import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";
import {
  AdvisoryBuyerArchiveDocument,
  AdvisoryBuyerGetByIdDocument,
  AdvisoryBuyerListDocument,
  AdvisoryBuyersPaginatedDocument,
  AdvisoryBuyerCreateDocument,
  AdvisoryBuyerUnarchiveDocument,
  AdvisoryBuyerUpdateDocument,
  AdvisoryBuyerListWalletTypeDocument,
  AdvisoryBuyerListCurrencyTypeDocument,
  AdvisoryBuyerDeleteDocument,
} from "api/generated";

import { Optional, ScopeEnum, ResourceEnum } from "config";

import {
  IAdvisoryBuyerModel,
  IAdvisoryBuyerCreateVars,
  IAdvisoryBuyerPaginatedResponse,
  IAdvisoryBuyerPaginatedVars,
  IAdvisoryBuyerUpdateVars,
  IAdvisoryListModel,
} from "./types";

export async function getAdvisoryBuyerListWalletType(): Promise<string[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AdvisoryBuyerListWalletTypeDocument,
    });
    return res?.data?.advisoryBuyerListWalletType ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getAdvisoryBuyerListCurrencyType(): Promise<string[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AdvisoryBuyerListCurrencyTypeDocument,
    });
    return res?.data?.advisoryBuyerListCurrencyType ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getAdvisoryBuyer(
  advisoryBuyerId: number
): Promise<Optional<IAdvisoryBuyerModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AdvisoryBuyerGetByIdDocument,
      variables: {
        id: advisoryBuyerId,
      },
    });
    return res?.data?.advisoryBuyerGetById;
  } catch (error) {
    throw error;
  }
}

export async function getAdvisoryBuyerPaginated(
  variables: IAdvisoryBuyerPaginatedVars
): Promise<Optional<IAdvisoryBuyerPaginatedResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AdvisoryBuyersPaginatedDocument,
      variables,
    });
    return res?.data?.advisoryBuyerListPaginated;
  } catch (error) {
    throw error;
  }
}

export async function getAdvisoryBuyerList(
  resource: ResourceEnum,
  scope: ScopeEnum
): Promise<IAdvisoryListModel[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AdvisoryBuyerListDocument,
      variables: {
        scope,
        resource,
      },
    });
    return res?.data?.advisoryBuyerList || [];
  } catch (error) {
    throw error;
  }
}

export async function createAdvisoryBuyer(
  variables: IAdvisoryBuyerCreateVars
): Promise<Optional<{ advisoryBuyerId: number }>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AdvisoryBuyerCreateDocument,
      variables,
    });
    return res?.data?.advisoryBuyerCreate;
  } catch (error) {
    throw error;
  }
}

export async function updateAdvisoryBuyer(
  variables: IAdvisoryBuyerUpdateVars
): Promise<Optional<{ advisoryBuyerId: number }>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AdvisoryBuyerUpdateDocument,
      variables,
    });
    return res?.data?.advisoryBuyerUpdate;
  } catch (error) {
    throw error;
  }
}

export async function archiveAdvisoryBuyer(
  advisoryBuyerId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AdvisoryBuyerArchiveDocument,
      variables: {
        advisoryBuyerId,
      },
    });
    return Boolean(res?.data?.advisoryBuyerArchive);
  } catch (error) {
    throw error;
  }
}

export async function deleteAdvisoryBuyer(
  advisoryBuyerId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AdvisoryBuyerDeleteDocument,
      variables: {
        advisoryBuyerId,
      },
    });
    return Boolean(res?.data?.advisoryBuyerDelete);
  } catch (error) {
    throw error;
  }
}

export async function unarchiveAdvisoryBuyer(
  advisoryBuyerId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AdvisoryBuyerUnarchiveDocument,
      variables: {
        advisoryBuyerId,
      },
    });
    return Boolean(res?.data?.advisoryBuyerUnarchive);
  } catch (error) {
    throw error;
  }
}
