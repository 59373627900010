import {
  AdvisoryProjectArchiveDocument,
  AdvisoryProjectCreateDocument,
  AdvisoryProjectDeleteDocument,
  AdvisoryProjectGetByIdDocument,
  AdvisoryProjectListDocument,
  AdvisoryProjectListPaginatedDocument,
  AdvisoryProjectListProjectTypeDocument,
  AdvisoryProjectUnarchiveDocument,
  AdvisoryProjectUpdateDocument,
} from "api/generated";
import { Optional, PartialNullable, ScopeEnum, ResourceEnum } from "config";

import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";

import {
  ICreateAdvisoryProjectVars,
  IUpdateAdvisoryProjectVars,
  IAdvisoryProjectModel,
  IAdvisoryProjectListPaginatedReturnType,
  IAdvisoryProjectListPaginatedVars,
} from "./types";

export async function getAdvisoryProjectListProjectType(): Promise<string[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AdvisoryProjectListProjectTypeDocument,
    });
    if (res?.errors) throw res?.errors;
    return res?.data?.advisoryProjectListProjectType ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getAdvisoryProjectList(
  resource: ResourceEnum,
  scope: ScopeEnum
): Promise<
  {
    advisoryProjectId: number;
    name: string;
    type: string;
    assetSymbol: string;
  }[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AdvisoryProjectListDocument,
      variables: {
        resource,
        scope,
      },
    });
    if (res?.errors) throw res?.errors;
    return res?.data?.advisoryProjectList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getAdvisoryProjectListPaginated(
  variables: IAdvisoryProjectListPaginatedVars
): Promise<IAdvisoryProjectListPaginatedReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AdvisoryProjectListPaginatedDocument,
      variables,
    });
    if (res?.errors) throw res?.errors;
    return res?.data?.advisoryProjectListPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createAdvisoryProject(
  variables: ICreateAdvisoryProjectVars
): Promise<Optional<Pick<IAdvisoryProjectModel, "advisoryProjectId">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AdvisoryProjectCreateDocument,
      variables,
    });
    return res?.data?.advisoryProjectCreate;
  } catch (error) {
    throw error;
  }
}

export async function updateAdvisoryProject(
  variables: IUpdateAdvisoryProjectVars
): Promise<Optional<Pick<IAdvisoryProjectModel, "advisoryProjectId">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AdvisoryProjectUpdateDocument,
      variables,
    });
    return res?.data?.advisoryProjectUpdate;
  } catch (error) {
    throw error;
  }
}

export async function archiveAdvisoryProject(
  advisoryProjectId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AdvisoryProjectArchiveDocument,
      variables: {
        advisoryProjectId,
      },
    });
    return Boolean(res?.data?.advisoryProjectArchive);
  } catch (error) {
    throw error;
  }
}

export async function deleteAdvisoryProject(
  advisoryProjectId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AdvisoryProjectDeleteDocument,
      variables: {
        advisoryProjectId,
      },
    });
    return Boolean(res?.data?.advisoryProjectDelete);
  } catch (error) {
    throw error;
  }
}

export async function unarchiveAdvisoryProject(
  advisoryProjectId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AdvisoryProjectUnarchiveDocument,
      variables: {
        advisoryProjectId,
      },
    });
    return Boolean(res?.data?.advisoryProjectUnarchive);
  } catch (error) {
    throw error;
  }
}

export async function getAdvisoryProject(
  advisoryProjectId: number
): Promise<PartialNullable<IAdvisoryProjectModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AdvisoryProjectGetByIdDocument,
      variables: {
        advisoryProjectId,
      },
    });
    return res?.data?.advisoryProjectGetById;
  } catch (error) {
    throw error;
  }
}
