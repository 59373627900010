import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { getExpectedConversion, IExpectedConversionReturnType } from "api";
import { Nullable } from "config";
import { RootState } from "store/store";
import { ConversionModalMode, IConversionModalData } from "./types";

const initialState = {
  conversionData: null as Nullable<IExpectedConversionReturnType["edges"]>,
  totalCount: 1,
  loading: false,
  modalData: {
    open: false,
    mode: ConversionModalMode.VIEW,
  } as IConversionModalData,
};

export const fetchExpectedConversions = createAsyncThunk(
  "conversion/fetchExpectedConversions",
  async (_, { getState }) => {
    const filters = (getState() as RootState).filters;
    if (!Object.values(filters).length) {
      return;
    }
    const fetchFunction = getExpectedConversion;
    const response = await fetchFunction(filters);
    return response;
  }
);

const conversionSlice = createSlice({
  name: "conversion",
  initialState,
  reducers: {
    setConversionModalData: (
      state,
      action: PayloadAction<IConversionModalData>
    ) => {
      state.modalData = action.payload;
    },
    closeConversionModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchExpectedConversions.fulfilled, (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          loading: false,
        };
      }
      return {
        ...state,
        conversionData: action.payload?.edges,
        totalCount: action.payload?.totalCount,
        loading: false,
      };
    });
    builder.addCase(fetchExpectedConversions.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const { setConversionModalData, closeConversionModal } =
  conversionSlice.actions;
export default conversionSlice.reducer;
