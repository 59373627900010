import {
  VentureInvestmentMethodCreateDocument,
  VentureInvestmentMethodDeleteDocument,
  VentureInvestmentMethodGetByIdDocument,
  VentureInvestmentMethodListDocument,
  VentureInvestmentMethodUpdateDocument,
  VentureInvestmentMethodsPaginatedDocument,
} from "api/generated";
import { PartialNullable } from "config";

import { RequestTypes } from "../../enums";
import { getRequestInstance } from "../../utils";
import {
  IInvestmentMethodCreateVars,
  IInvestmentMethodModel,
  IInvestmentMethodPaginatedResponse,
  IInvestmentMethodUpdateVars,
  IInvestmentMethodsListVars,
  IInvestmentMethodsPaginatedVars,
  InvestmentMethodCreateUpdateResponse,
  InvestmentMethodsListResponse,
} from "./types";

export async function getInvestmentMethodsPaginated(
  variables: IInvestmentMethodsPaginatedVars
): Promise<IInvestmentMethodPaginatedResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureInvestmentMethodsPaginatedDocument,
      variables,
    });
    return res?.data?.ventureInvestmentMethodsPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createInvestmentMethod(
  variables: IInvestmentMethodCreateVars
): Promise<InvestmentMethodCreateUpdateResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureInvestmentMethodCreateDocument,
      variables,
    });
    return res?.data?.ventureInvestmentMethodCreate;
  } catch (error) {
    throw error;
  }
}

export async function updateInvestmentMethod(
  variables: IInvestmentMethodUpdateVars
): Promise<InvestmentMethodCreateUpdateResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureInvestmentMethodUpdateDocument,
      variables,
    });
    return res?.data?.ventureInvestmentMethodUpdate;
  } catch (error) {
    throw error;
  }
}

export async function getInvestmentMethod(
  ventureInvestmentMethodId: number
): Promise<PartialNullable<IInvestmentMethodModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureInvestmentMethodGetByIdDocument,
      variables: {
        ventureInvestmentMethodId,
      },
    });
    return res?.data?.ventureInvestmentMethodGetById;
  } catch (error) {
    throw error;
  }
}

export async function deleteInvestmentMethod(
  ventureInvestmentMethodId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureInvestmentMethodDeleteDocument,
      variables: {
        ventureInvestmentMethodId,
      },
    });
    return Boolean(res?.data?.ventureInvestmentMethodDelete);
  } catch (error) {
    throw error;
  }
}

export async function getInvestmentMethods(
  variables: IInvestmentMethodsListVars
): Promise<InvestmentMethodsListResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureInvestmentMethodListDocument,
      variables,
    });
    return res?.data?.ventureInvestmentMethodList;
  } catch (error) {
    throw error;
  }
}
