import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";
import {
  CapitalMovementDividendGetByIdDocument,
  CapitalMovementDividendListDistributionTypeDocument,
  CapitalMovementDividendsPaginatedDocument,
  CapitalMovementDividendUpdateDocument,
} from "api/generated";

import { Optional } from "config/types";

import {
  ICapitalMovementDividendModel,
  ICapitalMovementDividendPaginatedResponse,
  ICapitalMovementDividendPaginatedVars,
  ICapitalMovementDividendUpdateVars,
} from "./types";
import { mapInvestorName } from "config";

export async function getCapitalMovementDividendListDistributionType(): Promise<
  string[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: CapitalMovementDividendListDistributionTypeDocument,
    });
    return res?.data?.capitalMovementDividendListDistributionType ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getCapitalMovementDividend(
  capitalMovementDividendId: number
): Promise<Optional<ICapitalMovementDividendModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: CapitalMovementDividendGetByIdDocument,
      variables: {
        id: capitalMovementDividendId,
      },
    });
    return (
      res?.data?.capitalMovementDividendGetById && {
        ...res.data.capitalMovementDividendGetById,
        investorProfile: mapInvestorName(
          res.data.capitalMovementDividendGetById.investorProfile
        ),
      }
    );
  } catch (error) {
    throw error;
  }
}

export async function getCapitalMovementDividendPaginated(
  variables: ICapitalMovementDividendPaginatedVars
): Promise<Optional<ICapitalMovementDividendPaginatedResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: CapitalMovementDividendsPaginatedDocument,
      variables,
    });
    return (
      res?.data?.capitalMovementDividendListPaginated && {
        ...res.data.capitalMovementDividendListPaginated,
        edges: res.data.capitalMovementDividendListPaginated.edges.map(
          (item) => ({
            ...item,
            node: {
              ...item.node,
              investorProfile:
                item.node.investorProfile &&
                mapInvestorName(item.node.investorProfile),
            },
          })
        ),
      }
    );
  } catch (error) {
    throw error;
  }
}

export async function updateCapitalMovementDividend(
  variables: ICapitalMovementDividendUpdateVars
): Promise<Optional<{ capitalMovementDividendId: number }>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: CapitalMovementDividendUpdateDocument,
      variables,
    });
    return res?.data?.capitalMovementDividendUpdate;
  } catch (error) {
    throw error;
  }
}
