import {
  SnapshotDownloadDocument,
  SnapshotsPaginatedDocument,
} from "api/generated";
import { Optional } from "config";

import { RequestTypes } from "../enums";
import { getRequestInstance } from "../utils";
import { ISnapshotsListVars, ISnapshotListReturnType } from "./types";

export async function getSnapshotsList(
  variables: ISnapshotsListVars
): Promise<ISnapshotListReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: SnapshotsPaginatedDocument,
      variables,
    });
    return res?.data?.snapshotsPaginated;
  } catch (error) {
    throw error;
  }
}

export async function downloadSnapshotDocument(
  snapshotId: number
): Promise<Optional<string>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: SnapshotDownloadDocument,
      variables: {
        snapshotId,
      },
    });
    return res?.data?.snapshotDownload;
  } catch (error) {
    throw error;
  }
}
