export enum RoutePaths {
  VENTURE_ASSET_ATTRIBUTION = "/venture/attribution-and-exits",
  VENTURE_DASHBOARD = "/venture/dashboard",
  VENTURE_PROJECT_PROFILE = "/venture/project-profile",
  VENTURE_VALUATION_CONTROL = "/venture/valuation-control",
  VENTURE_CONTACT = "/venture/contact-database",
  VENTURE_KPI = "/venture/kpi",
  VENTURE_PORTFOLIO_CONTROL = "/venture/portfolio-control",
  VENTURE_CONTACT_TYPE = "/administration/venture-library/contact-type",
  VENTURE_INVESTMENT_METHOD = "/administration/venture-library/investment-method",
  // VENTURE_PROJECT_TYPE_LIST = "/administration/venture-library/project-type-list",
  VENTURE_BLOCKCHAIN_LIST = "/administration/venture-library/blockchain-list",
  VENTURE_FUND = "/administration/venture-library/fund",
  VENTURE_ORIGINATION_SOURCE = "/administration/venture-library/origination-source",
  VENTURE_SECTOR = "/administration/venture-library/sector",
  VENTURE_TAGS = "/administration/venture-library/tags",
  VENTURE_NOTE_TYPE = "/administration/venture-library/note-type",
  VENTURE_DOCUMENT_LIST = "/administration/venture-library/document-list",
  ADMIN_EMAIL_TEMPLATES = "/system/email-templates",
  ADMIN_RESOURCES = "/administration/user-interaction/resources",
  LINK_AGENT_RESOURCES = "/capital-introducer/resources",
  LINK_AGENT_REPORT = "/capital-introducer/reports",
  LINK_AGENT_PAYOUT = "/capital-introducer/payouts",
  LINK_AGENT_MY_INFO = "/capital-introducer/my-info",
  REPORT_TYPE = "/administration/investors-management/report-type",
  STATEMENT_TYPE = "/administration/investors-management/statement-type",
  REPORTS = "/administration/investors-management/reports",
  STATEMENTS = "/administration/investors-management/statements",
  ANNOUNCEMENTS = "/administration/investors-management/events-and-updates/announcements",
  WEEKLY_UPDATES = "/administration/investors-management/events-and-updates/updates",
  UPCOMING_EVENTS = "/administration/investors-management/events-and-updates/upcoming-events",
  LINK_AGENT_PROFILES = "/capital-introducers/capital-introducers-profiles",
  CAPITAL_INTRODUCER_PAYOUTS = "/capital-introducer/capital-introducer-payouts",
  CAPITAL_INTRODUCER_REPORT = "/capital-introducer/capital-introducer-report",
  CAPITAL_INTRODUCER_KPI_REPORT = "/capital-introducer/kpi-report",
  CLIENT_DATABASE = "/westeros/client-database",
  ADVISORY_BUYER = "/allocation/buyer",
  ADVISORY_PROJECT = "/allocation/project",
  ADVISORY_PURCHASE = "/allocation/purchase",
  ADVISORY_TRANCHE = "/allocation/tranche",
  ADVISORY_EXIT_PLAN = "/allocation/exit-plan",
  ADVISORY_DISTRIBUTION = "/allocation/distribution",
  ADVISORY_REPORT = "/allocation/report",
  WESTEROS_SERVICE = "/administration/westeros-library/service",
  WESTEROS_NOTE_TYPE = "/administration/westeros-library/note-type",
  WESTEROS_DOCUMENT_LIST = "/administration/westeros-library/document-list",
  WESTEROS_TAGS = "/administration/westeros-library/tags",
  SNAPSHOTS = "/snapshots",
  PRODUCT_FUND_SERIES = "/investment/product-fund-series",
  EXPECTED_CONVERSION = "/administration/settings/expected-conversion",
  DASHBOARD = "/performance",
  EMPLOYEE_DASHBOARD = "/dashboard",
  HOME = "/",
  HOMEPAGE = "/homepage",
  SIGN_IN = "/sign-in",
  NOT_FOUND = "/not-found",
  USER_MANAGEMENT = "/administration/user-management",
  DOCUMENT = "/administration/investment-library/document-list",
  PRODUCT_FUND = "/administration/investment-library/product-fund",
  TAG = "/administration/investment-library/tag",
  NOTE_TYPE = "/administration/investment-library/note-type",
  SOURCE_EXCHANGE = "/administration/investment-library/transfer-source",
  INVESTORS = "/investors",
  PROFILES = "/investment/investor-database",
  INVESTMENT_CAPITAL_MOVEMENT = "/investment/capital-movement",
  INVESTMENT_CAPITAL_MOVEMENT_DIVIDEND = "/capital-movement-dividend",
  REPORTING = "/investment/reporting/investor-reporting",
  PRODUCT_FUND_REPORTING = "/investment/reporting/lead-reporting",
  KPI_PERFORMANCE = "/investment/reporting/kpi-performance",
  EMAILS = "/system/email-system",
  EMAILS_SEND_EMAIL = "/system/email-system/send-email",
  ASSETS_FIREBLOCKS = "/assets/fireblocks",
  ASSETS_EXCHANGE = "/assets/exchange",
  INVESTOR_MY_INFO = "/my-info",
  INVESTOR_CAPITAL_MOVEMENT = "/capital-movement",
  INVESTOR_REPORTS = "/reports",
  INVESTOR_STATEMENTS = "/statements",
  SYSTEM_TASK_MANAGEMENT_TASK = "/system/task-system/task",
  SYSTEM_TASK_MANAGEMENT_TASK_TYPE = "/system/task-management/task-type",
  SYSTEM_TASK_MANAGEMENT_NOTE_TYPE = "/system/task-management/note-type",
  SYSTEM_TASK_MANAGEMENT_TASK_REPORTING = "/system/task-management/task-reporting",
  SYSTEM_TASK_MANAGEMENT_RECURRING_TASK = "/system/task-system/recurring-task",
  FIREBLOCKS_WALLET = "/administration/allocation/fireblocks-wallet",
  WILDECARD = "*",
}
