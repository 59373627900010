import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getProductFundList, IProductFundModel } from "api";
import { GraphqlDataSchema, Nullable, Optional } from "config";
import { store } from "store";

import { IFundsModalData, FundModalModes } from "./types";

const initialState = {
  fundData: null as Nullable<GraphqlDataSchema<IProductFundModel>["edges"]>,
  totalCount: 1,
  loading: false,
  modalData: {
    open: false,
    mode: FundModalModes.VIEW,
  } as IFundsModalData,
};

export const fetchFunds = createAsyncThunk(
  "product-fund/fetchFunds",
  async (isFiltersIncluded: Optional<boolean> = true) => {
    const filters = store.getState().filters;
    if (!Object.values(filters).length) {
      return;
    }
    const response = await getProductFundList(isFiltersIncluded && filters);
    return response;
  }
);

const fundsSlice = createSlice({
  name: "product-fund",
  initialState,
  reducers: {
    setFundModalData: (state, action) => {
      state.modalData = action.payload;
    },
    closeFundModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFunds.fulfilled, (state, action) => {
      if (!action.payload) return;
      return {
        ...state,
        fundData: action.payload
          ?.edges as GraphqlDataSchema<IProductFundModel>["edges"],
        totalCount: action.payload?.totalCount,
        loading: false,
      };
    });
    builder.addCase(fetchFunds.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const { setFundModalData, closeFundModal } = fundsSlice.actions;
export default fundsSlice.reducer;
