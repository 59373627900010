export enum NotificationMode {
  ERROR = "error",
  SUCCESS = "success",
  DEFAULT = "default",
}

export enum NotificationDuration {
  DEFAULT = 3000,
  ERRORS = 2000,
}
