import {
  AnnouncementGetByIdDocument,
  AnnouncementForHomepageByIdDocument,
  AnnouncementsByUserDocument,
  AnnouncementsPaginatedDocument,
  AnnouncementCreateDocument,
  AnnouncementDeleteDocument,
  AnnouncementDocumentDownloadDocument,
  AnnouncementUpdateDocument,
  AnnouncementDocumentUploadDocument,
} from "api/generated";
import { Optional, PartialNullable } from "config";

import { RequestTypes } from "../../enums";
import { getRequestInstance } from "../../utils";
import { IFile, NumberFiltersType } from "../../types";
import {
  AnnouncementsForHomepageByIdResponse,
  AnnouncementsForUserResponse,
  AnnouncementsUpdateCreateResponse,
  IAnnouncementDocumentModel,
  IAnnouncementModel,
  IAnnouncementPaginatedResponse,
  IAnnouncementPaginatedVars,
  ICreateAnnouncementVars,
  IUpdateAnnouncementVars,
} from "./types";

export async function getAnnouncementsPaginated(
  variables: IAnnouncementPaginatedVars
): Promise<IAnnouncementPaginatedResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AnnouncementsPaginatedDocument,
      variables,
    });
    return res?.data?.announcementsPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createAnnouncement(
  variables: ICreateAnnouncementVars
): Promise<Optional<AnnouncementsUpdateCreateResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AnnouncementCreateDocument,
      variables,
    });
    return res?.data?.announcementCreate;
  } catch (error) {
    throw error;
  }
}

export async function updateAnnouncement(
  variables: IUpdateAnnouncementVars
): Promise<Optional<AnnouncementsUpdateCreateResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AnnouncementUpdateDocument,
      variables,
    });
    return res?.data?.announcementUpdate;
  } catch (error) {
    throw error;
  }
}

export async function getAnnouncement(
  announcementId: number
): Promise<PartialNullable<IAnnouncementModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AnnouncementGetByIdDocument,
      variables: { announcementId },
    });
    return res?.data?.announcementGetById;
  } catch (error) {
    throw error;
  }
}

export async function getAnnouncementsForUser(
  productFundId?: NumberFiltersType
): Promise<AnnouncementsForUserResponse[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AnnouncementsByUserDocument,
      variables: { productFundId },
    });
    return res?.data?.announcementsByUser ?? [];
  } catch (error) {
    throw error;
  }
}

export async function deleteAnnouncement(
  announcementId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AnnouncementDeleteDocument,
      variables: { announcementId },
    });
    return Boolean(res?.data?.announcementDelete);
  } catch (error) {
    throw error;
  }
}

export async function uploadAnnouncementDocument(
  variables: IFile
): Promise<Optional<IAnnouncementDocumentModel<number>>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AnnouncementDocumentUploadDocument,
      variables,
    });
    return res?.data?.announcementDocumentUpload;
  } catch (error) {
    throw error;
  }
}

export async function downloadAnnouncementDocument(
  announcementDocumentId: number
): Promise<Optional<string>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AnnouncementDocumentDownloadDocument,
      variables: { announcementDocumentId },
    });
    return res?.data?.announcementDocumentDownload;
  } catch (error) {
    throw error;
  }
}

export async function getAnnouncementForHomepage(
  announcementId: number
): Promise<AnnouncementsForHomepageByIdResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AnnouncementForHomepageByIdDocument,
      variables: { announcementId },
    });
    return res?.data?.announcementViewForInvestor;
  } catch (error) {
    throw error;
  }
}
