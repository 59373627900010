import { Box, Typography } from "@mui/material";

import { Spinner } from "../spinner";
import { SpinnerColor } from "../spinner/enums";
import { StyledFallbackLoader } from "./styles";
import { IFallbackLoaderProps } from "./types";

const FallbackLoader: React.FC<IFallbackLoaderProps> = ({
  withBackground,
  backgroundColor,
  zIndex,
  disableScreenScroll = true,
  fullHeightOfOverflowedContainer = false,
}: IFallbackLoaderProps) => {
  return (
    <StyledFallbackLoader
      withBackground={withBackground}
      backgroundColor={backgroundColor}
      zIndex={zIndex ?? ((theme) => theme.zIndex.drawer)}
      disableScreenScroll={disableScreenScroll}
      fullHeightOfOverflowedContainer={fullHeightOfOverflowedContainer}
    >
      <Box>
        <Spinner
          spinnerColor={withBackground ? SpinnerColor.GRAY : SpinnerColor.BLUE}
        />
        <Typography variant="subtitle2">Loading...</Typography>
      </Box>
    </StyledFallbackLoader>
  );
};

export default FallbackLoader;
