import { createTheme } from "@mui/material/styles";
import { TypographyStyleOptions } from "@mui/material/styles/createTypography";

import { CustomPalette, FontFamilies, FontWeights } from "./enums";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h7: true;
    h8: true;
    error: true;
    subtitle3: true;
    subtitle4: true;
    subtitle5: true;
    subtitle6: true;
    subtitle7: true;
    subtitle8: true;
    subtitle9: true;
    subtitle10: true;
  }
}

declare module "@mui/material/styles/zIndex" {
  interface ZIndex {
    minimal: number;
    filterbar: number;
    selectMenuBox: number;
    textEditorIcon: number;
    loaderInTable: number;
  }
}

declare module "@mui/material/styles" {
  interface Theme {
    shape: {
      borderRadius: string[];
    };
    fontWeight: typeof FontWeights;
  }
  interface ThemeOptions {
    shape: {
      borderRadius: string[];
    };
    fontWeight: typeof FontWeights;
  }

  interface TypographyVariants {
    error: TypographyStyleOptions;
    h7: TypographyStyleOptions;
    h8: TypographyStyleOptions;
    subtitle3: TypographyStyleOptions;
    subtitle4: TypographyStyleOptions;
    subtitle5: TypographyStyleOptions;
    subtitle6: TypographyStyleOptions;
    subtitle7: TypographyStyleOptions;
    subtitle8: TypographyStyleOptions;
    subtitle9: TypographyStyleOptions;
    subtitle10: TypographyStyleOptions;
  }
  interface TypographyVariantsOptions {
    error: TypographyStyleOptions;
    h7: TypographyStyleOptions;
    h8: TypographyStyleOptions;
    subtitle3: TypographyStyleOptions;
    subtitle4: TypographyStyleOptions;
    subtitle5: TypographyStyleOptions;
    subtitle6: TypographyStyleOptions;
    subtitle7: TypographyStyleOptions;
    subtitle8: TypographyStyleOptions;
    subtitle9: TypographyStyleOptions;
    subtitle10: TypographyStyleOptions;
  }
  interface Palette {
    custom: typeof CustomPalette;
  }
  interface PaletteOptions {
    custom: typeof CustomPalette;
  }
}

export const theme = createTheme({
  zIndex: {
    minimal: 1,
    filterbar: 6,
    loaderInTable: 5,
    selectMenuBox: 2,
    textEditorIcon: 3,
  },
  components: {
    MuiFilledInput: {
      styleOverrides: {
        input: {
          "&:-webkit-autofill": {
            borderTopLeftRadius: "unset",
            borderTopRightRadius: "unset",
          },
        },
      },
    },
  },
  fontWeight: {
    _400: FontWeights._400,
    _500: FontWeights._500,
    _600: FontWeights._600,
    _700: FontWeights._700,
    _800: FontWeights._800,
    _900: FontWeights._900,
    NORMAL: FontWeights.NORMAL,
  },
  spacing: [0, 4, 8, 10, 16, 20, 24, 32, 40, 48, 64],
  shape: {
    borderRadius: [3, 4, 10, 16, 20, 46].map((el) => `${el}px`),
  },
  typography: {
    error: {
      fontSize: "12px",
      lineHeight: "14px",
    },
    h1: {
      fontWeight: FontWeights._700,
      fontSize: "31px",
      lineHeight: "36px",
      textTransform: "uppercase",
      whiteSpace: "nowrap",
      letterSpacing: "5px",
    },
    h2: {
      fontWeight: FontWeights._600,
      fontSize: "16px",
    },
    h3: {
      fontWeight: FontWeights._600,
      fontSize: "15px",
    },
    h4: {
      fontWeight: FontWeights._500,
      fontSize: "15px",
      lineHeight: "25px",
    },
    h5: {
      fontSize: "20px",
      fontWeight: FontWeights._600,
      lineHeight: "23px",
    },
    h6: {
      fontSize: "22px",
      fontWeight: FontWeights._500,
      color: CustomPalette.white,
      lineHeight: "1.6rem",
    },
    h7: {
      fontSize: "17px",
      fontWeight: FontWeights._500,
    },
    h8: {
      fontSize: "22px",
      fontWeight: FontWeights._600,
    },
    subtitle1: {
      fontSize: "18px",
      fontWeight: FontWeights._600,
    },
    subtitle2: {
      fontSize: "13px",
      fontWeight: FontWeights._500,
      color: CustomPalette.white,
      lineHeight: "15px",
    },
    subtitle3: {
      fontSize: "14px",
      fontWeight: FontWeights._400,
      color: CustomPalette.white,
    },
    subtitle4: {
      fontSize: "11px",
      fontWeight: FontWeights._600,
    },
    subtitle5: {
      fontSize: "13px",
      color: CustomPalette.white,
      fontWeight: FontWeights._600,
      lineHeight: "22px",
    },
    subtitle6: {
      fontSize: "11px",
      color: CustomPalette.white,
      fontWeight: FontWeights._400,
      lineHeight: "13px",
    },
    subtitle7: {
      fontSize: "13px",
      fontWeight: FontWeights._400,
    },
    subtitle8: {
      fontSize: "14px",
      fontWeight: FontWeights._500,
    },
    subtitle9: {
      fontSize: "12px",
      fontWeight: FontWeights._500,
    },
    subtitle10: {
      fontSize: "15px",
      fontWeight: FontWeights._500,
    },
    fontFamily: FontFamilies.WORK_SANS,
  },

  palette: {
    custom: {
      white: CustomPalette.white,
      default_gray: CustomPalette.default_gray,
      dark_gray: CustomPalette.dark_gray,
      golden: CustomPalette.golden,
      dark_golden: CustomPalette.dark_golden,
      light_dark: CustomPalette.light_dark,
      default_dark: CustomPalette.default_dark,
      error_main: CustomPalette.error_main,
      dark_blue: CustomPalette.dark_blue,
      light_green: CustomPalette.light_green,
      default_green: CustomPalette.default_green,
      darker_blue: CustomPalette.darker_blue,
      light_gray: CustomPalette.light_gray,
      light_blue: CustomPalette.light_blue,
      lighter_gray: CustomPalette.lighter_gray,
      disable_gray: CustomPalette.disable_gray,
      disable_text_gray: CustomPalette.disable_text_gray,
      lighter_blue: CustomPalette.lighter_blue,
      red: CustomPalette.red,
      middle_blue: CustomPalette.middle_blue,
      black: CustomPalette.black,
      darker_gray: CustomPalette.darker_gray,
      purple: CustomPalette.purple,
      yellow: CustomPalette.yellow,
      orange: CustomPalette.orange,
    },
    text: {
      primary: CustomPalette.light_dark,
      secondary: CustomPalette.white,
      disabled: CustomPalette.disable_text_gray,
    },
    error: {
      main: CustomPalette.error_main,
    },
    primary: {
      main: CustomPalette.light_dark,
      contrastText: CustomPalette.white,
    },
    secondary: {
      main: CustomPalette.golden,
      contrastText: CustomPalette.light_dark,
    },
    info: {
      main: CustomPalette.white,
      contrastText: CustomPalette.light_dark,
    },
  },
});

export type CustomThemeType = typeof theme;
