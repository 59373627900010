import {
  getExchangeListGroupedByExchangeType,
  getExchangeListGroupedByExchangeTypeAndAsset,
  getExchangesListGroupedByAsset,
  getExchangesListGroupedByExchange,
} from "api";

export enum ExchangeGroupByOptions {
  ASSETS = "Asset",
  EXCHANGE = "Exchange",
  ACCOUNT = "Account",
  EXCHANGE_ASSET = "Exchange-Asset",
  ACCOUNT_ASSET = "Account-Asset",
}

export const exchangeGroupByOptionsToFunctionsMap = {
  [ExchangeGroupByOptions.ASSETS]: getExchangesListGroupedByAsset,
  [ExchangeGroupByOptions.EXCHANGE]: getExchangeListGroupedByExchangeType,
  [ExchangeGroupByOptions.ACCOUNT]: getExchangesListGroupedByExchange,
  [ExchangeGroupByOptions.EXCHANGE_ASSET]:
    getExchangeListGroupedByExchangeTypeAndAsset,
};
