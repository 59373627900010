import {
  CreateReportDocument,
  DeleteReportDocument,
  DownloadReportDocumentDocument,
  ReportGetByIdDocument,
  ReportsPaginatedDocument,
  UpdateReportDocument,
  UploadReportDocumentDocument,
} from "api/generated";
import { Optional, PartialNullable } from "config";

import { RequestTypes } from "../../enums";
import { getRequestInstance } from "../../utils";
import { IFile } from "../../types";
import {
  IReportCreateVars,
  IReportDocumentModel,
  IReportModel,
  IReportsPaginatedResponse,
  IReportsPaginatedVars,
  IReportUpdateVars,
  ReportsCreateUpdateResponse,
} from "./types";

export async function getReportsPaginated(
  variables: IReportsPaginatedVars
): Promise<IReportsPaginatedResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: ReportsPaginatedDocument,
      variables,
    });
    return res?.data?.reportsPaginated;
  } catch (error) {
    throw error;
  }
}

export async function getReport(
  reportId: number
): Promise<Optional<PartialNullable<IReportModel>>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: ReportGetByIdDocument,
      variables: { reportId },
    });
    return res?.data?.reportGetById;
  } catch (error) {
    throw error;
  }
}

export async function createReport(
  variables: IReportCreateVars
): Promise<Optional<ReportsCreateUpdateResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: CreateReportDocument,
      variables,
    });
    return res?.data?.reportCreate;
  } catch (error) {
    throw error;
  }
}

export async function updateReport(
  variables: IReportUpdateVars
): Promise<Optional<ReportsCreateUpdateResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: UpdateReportDocument,
      variables,
    });
    return res?.data?.reportUpdate;
  } catch (error) {
    throw error;
  }
}

export async function deleteReport(reportId: number): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: DeleteReportDocument,
      variables: { reportId },
    });
    return Boolean(res?.data?.reportDelete);
  } catch (error) {
    throw error;
  }
}

export async function uploadReportDocument(
  variables: IFile
): Promise<Optional<IReportDocumentModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: UploadReportDocumentDocument,
      variables,
    });
    return res?.data?.reportDocumentUpload;
  } catch (error) {
    throw error;
  }
}

export async function downloadReportDocument(
  reportDocumentId: number
): Promise<Optional<string>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: DownloadReportDocumentDocument,
      variables: { reportDocumentId },
    });
    return res?.data?.reportDocumentDownload;
  } catch (error) {
    throw error;
  }
}
