import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IFireblocksWalletPaginatedResponse,
  getFireblocksWalletPaginated,
} from "api";
import { Nullable } from "config";
import { store } from "store";

import { FireblocksWalletModalMode } from "./enums";
import { IFireblocksWalletModalData } from "./types";

const initialState = {
  fireblocksWalletData: null as Nullable<
    IFireblocksWalletPaginatedResponse["edges"]
  >,
  totalCount: 1,
  loading: false,
  modalData: {
    mode: FireblocksWalletModalMode.VIEW,
    open: false,
    loading: false,
  } as IFireblocksWalletModalData,
};

export const fetchFireblocksWalletList = createAsyncThunk(
  "advisory/fetchFireblocksWalletList",
  async () => {
    const filters = store.getState().filters;
    if (!Object.values(filters).length) {
      return;
    }
    const response = await getFireblocksWalletPaginated(filters);
    return response;
  }
);

const fireblocksWalletSlice = createSlice({
  name: "fireblocksWallet",
  initialState,
  reducers: {
    setFireblocksWalletModalData: (
      state,
      action: PayloadAction<IFireblocksWalletModalData>
    ) => {
      state.modalData = action.payload;
    },
    closeFireblocksWalletModal: (state) => {
      state.modalData = initialState.modalData;
    },
    setFireblocksWalletModalLoading: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.modalData.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFireblocksWalletList.fulfilled, (state, action) => {
      if (!action.payload) return;
      return {
        ...state,
        fireblocksWalletData: action.payload
          ?.edges as IFireblocksWalletPaginatedResponse["edges"],
        totalCount: action.payload?.totalCount,
        loading: false,
      };
    });
    builder.addCase(fetchFireblocksWalletList.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const {
  setFireblocksWalletModalData,
  closeFireblocksWalletModal,
  setFireblocksWalletModalLoading,
} = fireblocksWalletSlice.actions;
export default fireblocksWalletSlice.reducer;
