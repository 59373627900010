import { CSSObject, Theme, Typography, styled } from "@mui/material";
import { ArrowIcon } from "config/assets";

export const absoluteCenterStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export const fullWidthAndHeight = {
  width: "100%",
  height: "100%",
};

export const flexCenter = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const flexCenterColumn = {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
};

export const pointer = {
  cursor: "pointer",
};

export const scrollBar = (
  theme: Theme,
  mode: "light" | "dark" | "gray" = "dark"
) => ({
  overflowY: "overlay",
  "@-moz-document url-prefix()": {
    overflowY: "auto",
    scrollBarColor: `${theme.palette.custom.dark_blue} rgba(0,0,0,0)`,
    scrollBarWidth: "thin !important",
  },
  "&::-webkit-scrollBar": {
    width: "5px",
    height: "5px",
  },
  "&::-webkit-scrollBar-track-piece:start": {
    background: "transparent",
  },
  "&::-webkit-scrollBar-track-piece:end": {
    background: "transparent",
  },
  "&::-webkit-scrollBar-track": {
    background: theme.palette.custom.light_dark,
  },

  "&::-webkit-scrollBar-thumb": {
    background: theme.palette.custom.dark_blue,
    border: `1px solid ${theme.palette.custom.dark_blue}`,
    borderRadius: "10rem",
  },
  ...(mode === "light" && {
    "&::-webkit-scrollBar-track": {
      background: "transparent",
    },
  }),
  ...(mode === "gray" && {
    "&::-webkit-scrollBar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollBar-thumb": {
      background: theme.palette.custom.disable_text_gray,
      border: `1px solid ${theme.palette.custom.disable_text_gray}`,
      borderRadius: "10rem",
    },
  }),
});

export const ellipsis: CSSObject = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

export const ellipsisWithLines = (lineCount: number): CSSObject => ({
  display: "-webkit-box",
  WebkitLineClamp: lineCount,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

export const StyledProductFundStyleTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isViewMode",
})<{ isViewMode: boolean }>(({ theme, isViewMode }) => ({
  fontWeight: theme.fontWeight._600,
  width: "fit-content",
  display: "flex",
  alignItems: "center",
  ...(isViewMode && {
    display: "block",
    maxWidth: "312px",
    ...ellipsis,
  }),
  ...(!isViewMode && {
    height: "40px",
  }),
}));

export const TextEditorDescriptionBoxStyles = (theme: any) => ({
  ".quill-inactive": {
    paddingTop: "8px",
    borderRadius: "4px",
    border: `1px solid #A3A3A3`,
    boxShadow: "unset",

    ".ql-editor": {
      minHeight: "130px",
      height: "auto",
      maxHeight: "400px",
      ...scrollBar(theme, "light"),
    },
  },
});

export const StyledArrowIcon = styled(ArrowIcon)(({ theme }) => ({
  transform: "rotate(90deg)",
  path: {
    stroke: theme.palette.custom.default_dark,
    fill: theme.palette.custom.default_dark,
  },
}));
