import {
  LinkAgentChainListDocument,
  LinkAgentCurrencyListDocument,
  LinkAgentDocumentDownloadDocument,
  LinkAgentDocumentUploadDocument,
  LinkAgentNoteCreateDocument,
  LinkAgentNoteDeleteDocument,
  LinkAgentNotesDocumentDeleteDocument,
  LinkAgentNotesDocumentDownloadDocument,
  LinkAgentNotesDocumentUploadDocument,
  LinkAgentNoteUpdateDocument,
  LinkAgentNoteVerifyDocument,
  LinkAgentPayoutDataByLinkAgentProfileDocument,
  LinkAgentPayoutListByLinkAgentProfileDocument,
  LinkAgentProfileCommissionDocument,
  LinkAgentProfileCreateDocument,
  LinkAgentProfileDeleteDocument,
  LinkAgentProfileGetByIdDocument,
  LinkAgentProfilesListByParentDocument,
  LinkAgentProfilesListByTypeDocument,
  LinkAgentProfilesListDocument,
  LinkAgentProfilesPaginatedDocument,
  LinkAgentProfileUpdateDocument,
  LinkAgentTypeListDocument,
  UsersForLinkAgentCreationDocument,
} from "api/generated";
import { mapInvestorName, Optional, PartialNullable } from "config";

import { RequestTypes } from "../../enums";
import { getRequestInstance } from "../../utils";
import {
  IChainModel,
  ICreateLinkAgentVars,
  IGetLinkAgentProfilesReturnType,
  IGetLinkAgentProfilesVars,
  ILinkAgentNoteCreateVars,
  ILinkAgentNotesCreateReturnType,
  ILinkAgentNotesUpdateReturnType,
  ILinkAgentNoteUpdateVars,
  ILinkAgentPayoutDataByLinkAgentProfileResponse,
  ILinkAgentPayoutsByLinkAgentProfileResponse,
  ILinkAgentPayoutsByLinkAgentProfileVars,
  ILinkAgentProfileCommissionVars,
  ILinkAgentProfileModel,
  ILinkAgentProfilesByParentResponse,
  ILinkAgentProfilesByParentVars,
  ILinkAgentProfilesListByTypeResponse,
  ILinkAgentProfilesListByTypeVars,
  ILinkAgentProfilesPaginatedReturnType,
  ILinkAgentProfilesPaginatedVars,
  ILinkAgentTypeModel,
  IUpdateLinkAgentVars,
  IUploadLinkAgentDocumentReturnType,
  IUploadLinkAgentNoteDocumentReturnType,
} from "./types";
import { ICurrencyModel, IFile } from "../../types";
import { IUsersFilterResponse } from "../../user/types";

export async function getLinkAgentProfilesPaginated(
  variables: ILinkAgentProfilesPaginatedVars
): Promise<ILinkAgentProfilesPaginatedReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: LinkAgentProfilesPaginatedDocument,
      variables,
    });
    return res?.data?.linkAgentProfilesPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createLinkAgentProfile(
  variables: ICreateLinkAgentVars
): Promise<Optional<Pick<ILinkAgentProfileModel, "linkAgentProfileId">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: LinkAgentProfileCreateDocument,
      variables,
    });
    return res?.data?.linkAgentProfileCreate;
  } catch (error) {
    throw error;
  }
}

export async function getLinkAgentProfile(
  linkAgentProfileId: number
): Promise<PartialNullable<ILinkAgentProfileModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: LinkAgentProfileGetByIdDocument,
      variables: {
        linkAgentProfileId,
      },
    });
    return res?.data?.linkAgentProfileGetById;
  } catch (error) {
    throw error;
  }
}

export async function updateLinkAgentProfile(
  variables: IUpdateLinkAgentVars
): Promise<Optional<Pick<ILinkAgentProfileModel, "linkAgentProfileId">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: LinkAgentProfileUpdateDocument,
      variables,
    });
    return res?.data?.linkAgentProfileUpdate;
  } catch (error) {
    throw error;
  }
}

export async function getLinkAgentProfiles(
  variables: IGetLinkAgentProfilesVars
): Promise<IGetLinkAgentProfilesReturnType[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: LinkAgentProfilesListDocument,
      variables,
      fetchPolicy: "cache-first",
    });
    return res?.data?.linkAgentProfilesList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function downloadLinkAgentDocument(
  linkAgentDocumentId: number
): Promise<Optional<string>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: LinkAgentDocumentDownloadDocument,
      variables: { linkAgentDocumentId },
    });
    return res?.data?.linkAgentDocumentDownload;
  } catch (error) {
    throw error;
  }
}

export async function uploadLinkAgentDocument(
  variables: IFile
): Promise<Optional<IUploadLinkAgentDocumentReturnType>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: LinkAgentDocumentUploadDocument,
      variables,
    });
    return res?.data?.linkAgentDocumentUpload;
  } catch (error) {
    throw error;
  }
}

export async function createLinkAgentProfileNote(
  variables: ILinkAgentNoteCreateVars
): Promise<Optional<ILinkAgentNotesCreateReturnType>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: LinkAgentNoteCreateDocument,
      variables,
    });
    return res?.data?.linkAgentNoteCreate;
  } catch (error) {
    throw error;
  }
}

export async function updateLinkAgentProfileNote(
  variables: ILinkAgentNoteUpdateVars
): Promise<Optional<ILinkAgentNotesUpdateReturnType>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: LinkAgentNoteUpdateDocument,
      variables,
    });
    return res?.data?.linkAgentNoteUpdate;
  } catch (error) {
    throw error;
  }
}

export async function deleteLinkAgentProfileNote(
  linkAgentNoteId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: LinkAgentNoteDeleteDocument,
      variables: { linkAgentNoteId },
    });
    return Boolean(res?.data?.linkAgentNoteDelete);
  } catch (error) {
    throw error;
  }
}

export async function uploadLinkAgentNoteDocument(
  variables: IFile
): Promise<Optional<PartialNullable<IUploadLinkAgentNoteDocumentReturnType>>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: LinkAgentNotesDocumentUploadDocument,
      variables,
    });
    return res?.data?.linkAgentNotesDocumentUpload;
  } catch (error) {
    throw error;
  }
}

export async function downloadLinkAgentNoteDocument(
  linkAgentDocumentId: number
): Promise<Optional<string>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: LinkAgentNotesDocumentDownloadDocument,
      variables: { linkAgentDocumentId },
    });
    return res?.data?.linkAgentNotesDocumentDownload;
  } catch (error) {
    throw error;
  }
}

export async function deleteLinkAgentNoteDocument(
  linkAgentDocumentId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: LinkAgentNotesDocumentDeleteDocument,
      variables: { linkAgentDocumentId },
    });
    return Boolean(res?.data?.linkAgentNotesDocumentDelete);
  } catch (error) {
    throw error;
  }
}

export async function verifyLinkAgentNote(
  linkAgentNoteId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: LinkAgentNoteVerifyDocument,
      variables: { linkAgentNoteId },
    });
    return Boolean(res?.data?.linkAgentNoteVerify.isLocked);
  } catch (error) {
    throw error;
  }
}

export async function getUsersForLinkAgentCreate(): Promise<
  IUsersFilterResponse[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: UsersForLinkAgentCreationDocument,
    });
    return res?.data?.usersForLinkAgentCreation ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getLinkAgentProfileCommission(
  variables: ILinkAgentProfileCommissionVars
): Promise<number> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: LinkAgentProfileCommissionDocument,
      variables,
    });
    return res?.data?.linkAgentProfileCommission;
  } catch (error) {
    throw error;
  }
}

export async function getLinkAgentPayoutListByLinkAgentProfile(
  variables: ILinkAgentPayoutsByLinkAgentProfileVars
): Promise<ILinkAgentPayoutsByLinkAgentProfileResponse[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: LinkAgentPayoutListByLinkAgentProfileDocument,
      variables,
    });
    return (res?.data?.linkAgentPayoutListByLinkAgentProfile ?? []).map(
      (item) => ({
        ...item,
        investorProfile: {
          ...item.investorProfile,
          ...mapInvestorName(item.investorProfile),
        },
      })
    );
  } catch (e) {
    throw e;
  }
}

export async function getLinkAgentPayoutDataByLinkAgentProfile(
  variables: ILinkAgentPayoutsByLinkAgentProfileVars
): Promise<ILinkAgentPayoutDataByLinkAgentProfileResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: LinkAgentPayoutDataByLinkAgentProfileDocument,
      variables,
    });
    return res?.data?.linkAgentPayoutDataByLinkAgentProfile;
  } catch (e) {
    throw e;
  }
}

export async function getLinkAgentProfileChains(): Promise<IChainModel[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: LinkAgentChainListDocument,
      fetchPolicy: "cache-first",
    });
    return res?.data?.linkAgentChainList ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getLinkAgentProfileCurrencies(): Promise<
  ICurrencyModel[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: LinkAgentCurrencyListDocument,
      fetchPolicy: "cache-first",
    });
    return res?.data?.linkAgentCurrencyList ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getLinkAgentProfileTypes(): Promise<
  ILinkAgentTypeModel[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: LinkAgentTypeListDocument,
    });
    return res?.data?.linkAgentTypeList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getLinkAgentProfileListByType(
  variables: ILinkAgentProfilesListByTypeVars
): Promise<ILinkAgentProfilesListByTypeResponse[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: LinkAgentProfilesListByTypeDocument,
      variables,
    });
    return res?.data?.linkAgentProfilesListByType ?? [];
  } catch (error) {
    throw error;
  }
}

export async function deleteLinkAgentProfile(
  linkAgentProfileId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: LinkAgentProfileDeleteDocument,
      variables: { linkAgentProfileId },
    });
    return Boolean(res?.data?.linkAgentProfileDelete);
  } catch (error) {
    throw error;
  }
}

export async function getLinkAgentProfilesByParent(
  variables: ILinkAgentProfilesByParentVars
): Promise<ILinkAgentProfilesByParentResponse[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: LinkAgentProfilesListByParentDocument,
      variables,
    });
    return res?.data?.linkAgentProfilesListByParent ?? [];
  } catch (error) {
    throw error;
  }
}
