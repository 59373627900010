import {
  LinkAgentPayoutAggregatedExportDocument,
  LinkAgentPayoutCreateDocument,
  LinkAgentPayoutDeleteDocument,
  LinkAgentPayoutDetailedExportDocument,
  LinkAgentPayoutDocumentDownloadDocument,
  LinkAgentPayoutDocumentUploadDocument,
  LinkAgentPayoutExportFileTypeDocument,
  LinkAgentPayoutGetByIdDocument,
  LinkAgentPayoutListCurrenciesDocument,
  LinkAgentPayoutsPaginatedAggregatedDocument,
  LinkAgentPayoutsPaginatedDocument,
  LinkAgentPayoutTypeListDocument,
  LinkAgentPayoutUpdateDocument,
} from "api/generated";
import { mapInvestorName, Optional, PartialNullable } from "config";

import { RequestTypes } from "../../enums";
import { getRequestInstance } from "../../utils";
import {
  ILinkAgentPayoutCreateVars,
  ILinkAgentPayoutDocumentModel,
  ILinkAgentPayoutModel,
  ILinkAgentPayoutsAggregatedExportVars,
  ILinkAgentPayoutsAggregatedResponse,
  ILinkAgentPayoutsAggregatedVars,
  ILinkAgentPayoutsDetailedExportVars,
  ILinkAgentPayoutsPaginatedResponse,
  ILinkAgentPayoutsPaginatedVars,
  ILinkAgentPayoutTypeModel,
  ILinkAgentPayoutUpdateVars,
} from "./types";
import { ICurrencyModel, IFile } from "../../types";

export async function getLinkAgentPayoutsAggregatedPaginatedData(
  variables: ILinkAgentPayoutsAggregatedVars
): Promise<ILinkAgentPayoutsAggregatedResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: LinkAgentPayoutsPaginatedAggregatedDocument,
      variables,
    });
    return res?.data?.linkAgentPayoutsPaginatedAggregated;
  } catch (error) {
    throw error;
  }
}

export async function getLinkAgentPayoutsPaginatedData(
  variables: ILinkAgentPayoutsPaginatedVars
): Promise<ILinkAgentPayoutsPaginatedResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: LinkAgentPayoutsPaginatedDocument,
      variables,
    });
    return res?.data?.linkAgentPayoutsPaginated;
  } catch (error) {
    throw error;
  }
}

export async function getLinkAgentPayout(
  linkAgentPayoutId: number
): Promise<PartialNullable<ILinkAgentPayoutModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: LinkAgentPayoutGetByIdDocument,
      variables: { linkAgentPayoutId },
    });
    return (
      res?.data?.linkAgentPayoutGetById && {
        ...res.data.linkAgentPayoutGetById,
        investorProfile: {
          ...res?.data?.linkAgentPayoutGetById.investorProfile,
          ...mapInvestorName(res?.data?.linkAgentPayoutGetById.investorProfile),
        },
      }
    );
  } catch (error) {
    throw error;
  }
}

export async function createLinkAgentPayout(
  variables: ILinkAgentPayoutCreateVars
): Promise<Optional<Pick<ILinkAgentPayoutModel, "linkAgentPayoutId">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: LinkAgentPayoutCreateDocument,
      variables,
    });
    return res?.data?.linkAgentPayoutCreate;
  } catch (error) {
    throw error;
  }
}

export async function updateLinkAgentPayout(
  variables: ILinkAgentPayoutUpdateVars
): Promise<Optional<Pick<ILinkAgentPayoutModel, "linkAgentPayoutId">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: LinkAgentPayoutUpdateDocument,
      variables,
    });
    return res?.data?.linkAgentPayoutUpdate;
  } catch (error) {
    throw error;
  }
}

export async function deleteLinkAgentPayout(
  linkAgentPayoutId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: LinkAgentPayoutDeleteDocument,
      variables: { linkAgentPayoutId },
    });
    return Boolean(res?.data?.linkAgentPayoutDelete);
  } catch (error) {
    throw error;
  }
}

export async function uploadLinkAgentPayoutDocument(
  variables: IFile
): Promise<Optional<ILinkAgentPayoutDocumentModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: LinkAgentPayoutDocumentUploadDocument,
      variables,
    });
    return res?.data?.linkAgentPayoutDocumentUpload;
  } catch (error) {
    throw error;
  }
}

export async function downloadLinkAgentPayoutDocument(
  linkAgentPayoutDocumentId: number
): Promise<Optional<string>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: LinkAgentPayoutDocumentDownloadDocument,
      variables: { linkAgentPayoutDocumentId },
    });
    return res?.data?.linkAgentPayoutDocumentDownload;
  } catch (error) {
    throw error;
  }
}

export async function getLinkAgentPayoutExportFileTypes(): Promise<string[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: LinkAgentPayoutExportFileTypeDocument,
      fetchPolicy: "cache-first",
    });
    return res?.data?.linkAgentPayoutExportFileType ?? [];
  } catch (error) {
    throw error;
  }
}

export async function exportLinkAgentPayoutAggregatedData(
  variables: ILinkAgentPayoutsAggregatedExportVars
): Promise<string> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: LinkAgentPayoutAggregatedExportDocument,
      variables,
    });
    return res?.data?.linkAgentPayoutAggregatedExport;
  } catch (error) {
    throw error;
  }
}

export async function exportLinkAgentPayoutDetailedData(
  variables: ILinkAgentPayoutsDetailedExportVars
): Promise<string> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: LinkAgentPayoutDetailedExportDocument,
      variables,
    });
    return res?.data?.linkAgentPayoutDetailedExport;
  } catch (error) {
    throw error;
  }
}

export async function getLinkAgentPayoutCurrencies(): Promise<
  ICurrencyModel[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: LinkAgentPayoutListCurrenciesDocument,
      fetchPolicy: "cache-first",
    });
    return res?.data?.linkAgentPayoutListCurrencies ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getLinkAgentPayoutTypes(): Promise<
  ILinkAgentPayoutTypeModel[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: LinkAgentPayoutTypeListDocument,
      fetchPolicy: "cache-first",
    });
    return res?.data?.linkAgentPayoutTypeList ?? [];
  } catch (e) {
    throw e;
  }
}
