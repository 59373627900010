export enum RequestTypes {
  QUERY = "query",
  MUTATE = "mutate",
  REST = "rest",
}

export enum ErrorCodes {
  NOTE_NOT_FOUND = 1094,
  INVESTOR_PROFILE_NOT_FOUND = 1042,
}
