import { Slide } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
  hideSnackbar,
  clearSnackbarStore,
} from "store";
import { StyledSnackbar } from "./styles";

const CustomizedSnackbar = () => {
  const dispatch = useAppDispatch();
  const { isOpen, message, duration, mode } = useAppSelector(
    (state) => state.notification
  );

  return (
    <StyledSnackbar
      open={isOpen}
      message={message}
      autoHideDuration={duration}
      mode={mode}
      TransitionComponent={Slide}
      TransitionProps={{ onExited: () => dispatch(clearSnackbarStore()) }}
      onClose={() => dispatch(hideSnackbar())}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    />
  );
};

export default CustomizedSnackbar;
