import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  IUpcomingEventsPaginatedResponse,
  getUpcomingEventsPaginated,
} from "api";
import { Nullable } from "config";
import { RootState } from "store";

import { UpcomingEventModalModes } from "./enums";
import { IUpcomingEventModal } from "./types";

const initialState = {
  upcomingEventsData: null as Nullable<
    IUpcomingEventsPaginatedResponse["edges"]
  >,
  totalCount: 1,
  loading: false,
  modalData: {
    open: false,
    mode: UpcomingEventModalModes.VIEW,
  } as IUpcomingEventModal,
};

export const fetchUpcomingEvents = createAsyncThunk(
  "upcoming-events/fetchUpcomingEvents",
  async (_, { getState }) => {
    const filters = (getState() as RootState).filters;
    if (!Object.values(filters).length) {
      return;
    }
    const response = await getUpcomingEventsPaginated(filters);
    return response;
  }
);

const upcomingEventsSlice = createSlice({
  name: "upcoming-events",
  initialState,
  reducers: {
    setUpcomingEventsModalData: (
      state,
      action: PayloadAction<IUpcomingEventModal>
    ) => {
      state.modalData = action.payload;
    },
    closeUpcomingEventsModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchUpcomingEvents.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        upcomingEventsData: action.payload?.edges ?? null,
        totalCount: action.payload?.totalCount ?? 1,
      };
    });
    builder.addCase(fetchUpcomingEvents.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const { setUpcomingEventsModalData, closeUpcomingEventsModal } =
  upcomingEventsSlice.actions;
export default upcomingEventsSlice.reducer;
