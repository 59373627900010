import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { IReportsPaginatedResponse, getReportsPaginated } from "api";
import { Nullable } from "config";
import { RootState } from "store";

import { ReportsModalMode } from "./enums";
import { IReportModal } from "./types";

const initialState = {
  reportsData: null as Nullable<IReportsPaginatedResponse["edges"]>,
  totalCount: 1,
  loading: false,
  modalData: {
    open: false,
    mode: ReportsModalMode.VIEW,
  } as IReportModal,
};

export const fetchReports = createAsyncThunk(
  "reports/fetchReports",
  async (_, { getState }) => {
    const filters = (getState() as RootState).filters;
    if (!Object.values(filters).length) {
      return;
    }
    const response = await getReportsPaginated(filters);
    return response;
  }
);

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setReportModalData: (state, action: PayloadAction<IReportModal>) => {
      state.modalData = action.payload;
    },
    closeReportModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchReports.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        reportsData: action.payload?.edges ?? null,
        totalCount: action.payload?.totalCount ?? 1,
      };
    });
    builder.addCase(fetchReports.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const { setReportModalData, closeReportModal } = reportsSlice.actions;
export default reportsSlice.reducer;
