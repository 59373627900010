import {
  Suspense,
  useCallback,
  useEffect,
  useState,
  lazy,
  useMemo,
} from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

import { getCountriesPhoneCode, refreshIdToken } from "api";
import { FallbackLoader } from "components";
import {
  CustomPalette,
  ResourceEnum,
  RoutePaths,
  STORAGE_CONSTANTS,
  ScopeEnum,
} from "config";
import { useAppSelector } from "store";
import useIdToken from "hooks/useIdToken.hook";

import { MainLayoutStyles, LayoutContentWrapperStyles } from "./styles";

const Sidebar = lazy(() => import("components/sidebar/component"));
const Topbar = lazy(() => import("components/topbar/component"));
const UserModal = lazy(
  () =>
    import(
      "pages/administration/user-management/components/user-modal/component"
    )
);

const MainLayout: React.FC = () => {
  const isUserModalOpened = useAppSelector(
    (state) => state.users.modalData.open
  );

  const location = useLocation();
  const navigate = useNavigate();

  const idToken = useIdToken();

  const [visibilityState, setVisibilityState] = useState(false);

  const handleVisibilityChange = useCallback(() => {
    setVisibilityState(document.visibilityState === "visible");
  }, []);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  useEffect(() => {
    refreshIdToken();
  }, []);

  useEffect(() => {
    if (visibilityState) {
      (async () => {
        await refreshIdToken();
        setVisibilityState(false);
      })();
    }
  }, [visibilityState]);

  useEffect(() => {
    (async () => {
      const isThereStoragePhoneCodes = localStorage.getItem(
        STORAGE_CONSTANTS.COUNTRIESPHONECODES
      );
      if (!isThereStoragePhoneCodes) {
        const res = await getCountriesPhoneCode();
        localStorage.setItem(
          STORAGE_CONSTANTS.COUNTRIESPHONECODES,
          JSON.stringify(res)
        );
        localStorage.setItem(
          STORAGE_CONSTANTS.COUNTRIES,
          JSON.stringify(
            res.map((el) => ({
              label: el.countryName,
              value: el.countryPhoneCodeId.toString(),
            }))
          )
        );
      }
    })();
  }, []);

  const hasUserEmailHistoryPermission = useMemo(() => {
    const found = idToken?.permissions.find(
      (el) => el.resource === ResourceEnum.EmailHistory
    );
    return Boolean(found?.scopes.includes(ScopeEnum.View));
  }, [idToken?.permissions]);

  useEffect(() => {
    if (
      location.pathname === RoutePaths.EMAILS &&
      !hasUserEmailHistoryPermission
    ) {
      navigate(RoutePaths.EMAILS_SEND_EMAIL);
    }
  }, [location.pathname, hasUserEmailHistoryPermission, navigate]);

  const isDashboardWebpage = location.pathname === RoutePaths.DASHBOARD;
  const isHomePage = location.pathname === RoutePaths.HOME;

  return (
    <Box sx={MainLayoutStyles}>
      <Suspense
        fallback={
          <FallbackLoader
            withBackground
            zIndex={(theme) => theme.zIndex.drawer + 1}
          />
        }
      >
        <Topbar />
        <Sidebar />
      </Suspense>
      <Suspense fallback={<FallbackLoader />}>
        <Box
          sx={(theme) =>
            LayoutContentWrapperStyles(
              theme,
              !isDashboardWebpage && !isHomePage
            )
          }
        >
          <Outlet />
          <Suspense
            fallback={<FallbackLoader backgroundColor={CustomPalette.white} />}
          >
            {isUserModalOpened && <UserModal />}
          </Suspense>
        </Box>
      </Suspense>
    </Box>
  );
};

export default MainLayout;
