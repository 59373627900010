import { ICapitalMovementByIdReturnType } from "api";
import { PartialNullable } from "config";

export enum CapitalMovementModalModes {
  VIEW = "view",
  CREATE = "create",
  EDIT = "edit",
  VIEW_FROM_PROFILE = "investorProfileView",
}

export interface IModalData {
  isOpen: boolean;
  mode: CapitalMovementModalModes;
  capitalMovementId?: number;
  dataById?: PartialNullable<ICapitalMovementByIdReturnType>;
}
