import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import {
  ILinkAgentProfilesPaginatedReturnType,
  getLinkAgentProfilesPaginated,
} from "api";
import { Nullable } from "config";
import { RootState } from "store/store";

import { LinkAgentProfileModelModes } from "./enums";
import { ILinkAgentProfileModalData } from "./types";

const initialState = {
  linkAgentProfilesData: null as Nullable<
    ILinkAgentProfilesPaginatedReturnType["edges"]
  >,
  totalCount: 1,
  loading: false,
  modalData: {
    open: false,
    mode: LinkAgentProfileModelModes.VIEW,
  } as ILinkAgentProfileModalData,
};

export const fetchLinkAgentProfiles = createAsyncThunk(
  "link-agent-profiles/fetchLinkAgentProfiles",
  async (_, { getState }) => {
    try {
      const filters = (getState() as RootState).filters;
      if (!Object.values(filters).length) {
        return;
      }

      const response = await getLinkAgentProfilesPaginated(filters);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const linkAgentProfilesSlice = createSlice({
  name: "link-agent-profiles",
  initialState,
  reducers: {
    setLinkAgentProfileModalData: (
      state,
      action: PayloadAction<ILinkAgentProfileModalData>
    ) => {
      state.modalData = action.payload;
    },
    closeLinkAgentProfileModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLinkAgentProfiles.fulfilled, (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          loading: false,
        };
      }
      return {
        ...state,
        linkAgentProfilesData: action.payload?.edges,
        totalCount: action.payload?.totalCount,
        loading: false,
      };
    });
    builder.addCase(fetchLinkAgentProfiles.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(fetchLinkAgentProfiles.rejected, (state) => {
      return {
        ...state,
        loading: false,
      };
    });
  },
});

export const { setLinkAgentProfileModalData, closeLinkAgentProfileModal } =
  linkAgentProfilesSlice.actions;
export default linkAgentProfilesSlice.reducer;
