import {
  AssetExportExchangeDocument,
  AssetsListDocument,
  AssetsGroupedByAssetPaginatedDocument,
  AssetsGroupedByExchangeDocument,
  AssetsGroupedByExchangeTypeAndAssetDocument,
  AssetsGroupedByExchangeTypeDocument,
  ExchangeAccountsDocument,
  AssetExchangeExportFileTypeDocument,
  AssetsPaginatedDocument,
  ExchangeTypesDocument,
  ExchangeCompaniesDocument,
} from "api/generated";

import { RequestTypes } from "../../enums";
import { getRequestInstance } from "../../utils";

import {
  IGetExchangeListResponse,
  IAccountExchangeModel,
  ITypeExchangeModel,
  IGetExchangeListArgs,
  IAssetExchangeModel,
  IGetExchangeListGroupByExchangeArgs,
  IGetExchangeListGroupedByExchangeResponse,
  IGetExchangeListGroupedByAssetArgs,
  IGetExchangeListGroupedByAssetResponse,
  IGetExchangeListGroupedByExchangeTypeArgs,
  IGetExchangeListGroupedByExchangeTypeResponse,
  IGetExchangeListGroupedByExchangeTypeAndAssetResponse,
  IGetExchangeListGroupedByExchangeTypeAndAssetArgs,
  IExportExchangesArgs,
} from "./types";

export async function getExchangeList(
  variables?: IGetExchangeListArgs
): Promise<IGetExchangeListResponse> {
  const makeRequest = getRequestInstance(RequestTypes.QUERY);
  const res = await makeRequest({
    query: AssetsPaginatedDocument,
    variables,
  });
  return res?.data?.assetsPaginated;
}

export async function getExchangeCompanies(): Promise<string[]> {
  const makeRequest = getRequestInstance(RequestTypes.QUERY);
  const res = await makeRequest({
    query: ExchangeCompaniesDocument,
  });
  return res?.data?.exchangeCompanies ?? [];
}

export async function getExchangeAccounts(): Promise<IAccountExchangeModel[]> {
  const makeRequest = getRequestInstance(RequestTypes.QUERY);
  const res = await makeRequest({
    query: ExchangeAccountsDocument,
  });
  return res?.data?.exchangeAccounts ?? [];
}

export async function getExchangeTypes(): Promise<ITypeExchangeModel[]> {
  const makeRequest = getRequestInstance(RequestTypes.QUERY);
  const res = await makeRequest({
    query: ExchangeTypesDocument,
  });
  return res?.data?.exchangeTypes ?? [];
}

export async function getAssets(): Promise<IAssetExchangeModel[]> {
  const makeRequest = getRequestInstance(RequestTypes.QUERY);
  const res = await makeRequest({
    query: AssetsListDocument,
  });
  return res?.data?.assetsList ?? [];
}

export async function getExchangesListGroupedByAsset(
  variables?: IGetExchangeListGroupedByAssetArgs
): Promise<IGetExchangeListGroupedByAssetResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AssetsGroupedByAssetPaginatedDocument,
      variables,
    });
    return res?.data?.assetsGroupedByAssetPaginated;
  } catch (error) {
    throw error;
  }
}

export async function getExchangesListGroupedByExchange(
  variables?: IGetExchangeListGroupByExchangeArgs
): Promise<IGetExchangeListGroupedByExchangeResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AssetsGroupedByExchangeDocument,
      variables,
    });
    return res?.data?.assetsGroupedByExchangePaginated;
  } catch (error) {
    throw error;
  }
}

export async function getExchangeListGroupedByExchangeType(
  variables?: IGetExchangeListGroupedByExchangeTypeArgs
): Promise<IGetExchangeListGroupedByExchangeTypeResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AssetsGroupedByExchangeTypeDocument,
      variables,
    });
    return res?.data?.assetsGroupedByExchangeTypePaginated;
  } catch (error) {
    throw error;
  }
}

export async function getExchangeListGroupedByExchangeTypeAndAsset(
  variables?: IGetExchangeListGroupedByExchangeTypeAndAssetArgs
): Promise<IGetExchangeListGroupedByExchangeTypeAndAssetResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AssetsGroupedByExchangeTypeAndAssetDocument,
      variables,
    });
    return res?.data?.assetsGroupedByExchangeTypeAndAssetPaginated;
  } catch (error) {
    throw error;
  }
}

export async function getExchangeExportFileTypes(): Promise<string[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AssetExchangeExportFileTypeDocument,
    });
    return res?.data?.assetExchangeExportFileType ?? [];
  } catch (error) {
    throw error;
  }
}

export async function exportExchangeFile(
  variables: IExportExchangesArgs
): Promise<string> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AssetExportExchangeDocument,
      variables,
    });
    return res?.data?.assetExport;
  } catch (error) {
    throw error;
  }
}
