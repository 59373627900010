import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getTaskPaginated, ITaskPaginatedResponse } from "api";
import { Nullable } from "config";
import { store } from "store";

import { TaskModalModes } from "./enums";
import { ITaskModalData } from "./types";

const initialState = {
  taskData: null as Nullable<ITaskPaginatedResponse["edges"]>,
  totalCount: 1,
  loading: false,
  modalData: {
    mode: TaskModalModes.VIEW,
    open: false,
    loading: false,
  } as ITaskModalData,
};

export const fetchTasks = createAsyncThunk("tasks/fetchTasks", async () => {
  const filters = store.getState().filters;
  if (!Object.values(filters).length) {
    return;
  }
  const response = await getTaskPaginated(filters);
  return response;
});

const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    setTaskModalData: (state, action: PayloadAction<ITaskModalData>) => {
      state.modalData = action.payload;
    },
    closeTaskModal: (state) => {
      state.modalData = initialState.modalData;
    },
    setTaskModalLoading: (state, action: PayloadAction<boolean>) => {
      state.modalData.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTasks.fulfilled, (state, action) => {
      if (!action.payload) return;
      return {
        ...state,
        taskData: action.payload
          ?.edges as ITaskPaginatedResponse["edges"],
        totalCount: action.payload?.totalCount,
        loading: false,
      };
    });
    builder.addCase(fetchTasks.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const { setTaskModalData, closeTaskModal, setTaskModalLoading } = taskSlice.actions;
export default taskSlice.reducer;
