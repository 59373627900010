import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  IStatementsPaginatedResponse,
  getStatementsPaginated,
  getStatementsPaginatedForInvestor,
} from "api";
import { Nullable } from "config";
import { RootState } from "store";

import { StatementModalModes } from "./enums";
import { IStatementModal } from "./types";

const initialState = {
  statementsData: null as Nullable<IStatementsPaginatedResponse["edges"]>,
  totalCount: 1,
  loading: false,
  modalData: {
    open: false,
    mode: StatementModalModes.VIEW,
  } as IStatementModal,
};

export const fetchStatements = createAsyncThunk(
  "statements/fetchStatements",
  async (isUserInvestor: boolean, { getState }) => {
    const filters = (getState() as RootState).filters;
    if (!Object.values(filters).length) {
      return;
    }
    let fetchFunction = getStatementsPaginated;
    if (isUserInvestor) {
      fetchFunction = getStatementsPaginatedForInvestor;
    }
    const response = await fetchFunction(filters);
    return response;
  }
);

const statementsSlice = createSlice({
  name: "statements",
  initialState,
  reducers: {
    setStatementModalData: (state, action: PayloadAction<IStatementModal>) => {
      state.modalData = action.payload;
    },
    closeStatementModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchStatements.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        statementsData: action.payload?.edges ?? null,
        totalCount: action.payload?.totalCount ?? 1,
      };
    });
    builder.addCase(fetchStatements.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const { setStatementModalData, closeStatementModal } =
  statementsSlice.actions;
export default statementsSlice.reducer;
