import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import {
  getVentureContactTypesPaginated,
  IVentureContactTypePaginatedResponse,
} from "api";
import { Nullable } from "config";
import { RootState } from "store/store";

import { IVentureContactTypeModalData } from "./types";
import { VentureContactTypeModalMode } from "./enums";

const initialState = {
  ventureContactTypeData: null as Nullable<
    IVentureContactTypePaginatedResponse["edges"]
  >,
  totalCount: 1,
  loading: false,
  modalData: {
    open: false,
    mode: VentureContactTypeModalMode.VIEW,
  } as IVentureContactTypeModalData,
};

const actionTypeName = "venture-contact-type-type";

export const fetchVentureContactTypes = createAsyncThunk(
  `${actionTypeName}/fetchVentureContactTypes`,
  async (_, { getState }) => {
    const filters = (getState() as RootState).filters;
    if (!Object.values(filters).length) {
      return;
    }

    const response = await getVentureContactTypesPaginated(filters);
    return response;
  }
);

const ventureContactTypeSlice = createSlice({
  name: actionTypeName,
  initialState,
  reducers: {
    setVentureContactTypeModalData: (
      state,
      action: PayloadAction<IVentureContactTypeModalData>
    ) => {
      state.modalData = action.payload;
    },
    closeVentureContactTypeModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVentureContactTypes.fulfilled, (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          loading: false,
        };
      }
      return {
        ...state,
        ventureContactTypeData: action.payload?.edges,
        totalCount: action.payload?.totalCount,
        loading: false,
      };
    });
    builder.addCase(fetchVentureContactTypes.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const { setVentureContactTypeModalData, closeVentureContactTypeModal } =
  ventureContactTypeSlice.actions;
export default ventureContactTypeSlice.reducer;
