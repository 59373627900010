import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getSources } from "api";
import { Nullable, Optional } from "config";
import { store } from "store";

import { ISourceModalData, SourceModalMode } from "./types";

const initialState = {
  sourceData: null as Nullable<any[]>,
  totalCount: 1,
  loading: false,
  modalData: {
    open: false,
    mode: SourceModalMode.VIEW,
  } as ISourceModalData,
};

export const fetchSource = createAsyncThunk(
  "source/fetchSource",
  async (isFiltersIncluded: Optional<boolean> = true) => {
    const filters = store.getState().filters;
    if (!Object.values(filters).length && isFiltersIncluded) {
      return;
    }
    const response = await getSources(isFiltersIncluded && filters);
    return response;
  }
);

const sourceSlice = createSlice({
  name: "source",
  initialState,
  reducers: {
    setSourceModalData: (state, action: PayloadAction<ISourceModalData>) => {
      state.modalData = action.payload;
    },
    closeSourceModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSource.fulfilled, (state, action) => {
      if (!action.payload) return;
      return {
        ...state,
        loading: false,
        sourceData: action.payload?.edges,
        totalCount: action.payload?.totalCount,
      };
    });
    builder.addCase(fetchSource.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const { setSourceModalData, closeSourceModal } = sourceSlice.actions;
export default sourceSlice.reducer;
