import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";
import {
  AdvisoryExitPlanGetByIdDocument,
  AdvisoryExitPlansPaginatedDocument,
  AdvisoryExitPlanCreateDocument,
  AdvisoryExitPlanUpdateDocument,
} from "api/generated";

import { Optional } from "config/types";

import {
  IAdvisoryExitPlanCreateVars,
  IAdvisoryExitPlanPaginatedResponse,
  IAdvisoryExitPlanPaginatedVars,
  IAdvisoryExitPlanUpdateVars,
  IAdvisoryExitPlanDetailedModel,
} from "./types";

export async function getAdvisoryExitPlan(
  advisoryExitPlanId: number
): Promise<Optional<IAdvisoryExitPlanDetailedModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AdvisoryExitPlanGetByIdDocument,
      variables: {
        id: advisoryExitPlanId,
      },
    });
    return res?.data?.advisoryExitPlanGetById;
  } catch (error) {
    throw error;
  }
}

export async function getAdvisoryExitPlanPaginated(
  variables: IAdvisoryExitPlanPaginatedVars
): Promise<Optional<IAdvisoryExitPlanPaginatedResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AdvisoryExitPlansPaginatedDocument,
      variables,
    });
    return res?.data?.advisoryExitPlanListPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createAdvisoryExitPlan(
  variables: IAdvisoryExitPlanCreateVars
): Promise<Optional<{ advisoryExitPlanId: number }>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AdvisoryExitPlanCreateDocument,
      variables,
    });
    return res?.data?.advisoryExitPlanCreate;
  } catch (error) {
    throw error;
  }
}

export async function updateAdvisoryExitPlan(
  variables: IAdvisoryExitPlanUpdateVars
): Promise<Optional<{ advisoryExitPlanId: number }>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AdvisoryExitPlanUpdateDocument,
      variables,
    });
    return res?.data?.advisoryExitPlanUpdate;
  } catch (error) {
    throw error;
  }
}
