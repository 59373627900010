import {
  FireblocksExportDocument,
  FireblocksAssetsListDocument,
  FireblocksListVaultsDocument,
  FireblocksListTypesDocument,
  AssetsFireblocksGroupedByAssetDocument,
  AssetsFireblocksGroupedByVaultDocument,
  AssetsFireblocksGroupedByVaultTypeAndAssetDocument,
  FireblocksExportFileTypeDocument,
  AssetsFireblocksListDocument,
  AssetsFireblocksGroupedByVaultTypeDocument,
} from "api/generated";
import { ResourceEnum, ScopeEnum } from "config";

import { RequestTypes } from "../../enums";
import { getRequestInstance } from "../../utils";
import {
  IExportFireblocksArgs,
  IFireblocksAssets,
  IFireblocksTypes,
  IFireblocksVaults,
  IGetFireblocksListArgs,
  IGetFireblocksListByAssetReturnType,
  IGetFireblocksListByVaultReturnType,
  IGetFireblocksListByVaultTypeAndAssetReturnType,
  IGetFireblocksListByVaultTypeReturnType,
  IGetFireblocksListReturnType,
} from "./types";

export async function getFireblocksList(
  variables: IGetFireblocksListArgs
): Promise<IGetFireblocksListReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AssetsFireblocksListDocument,
      variables,
    });
    return res?.data?.assetsFireblocksPaginated;
  } catch (error) {
    throw error;
  }
}

export async function getFireblocksAssets(): Promise<IFireblocksAssets[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: FireblocksAssetsListDocument,
    });
    return res?.data?.assetsList ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getFireblocksVaults(
  resource: ResourceEnum,
  scope: ScopeEnum
): Promise<IFireblocksVaults[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: FireblocksListVaultsDocument,
      variables: {
        resource,
        scope,
      },
    });
    return res?.data?.fireblocksListVaults ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getFireblocksTypes(
  resource: ResourceEnum,
  scope: ScopeEnum
): Promise<IFireblocksTypes[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: FireblocksListTypesDocument,
      variables: {
        resource,
        scope,
      },
    });
    return res?.data?.fireblocksListTypes?.map((el) => ({ name: el })) ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getFireblocksListByVault(
  variables: IGetFireblocksListArgs
): Promise<IGetFireblocksListByVaultReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AssetsFireblocksGroupedByVaultDocument,
      variables,
    });
    return res?.data?.assetsFireblocksGroupedByVaultPaginated;
  } catch (e) {
    throw e;
  }
}

export async function getFireblocksListByAsset(
  variables: IGetFireblocksListArgs
): Promise<IGetFireblocksListByAssetReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AssetsFireblocksGroupedByAssetDocument,
      variables,
    });
    return res?.data?.assetsFireblocksGroupedByAssetPaginated;
  } catch (e) {
    throw e;
  }
}

export async function getFireblocksListByVaultType(
  variables: IGetFireblocksListArgs
): Promise<IGetFireblocksListByVaultTypeReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AssetsFireblocksGroupedByVaultTypeDocument,
      variables,
    });
    return res?.data?.assetsFireblocksGroupedByVaultTypePaginated;
  } catch (e) {
    throw e;
  }
}

export async function getFireblocksListByVaultTypeAndAsset(
  variables: IGetFireblocksListArgs
): Promise<IGetFireblocksListByVaultTypeAndAssetReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AssetsFireblocksGroupedByVaultTypeAndAssetDocument,
      variables,
    });
    return res?.data?.assetsFireblocksGroupedByVaultTypeAndAssetPaginated;
  } catch (e) {
    throw e;
  }
}

export async function getFireblocksExportFileTypes(): Promise<string[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: FireblocksExportFileTypeDocument,
    });
    return res?.data?.fireblocksExportFileType ?? [];
  } catch (e) {
    throw e;
  }
}

export async function exportFireblocks(
  variables: IExportFireblocksArgs
): Promise<string> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: FireblocksExportDocument,
      variables,
    });
    return res?.data?.fireblocksExport;
  } catch (e) {
    throw e;
  }
}
