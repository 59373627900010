import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";
import {
  AdvisoryDistributionGetByIdDocument,
  AdvisoryDistributionListPaginatedDocument,
  AdvisoryDistributionCreateDocument,
  AdvisoryDistributionUpdateDocument,
  AdvisoryDistributionDistributionNumberDocument,
  AdvisoryDistributionCalculationsForManagedSellingDocument,
  AdvisoryDistributionCalculationsForTokenDisbursementDocument,
} from "api/generated";

import { Optional } from "config/types";

import {
  IAdvisoryDistributionCreateVars,
  IAdvisoryDistributionPaginatedResponse,
  IAdvisoryDistributionPaginatedVars,
  IAdvisoryDistributionUpdateVars,
  IAdvisoryDistributionDetailedModel,
  IAdvisoryDistributionCalculationsForManagedSelling,
  IAdvisoryDistributionCalculationsForTokenDisbursement,
} from "./types";

export async function getAdvisoryDistribution(
  advisoryDistributionId: number
): Promise<Optional<IAdvisoryDistributionDetailedModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AdvisoryDistributionGetByIdDocument,
      variables: {
        id: advisoryDistributionId,
      },
    });
    return res?.data?.advisoryDistributionGetById;
  } catch (error) {
    throw error;
  }
}

export async function getAdvisoryDistributionNumber(
  advisoryProjectId: number,
  type: string,
  advisoryTrancheId?: number
): Promise<number> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AdvisoryDistributionDistributionNumberDocument,
      variables: {
        advisoryProjectId,
        type,
        advisoryTrancheId,
      },
    });
    return res?.data?.advisoryDistributionDistributionNumber ?? 1;
  } catch (error) {
    throw error;
  }
}

export async function getAdvisoryDistributionCalculationsForManagedSelling(
  advisoryTrancheId: number,
  amount: number,
  price?: number,
  advisoryDistributionId?: number
): Promise<IAdvisoryDistributionCalculationsForManagedSelling> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AdvisoryDistributionCalculationsForManagedSellingDocument,
      variables: {
        amount,
        price,
        advisoryDistributionId,
        advisoryTrancheId,
      },
    });
    return res?.data?.advisoryDistributionCalculationsForManagedSelling ?? {};
  } catch (error) {
    throw error;
  }
}

export async function getAdvisoryDistributionCalculationsForTokenDisbursement(
  advisoryProjectId: number,
  amount: number,
  price?: number,
  advisoryDistributionId?: number
): Promise<IAdvisoryDistributionCalculationsForTokenDisbursement> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AdvisoryDistributionCalculationsForTokenDisbursementDocument,
      variables: {
        amount,
        price,
        advisoryDistributionId,
        advisoryProjectId,
      },
    });
    return (
      res?.data?.advisoryDistributionCalculationsForTokenDisbursement ?? {}
    );
  } catch (error) {
    throw error;
  }
}

export async function getAdvisoryDistributionPaginated(
  variables: IAdvisoryDistributionPaginatedVars
): Promise<Optional<IAdvisoryDistributionPaginatedResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AdvisoryDistributionListPaginatedDocument,
      variables,
    });
    return res?.data?.advisoryDistributionListPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createAdvisoryDistribution(
  variables: IAdvisoryDistributionCreateVars
): Promise<Optional<{ advisoryDistributionId: number }>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AdvisoryDistributionCreateDocument,
      variables,
    });
    return res?.data?.advisoryDistributionCreate;
  } catch (error) {
    throw error;
  }
}

export async function updateAdvisoryDistribution(
  variables: IAdvisoryDistributionUpdateVars
): Promise<Optional<{ advisoryDistributionId: number }>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AdvisoryDistributionUpdateDocument,
      variables,
    });
    return res?.data?.advisoryDistributionUpdate;
  } catch (error) {
    throw error;
  }
}
