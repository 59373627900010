import { styled } from "@mui/material";
import { ISpinnerProps } from "./types";

export const StyledSpinner = styled("div", {
  shouldForwardProp: (prop) => prop !== "spinnerColor" && prop !== "size",
})<ISpinnerProps>(({ theme, spinnerColor, size }) => ({
  display: "inline-block",
  position: "relative",
  width: "48px",
  height: "48px",
  ...(size === "small" && {
    width: "32px",
    height: "32px",
  }),
  "& div": {
    position: "absolute",
    width: "5.64px",
    height: "5.64px",
    background: theme.palette.custom[spinnerColor ?? "light_gray"],
    opacity: "0.3",
    borderRadius: "50%",
    animation: "spinner 0.8s linear infinite",
    "&:nth-of-type(1)": {
      animationDelay: "0s",
      top: "30.62px",
      left: "37.97px",
    },
    "&:nth-of-type(2)": {
      animationDelay: "-0.1s",
      top: "18.17px",
      left: "42.95px",
    },
    "&:nth-of-type(3)": {
      animationDelay: "-0.2s",
      top: "4.6px",
      left: "37.97px",
    },
    "&:nth-of-type(4)": {
      animationDelay: "-0.3s",
      top: "0",
      left: "24px",
    },
    "&:nth-of-type(5)": {
      animationDelay: "-0.4s",
      top: "5.23px",
      left: "9.94px",
    },
    "&:nth-of-type(6)": {
      animationDelay: "-0.5s",
      top: "18.17px",
      left: "5.65px",
    },
    "&:nth-of-type(7)": {
      animationDelay: "-0.6s",
      top: "32.5px",
      left: "10.57px",
    },
    "&:nth-of-type(8)": {
      animationDelay: "-0.7s",
      top: "35.32px",
      left: "24px",
    },

    ...(size === "small" && {
      width: "3.64px",
      height: "3.64px",
      "&:nth-of-type(1)": {
        animationDelay: "0s",
        top: "25.62px",
        left: "26.97px",
      },
      "&:nth-of-type(2)": {
        animationDelay: "-0.1s",
        top: "14.17px",
        left: "29.95px",
      },
      "&:nth-of-type(3)": {
        animationDelay: "-0.2s",
        top: "4.6px",
        left: "25.97px",
      },
      "&:nth-of-type(4)": {
        animationDelay: "-0.3s",
        top: "0",
        left: "16px",
      },
      "&:nth-of-type(5)": {
        animationDelay: "-0.4s",
        top: "4.23px",
        left: "6.94px",
      },
      "&:nth-of-type(6)": {
        animationDelay: "-0.5s",
        top: "13.17px",
        left: "2.65px",
      },
      "&:nth-of-type(7)": {
        animationDelay: "-0.6s",
        top: "25.5px",
        left: "4.57px",
      },
      "&:nth-of-type(8)": {
        animationDelay: "-0.7s",
        top: "29.32px",
        left: "16px",
      },
    }),

    "@keyframes spinner": {
      "0%, 100%": {
        transform: "scale(1)",
      },
      "12.5%": {
        transform: "scale(1.10)",
        opacity: "0.4",
      },
      "25%": {
        transform: "scale(1.22)",
        opacity: "0.5",
      },
      "37.5%": {
        transform: "scale(1.33)",
        opacity: "0.6",
      },
      "50%": {
        transform: "scale(1.44)",
        opacity: "0.7",
      },
      "62.5%": {
        transform: "scale(1.55)",
        opacity: "0.8",
      },
      "75%": {
        transform: "scale(1.66)",
        opacity: "0.9",
      },
      "87.5%": {
        transform: "scale(1.77)",
        opacity: "1",
      },
    },
  },
}));
