import {
  VentureValuationControlPaginatedDocument,
  VentureValuationControlUpdateDocument,
  VentureValuationControlGetByProjectDocument,
  VentureValuationControlInvestorDocumentDownloadDocument,
  VentureValuationControlInvestorDocumentUploadDocument,
  VentureValuationControlCreateFinancialRoundDocument,
  VentureValuationControlUpdateFinancialRoundDocument,
  VentureValuationControlDeleteFinancialRoundDocument,
  VentureValuationControlFinancialRoundHistoryDocument,
  VentureProjectProfileListFinancialsRoundsDocument,
} from "api/generated";
import { Optional, PartialNullable } from "config";
import { IFile } from "components";

import { RequestTypes } from "../../enums";
import { getRequestInstance } from "../../utils";
import {
  VentureValuationControlUpdateResponse,
  IGetVentureValuationControlResponse,
  IVentureValuationControlPaginatedResponse,
  IVentureValuationControlsPaginatedVars,
  IVentureValuationControlUpdateVars,
  IVentureValuationControlInvestorDocumentModel,
  FinancialRoundHistoryResponse,
  IVentureValuationControlFinancialRoundCreateVars,
  IVentureValuationControlFinancialRoundUpdateVars,
  IVentureValuationControlFinancialRoundDeleteVars,
  VentureFinancialRoundListResponse,
} from "./types";

export async function getVentureValuationControlsPaginated(
  variables: IVentureValuationControlsPaginatedVars
): Promise<IVentureValuationControlPaginatedResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureValuationControlPaginatedDocument,
      variables,
    });
    return res?.data?.ventureValuationControlPaginated;
  } catch (error) {
    throw error;
  }
}

export async function getVentureValuationControl(
  projectProfileId: number
): Promise<PartialNullable<IGetVentureValuationControlResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureValuationControlGetByProjectDocument,
      variables: {
        projectProfileId,
      },
    });
    return res?.data?.ventureValuationControlGetByProject;
  } catch (error) {
    throw error;
  }
}

export async function getVentureFinancialRoundList(): Promise<VentureFinancialRoundListResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureProjectProfileListFinancialsRoundsDocument,
    });
    return res?.data?.ventureProjectProfileListFinancialsRounds ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getVentureValuationControlFinancialRoundHistory(
  projectProfileId: number
): Promise<FinancialRoundHistoryResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureValuationControlFinancialRoundHistoryDocument,
      variables: {
        projectProfileId,
      },
    });
    return res?.data?.ventureValuationControlFinancialRoundHistory ?? [];
  } catch (error) {
    throw error;
  }
}

export async function updateVentureValuationControl(
  variables: IVentureValuationControlUpdateVars
): Promise<VentureValuationControlUpdateResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureValuationControlUpdateDocument,
      variables,
    });
    return res?.data?.ventureValuationControlUpdate;
  } catch (error) {
    throw error;
  }
}

export async function createVentureValuationControlFinancialRound(
  variables: IVentureValuationControlFinancialRoundCreateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureValuationControlCreateFinancialRoundDocument,
      variables,
    });
    return Boolean(res?.data?.ventureValuationControlCreateFinancialRound);
  } catch (error) {
    throw error;
  }
}

export async function updateVentureValuationControlFinancialRound(
  variables: IVentureValuationControlFinancialRoundUpdateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureValuationControlUpdateFinancialRoundDocument,
      variables,
    });
    return Boolean(res?.data?.ventureValuationControlUpdateFinancialRound);
  } catch (error) {
    throw error;
  }
}

export async function deleteVentureValuationControlFinancialRound(
  variables: IVentureValuationControlFinancialRoundDeleteVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureValuationControlDeleteFinancialRoundDocument,
      variables,
    });
    return Boolean(res?.data?.ventureValuationControlDeleteFinancialRound);
  } catch (error) {
    throw error;
  }
}

export async function uploadVentureValuationControlInvestorDocument(
  variables: IFile
): Promise<Optional<IVentureValuationControlInvestorDocumentModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureValuationControlInvestorDocumentUploadDocument,
      variables,
    });
    return res?.data?.ventureValuationControlInvestorDocumentUpload;
  } catch (error) {
    throw error;
  }
}

export async function downloadVentureValuationControlInvestorDocument(
  documentId: number
): Promise<Optional<string>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureValuationControlInvestorDocumentDownloadDocument,
      variables: { ventureValuationControlInvestorDocumentId: documentId },
    });
    return res?.data?.ventureValuationControlInvestorDocumentDownload;
  } catch (error) {
    throw error;
  }
}
