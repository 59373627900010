import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getDocumentsList, IDocumentListResponse } from "api";
import { Nullable, Optional } from "config";
import { store } from "store";

import { IDocumentListModalData, DocumentModalMode } from "./types";

const initialState = {
  documentList: null as Nullable<IDocumentListResponse["edges"]>,
  totalCount: 1,
  loading: false,
  modalData: {
    open: false,
    mode: DocumentModalMode.VIEW,
  } as IDocumentListModalData,
};

export const fetchDocumentList = createAsyncThunk(
  "document-list/fetchDocumentList",
  async (isFiltersIncluded: Optional<boolean> = true) => {
    const filters = store.getState().filters;
    if (!Object.values(filters).length && isFiltersIncluded) {
      return;
    }
    const response = await getDocumentsList(isFiltersIncluded && filters);
    return response;
  }
);

const documentListSlice = createSlice({
  name: "document-list",
  initialState,
  reducers: {
    setDocumentListModalData: (state, action) => {
      state.modalData = action.payload;
    },
    closeDocumentListModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDocumentList.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        documentList: action.payload?.edges ?? null,
        totalCount: action.payload?.totalCount ?? 1,
      };
    });
    builder.addCase(fetchDocumentList.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const { setDocumentListModalData, closeDocumentListModal } =
  documentListSlice.actions;
export default documentListSlice.reducer;
