import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getCapitalMovementList, IGetCapitalMovementListReturnType } from "api";
import { Nullable } from "config";
import { store } from "store";

import { CapitalMovementModalModes, IModalData } from "./types";

const initialState = {
  capitalMovementData: null as Nullable<
    IGetCapitalMovementListReturnType["edges"]
  >,
  totalCount: 1,
  loading: false,
  modalData: {
    isOpen: false,
    mode: CapitalMovementModalModes.CREATE,
  } as IModalData,
};

export const fetchCapitalMovements = createAsyncThunk(
  "capital-movement/fetchCapitalMovements",
  async () => {
    const filters = store.getState().filters;
    if (!Object.values(filters).length) {
      return;
    }
    const response = await getCapitalMovementList(filters);
    return response;
  }
);

const capitalMovementSlice = createSlice({
  name: "capital-movement",
  initialState,
  reducers: {
    setCapitalMovementModalData: (state, action: PayloadAction<IModalData>) => {
      state.modalData = action.payload;
    },
    closeCapitalMovementModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCapitalMovements.fulfilled, (state, action) => {
      if (!action.payload) return;
      return {
        ...state,
        capitalMovementData: action.payload?.edges,
        totalCount: action.payload?.totalCount,
        loading: false,
      };
    });
    builder.addCase(fetchCapitalMovements.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const { setCapitalMovementModalData, closeCapitalMovementModal } =
  capitalMovementSlice.actions;
export default capitalMovementSlice.reducer;
