import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";
import {
  TaskCreateDocument,
  TaskDeleteDocument,
  TaskDocumentDownloadDocument,
  TaskDocumentUploadDocument,
  TaskGetByIdDocument,
  TaskListByRelatedEntityDocument,
  TaskListDocument,
  TaskListPaginatedDocument,
  TaskListRelatedEntitiesDocument,
  TaskNoteCreateDocument,
  TaskNoteDeleteDocument,
  TaskNoteDocumentDeleteDocument,
  TaskNoteDocumentDownloadDocument,
  TaskNoteDocumentUploadDocument,
  TaskNoteUpdateDocument,
  TaskNoteVerifyDocument,
  TaskStatusUpdateDocument,
  TaskUpdateDocument,
} from "api/generated";

import { Optional, PartialNullable } from "config/types";

import {
  ITaskModel,
  ITaskCreateVars,
  ITaskPaginatedResponse,
  ITaskPaginatedVars,
  ITaskUpdateVars,
  ITaskRelatedEntity,
  ITaskForModalModel,
  ITaskListModel,
  ICreateTaskNoteForNotes,
  ITaskNoteCreateReturnType,
  IUpdateTaskNoteForNotes,
  IUpdateTaskNoteResponse,
  ITaskNoteModel,
  IUploadTaskNoteDocumentReturnType,
  IUploadTaskDocArgs,
  IUploadDocumentReturnType,
  ITaskStatusUpdateVars,
} from "./types";

export async function getTask(taskId: number): Promise<Optional<ITaskModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: TaskGetByIdDocument,
      variables: {
        id: taskId,
      },
    });
    return res?.data?.taskGetById;
  } catch (error) {
    throw error;
  }
}

export async function getTaskListByRelatedEntities(
  entityTypeName: string,
  relatedEntityId: number
): Promise<ITaskForModalModel[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: TaskListByRelatedEntityDocument,
      variables: {
        entityTypeName,
        relatedEntityId,
      },
    });
    return res?.data?.taskListByRelatedEntity || [];
  } catch (error) {
    throw error;
  }
}

export async function getTaskPaginated(
  variables: ITaskPaginatedVars
): Promise<Optional<ITaskPaginatedResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: TaskListPaginatedDocument,
      variables,
    });
    return res?.data?.taskListPaginated;
  } catch (error) {
    throw error;
  }
}

export async function getTaskList(
  filters: ITaskPaginatedVars["filters"]
): Promise<ITaskListModel[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: TaskListDocument,
      variables: {
        filters,
      },
    });
    return res?.data?.taskList || [];
  } catch (error) {
    throw error;
  }
}

export async function getTaskRelatedEntities(
  entityTypeId: number
): Promise<ITaskRelatedEntity[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: TaskListRelatedEntitiesDocument,
      variables: { entityTypeId },
    });
    return res?.data?.taskListRelatedEntities ?? [];
  } catch (error) {
    throw error;
  }
}

export async function createTask(
  variables: ITaskCreateVars
): Promise<Optional<{ taskId: number }>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: TaskCreateDocument,
      variables,
    });
    return res?.data?.taskCreate;
  } catch (error) {
    throw error;
  }
}

export async function updateTask(
  variables: ITaskUpdateVars
): Promise<Optional<{ taskId: number }>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: TaskUpdateDocument,
      variables,
    });
    return res?.data?.taskUpdate;
  } catch (error) {
    throw error;
  }
}

export async function updateTaskStatus(
  variables: ITaskStatusUpdateVars
): Promise<Optional<{ taskId: number }>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: TaskStatusUpdateDocument,
      variables,
    });
    return res?.data?.taskStatusUpdate;
  } catch (error) {
    throw error;
  }
}

export async function deleteTask(taskId: number): Promise<Optional<boolean>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: TaskDeleteDocument,
      variables: {
        id: taskId,
      },
    });
    return res?.data?.taskDelete;
  } catch (error) {
    throw error;
  }
}

export async function createTaskNote(
  variables: ICreateTaskNoteForNotes
): Promise<Optional<PartialNullable<ITaskNoteCreateReturnType>>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: TaskNoteCreateDocument,
      variables,
    });
    return res?.data?.taskNoteCreate;
  } catch (error) {
    throw error;
  }
}

export async function updateTaskNote(
  variables: IUpdateTaskNoteForNotes
): Promise<Optional<IUpdateTaskNoteResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: TaskNoteUpdateDocument,
      variables,
    });
    return res?.data?.taskNoteUpdate;
  } catch (error) {
    throw error;
  }
}

export async function deleteTaskNote(taskNoteId: number): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: TaskNoteDeleteDocument,
      variables: {
        taskNoteId,
      },
    });
    return Boolean(res?.data?.taskNoteDelete);
  } catch (error) {
    throw error;
  }
}

export async function verifyTaskNote(
  taskNoteId: number
): Promise<Optional<Pick<ITaskNoteModel, "isLocked">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: TaskNoteVerifyDocument,
      variables: {
        taskNoteId,
      },
    });
    return res?.data?.taskNoteVerify;
  } catch (error) {
    throw error;
  }
}

export async function uploadTaskNoteDocument(
  variables: IUploadTaskDocArgs
): Promise<Optional<PartialNullable<IUploadTaskNoteDocumentReturnType>>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: TaskNoteDocumentUploadDocument,
      variables,
    });
    return res?.data?.taskNoteDocumentUpload;
  } catch (error) {
    throw error;
  }
}

export async function downloadTaskNoteDocument(
  taskDocumentId: number
): Promise<Optional<string>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: TaskNoteDocumentDownloadDocument,
      variables: { taskDocumentId },
    });
    return res?.data?.taskNoteDocumentDownload;
  } catch (error) {
    throw error;
  }
}

export async function deleteTaskNoteDocument(
  taskDocumentId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: TaskNoteDocumentDeleteDocument,
      variables: { taskDocumentId },
    });
    return Boolean(res?.data?.taskNoteDocumentDelete);
  } catch (error) {
    throw error;
  }
}

export async function uploadTaskDocument(
  variables: IUploadTaskDocArgs
): Promise<Optional<IUploadDocumentReturnType>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: TaskDocumentUploadDocument,
      variables,
    });
    return res?.data?.taskDocumentUpload;
  } catch (error) {
    throw error;
  }
}

export async function downloadTaskDocument(
  documentId: number
): Promise<Optional<string>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: TaskDocumentDownloadDocument,
      variables: {
        documentId,
      },
    });
    return res?.data?.taskDocumentDownload;
  } catch (error) {
    throw error;
  }
}
