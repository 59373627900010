import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import {
  IVentureContactListPaginatedResponse,
  getVentureContactListPaginated,
} from "api";
import { Nullable } from "config";
import { RootState } from "store/store";

import { VentureContactModalMode } from "./enums";
import { IVentureContactModalData } from "./types";

const initialState = {
  ventureContactData: null as Nullable<
    IVentureContactListPaginatedResponse["edges"]
  >,
  totalCount: 1,
  loading: false,
  modalData: {
    open: false,
    mode: VentureContactModalMode.VIEW,
  } as IVentureContactModalData,
};

export const fetchVentureContactList = createAsyncThunk(
  "venture-contact/getVentureContactListPaginated",
  async (_, { getState }) => {
    try {
      const filters = (getState() as RootState).filters;
      if (!Object.values(filters).length) {
        return;
      }

      const response = await getVentureContactListPaginated(filters);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const VentureContactSlice = createSlice({
  name: "venture-contact",
  initialState,
  reducers: {
    setVentureContactModalData: (
      state,
      action: PayloadAction<IVentureContactModalData>
    ) => {
      state.modalData = action.payload;
    },
    closeVentureContactModal: (state) => {
      state.modalData = initialState.modalData;
    },
    switchVentureContactModalMode: (state) => {
      state.modalData.mode = VentureContactModalMode.EDIT;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVentureContactList.fulfilled, (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          loading: false,
        };
      }
      return {
        ...state,
        ventureContactData: action.payload?.edges,
        totalCount: action.payload?.totalCount,
        loading: false,
      };
    });
    builder.addCase(fetchVentureContactList.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(fetchVentureContactList.rejected, (state) => {
      return {
        ...state,
        loading: false,
      };
    });
  },
});

export const {
  setVentureContactModalData,
  closeVentureContactModal,
  switchVentureContactModalMode,
} = VentureContactSlice.actions;
export default VentureContactSlice.reducer;
