import {
  AssetAttributionCapitalInvestmentCreateDocument,
  AssetAttributionCapitalInvestmentDeleteDocument,
  AssetAttributionCapitalInvestmentListByProjectProfileDocument,
  AssetAttributionCapitalInvestmentUpdateDocument,
  AssetAttributionCommitmentCreateDocument,
  AssetAttributionCommitmentDeleteDocument,
  AssetAttributionCommitmentListByProjectProfileDocument,
  AssetAttributionCommitmentUpdateDocument,
  AssetAttributionExitCreateDocument,
  AssetAttributionExitDeleteDocument,
  AssetAttributionExitSummaryByProjectProfileDocument,
  AssetAttributionExitUpdateDocument,
  AssetAttributionInvestmentCreateDocument,
  AssetAttributionInvestmentDeleteDocument,
  AssetAttributionInvestmentListByProjectProfileDocument,
  AssetAttributionInvestmentUpdateDocument,
  AssetAttributionListAssetAttributionDescriptionsDocument,
  AssetAttributionListCapitalInvestmentTypesDocument,
  AssetAttributionListInvestmentChecksDocument,
  AssetAttributionListInvestmentCurrenciesDocument,
  AssetAttributionListInvestmentRoundsDocument,
  AssetAttributionPaginatedDocument,
  AssetAttributionUpdateDocument,
  VentureAssetAttributionGetByProjectProfileIdDocument,
  VentureAssetAttributionListExitTypeDocument,
  VentureAssetAttributionListInvestmentSourceDocument,
} from "api/generated";
import { InvestmentTypeEnum, PartialNullable } from "config";

import { RequestTypes } from "../../enums";
import { getRequestInstance } from "../../utils";
import {
  AssetAttributionCreateUpdateResponse,
  IAssetAttributionCapitalInvestmentCreateVars,
  IAssetAttributionCapitalInvestmentDeleteVars,
  IAssetAttributionCapitalInvestmentModel,
  IAssetAttributionCapitalInvestmentUpdateVars,
  IAssetAttributionCommitmentCreateVars,
  IAssetAttributionCommitmentDeleteVars,
  IAssetAttributionCommitmentModel,
  IAssetAttributionCommitmentUpdateVars,
  IAssetAttributionDescription,
  IAssetAttributionExitCreateVars,
  IAssetAttributionExitDeleteVars,
  IAssetAttributionExitSummaryModel,
  IAssetAttributionExitUpdateVars,
  IAssetAttributionInvestmentCreateVars,
  IAssetAttributionInvestmentDeleteVars,
  IAssetAttributionInvestmentModel,
  IAssetAttributionInvestmentUpdateVars,
  IAssetAttributionModel,
  IAssetAttributionPaginatedResponse,
  IAssetAttributionsPaginatedVars,
  IAssetAttributionUpdateVars,
  ICapitalInvestmentType,
  IInvestmentCheck,
  IInvestmentRound,
} from "./types";
import { IInvestmentCurrency } from "../venture-project-profile";

export async function getAssetAttributionsPaginated(
  variables: IAssetAttributionsPaginatedVars
): Promise<IAssetAttributionPaginatedResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AssetAttributionPaginatedDocument,
      variables,
    });
    return res?.data?.ventureAssetAttributionPaginated;
  } catch (error) {
    throw error;
  }
}

// export async function createAssetAttribution(
//   variables: IAssetAttributionCreateVars
// ): Promise<AssetAttributionCreateUpdateResponse> {
//   try {
//     const makeRequest = getRequestInstance(RequestTypes.MUTATE);
//     const res = await makeRequest({
//       mutation: AssetAttributionCreateDocument,
//       variables,
//     });
//     return res?.data?.ventureAssetAttributionCreate;
//   } catch (error) {
//     throw error;
//   }
// }

export async function updateAssetAttribution(
  variables: IAssetAttributionUpdateVars
): Promise<AssetAttributionCreateUpdateResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AssetAttributionUpdateDocument,
      variables,
    });
    return res?.data?.ventureAssetAttributionUpdate;
  } catch (error) {
    throw error;
  }
}

export async function getAssetAttributionByProject(
  projectProfileId: number
): Promise<PartialNullable<IAssetAttributionModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureAssetAttributionGetByProjectProfileIdDocument,
      variables: {
        projectProfileId,
      },
    });
    return res?.data?.ventureAssetAttributionGetByProjectProfileId;
  } catch (error) {
    throw error;
  }
}

// export async function deleteAssetAttribution(
//   assetAttributionId: number
// ): Promise<boolean> {
//   try {
//     const makeRequest = getRequestInstance(RequestTypes.MUTATE);
//     const res = await makeRequest({
//       mutation: AssetAttributionDeleteDocument,
//       variables: {
//         assetAttributionId,
//       },
//     });
//     return Boolean(res?.data?.ventureAssetAttributionDelete);
//   } catch (error) {
//     throw error;
//   }
// }

export async function listInvestmentSourceForAssetAttribution(
  investmentType: InvestmentTypeEnum,
  projectProfileId?: number
): Promise<string[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureAssetAttributionListInvestmentSourceDocument,
      variables: {
        investmentType,
        projectProfileId,
      },
    });
    return res?.data?.ventureAssetAttributionListInvestmentSource || [];
  } catch (error) {
    throw error;
  }
}

export async function listExitsTypeForAssetAttribution(): Promise<
  Record<number, string>
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureAssetAttributionListExitTypeDocument,
    });
    return res?.data?.ventureAssetAttributionListExitType || {};
  } catch (error) {
    throw error;
  }
}

export async function getAssetAttributionDescriptionsForAssetAttribution(): Promise<
  IAssetAttributionDescription[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AssetAttributionListAssetAttributionDescriptionsDocument,
    });
    return (
      res?.data?.ventureAssetAttributionListAssetAttributionDescriptions ?? []
    );
  } catch (e) {
    throw e;
  }
}

export async function getInvestmentRoundsForAssetAttribution(): Promise<
  IInvestmentRound[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AssetAttributionListInvestmentRoundsDocument,
    });
    return res?.data?.ventureAssetAttributionListInvestmentRounds ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getInvestmentChecksForAssetAttribution(): Promise<
  IInvestmentCheck[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AssetAttributionListInvestmentChecksDocument,
    });
    return res?.data?.ventureAssetAttributionListInvestmentChecks ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getCapitalInvestmentTypesForProjectProfile(): Promise<
  ICapitalInvestmentType[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AssetAttributionListCapitalInvestmentTypesDocument,
    });
    return res?.data?.ventureProjectProfileListCapitalInvestmentTypes ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getInvestmentCurrenciesForAssetAttribution(): Promise<
  IInvestmentCurrency[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AssetAttributionListInvestmentCurrenciesDocument,
    });
    return res?.data?.ventureAssetAttributionListInvestmentCurrencies ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getAssetAttributionExitSummaryByProjectProfile(
  projectProfileId: number
): Promise<IAssetAttributionExitSummaryModel> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AssetAttributionExitSummaryByProjectProfileDocument,
      variables: {
        projectProfileId,
      },
    });
    return res?.data?.assetAttributionExitSummaryByProjectProfile || {};
  } catch (e) {
    throw e;
  }
}

export async function getAssetAttributionCommitmentListByProjectProfile(
  projectProfileId: number
): Promise<IAssetAttributionCommitmentModel[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AssetAttributionCommitmentListByProjectProfileDocument,
      variables: {
        projectProfileId,
      },
    });
    return res?.data?.assetAttributionCommitmentListByProjectProfile || [];
  } catch (e) {
    throw e;
  }
}

export async function getAssetAttributionInvestmentListByProjectProfile(
  projectProfileId: number
): Promise<IAssetAttributionInvestmentModel[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AssetAttributionInvestmentListByProjectProfileDocument,
      variables: {
        projectProfileId,
      },
    });
    return res?.data?.assetAttributionInvestmentListByProjectProfile || [];
  } catch (e) {
    throw e;
  }
}

export async function getAssetAttributionCapitalInvestmentListByProjectProfile(
  projectProfileId: number
): Promise<IAssetAttributionCapitalInvestmentModel[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AssetAttributionCapitalInvestmentListByProjectProfileDocument,
      variables: {
        projectProfileId,
      },
    });
    return (
      res?.data?.assetAttributionCapitalInvestmentListByProjectProfile || []
    );
  } catch (e) {
    throw e;
  }
}

export async function createAssetAttributionCommitment(
  variables: IAssetAttributionCommitmentCreateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AssetAttributionCommitmentCreateDocument,
      variables,
    });
    return Boolean(res?.data?.ventureAssetAttributionCommitmentCreate);
  } catch (error) {
    throw error;
  }
}

export async function updateAssetAttributionCommitment(
  variables: IAssetAttributionCommitmentUpdateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AssetAttributionCommitmentUpdateDocument,
      variables,
    });
    return Boolean(res?.data?.ventureAssetAttributionCommitmentUpdate);
  } catch (error) {
    throw error;
  }
}

export async function deleteAssetAttributionCommitment(
  variables: IAssetAttributionCommitmentDeleteVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AssetAttributionCommitmentDeleteDocument,
      variables,
    });
    return Boolean(res?.data?.ventureAssetAttributionCommitmentDelete);
  } catch (error) {
    throw error;
  }
}

export async function createAssetAttributionExit(
  variables: IAssetAttributionExitCreateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AssetAttributionExitCreateDocument,
      variables,
    });
    return Boolean(res?.data?.ventureAssetAttributionExitCreate);
  } catch (error) {
    throw error;
  }
}

export async function updateAssetAttributionExit(
  variables: IAssetAttributionExitUpdateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AssetAttributionExitUpdateDocument,
      variables,
    });
    return Boolean(res?.data?.ventureAssetAttributionExitUpdate);
  } catch (error) {
    throw error;
  }
}

export async function deleteAssetAttributionExit(
  variables: IAssetAttributionExitDeleteVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AssetAttributionExitDeleteDocument,
      variables,
    });
    return Boolean(res?.data?.ventureAssetAttributionExitDelete);
  } catch (error) {
    throw error;
  }
}

export async function createAssetAttributionInvestment(
  variables: IAssetAttributionInvestmentCreateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AssetAttributionInvestmentCreateDocument,
      variables,
    });
    return Boolean(res?.data?.ventureAssetAttributionInvestmentCreate);
  } catch (error) {
    throw error;
  }
}

export async function updateAssetAttributionInvestment(
  variables: IAssetAttributionInvestmentUpdateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AssetAttributionInvestmentUpdateDocument,
      variables,
    });
    return Boolean(res?.data?.ventureAssetAttributionInvestmentUpdate);
  } catch (error) {
    throw error;
  }
}

export async function deleteAssetAttributionInvestment(
  variables: IAssetAttributionInvestmentDeleteVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AssetAttributionInvestmentDeleteDocument,
      variables,
    });
    return Boolean(res?.data?.ventureAssetAttributionInvestmentDelete);
  } catch (error) {
    throw error;
  }
}

export async function createAssetAttributionCapitalInvestment(
  variables: IAssetAttributionCapitalInvestmentCreateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AssetAttributionCapitalInvestmentCreateDocument,
      variables,
    });
    return Boolean(res?.data?.ventureAssetAttributionCapitalInvestmentCreate);
  } catch (error) {
    throw error;
  }
}

export async function updateAssetAttributionCapitalInvestment(
  variables: IAssetAttributionCapitalInvestmentUpdateVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AssetAttributionCapitalInvestmentUpdateDocument,
      variables,
    });
    return Boolean(res?.data?.ventureAssetAttributionCapitalInvestmentUpdate);
  } catch (error) {
    throw error;
  }
}

export async function deleteAssetAttributionCapitalInvestment(
  variables: IAssetAttributionCapitalInvestmentDeleteVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AssetAttributionCapitalInvestmentDeleteDocument,
      variables,
    });
    return Boolean(res?.data?.ventureAssetAttributionCapitalInvestmentDelete);
  } catch (error) {
    throw error;
  }
}
