import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { FallbackLoader, ProtectedRoute } from "components";
import { IRoute, RoutePaths } from "config";
import { MainLayout } from "layouts";
import { useAppSelector } from "store";
import useIdToken from "hooks/useIdToken.hook";

import { IRoutingProps } from "./types";

const SentryRoutes =
  process.env.REACT_APP_SENTRY_DSN && process.env.REACT_APP_PROJECT_ENV
    ? Sentry.withSentryReactRouterV6Routing(Routes)
    : Routes;

const Routing: React.FC<IRoutingProps> = ({ routes }) => {
  const token = useAppSelector((state) => state.auth.accessToken);
  const idToken = useIdToken();

  return (
    <Suspense fallback={<FallbackLoader withBackground />}>
      <SentryRoutes>
        {routes.map((el) => {
          return (
            <React.Fragment key={el.path}>
              {el.protected ? (
                <Route element={<ProtectedRoute isAllowed={Boolean(token)} />}>
                  <Route
                    element={
                      <ProtectedRoute
                        route={el}
                        redirectPath={RoutePaths.NOT_FOUND}
                        path={el.path}
                      />
                    }
                  >
                    <Route element={<MainLayout />}>
                      <Route
                        path={el.path}
                        element={el.element ? <el.element /> : null}
                      >
                        {el.children &&
                          (Array.isArray(el.children)
                            ? el.children
                            : el.children(idToken)
                          ).map((element) => {
                            // TODO: implement recursive route, couldnot because of route inseide route issue
                            return element.protected ? (
                              <Route
                                key={element.path}
                                element={
                                  <ProtectedRoute
                                    route={element}
                                    redirectPath={RoutePaths.NOT_FOUND}
                                    path={element.path}
                                    parentRoute={el}
                                  />
                                }
                              >
                                <Route
                                  path={element.path}
                                  element={
                                    element.element ? (
                                      <element.element {...element.props} />
                                    ) : null
                                  }
                                />
                              </Route>
                            ) : (
                              <Route
                                key={element.path}
                                path={element.path}
                                element={
                                  element.element ? (
                                    <element.element {...element.props} />
                                  ) : null
                                }
                              >
                                {element.children &&
                                  (element.children as IRoute[]).map((el) => {
                                    return (
                                      <Route
                                        key={el.path}
                                        path={el.path}
                                        element={
                                          el.element ? (
                                            <el.element {...el.props} />
                                          ) : null
                                        }
                                      />
                                    );
                                  })}
                              </Route>
                            );
                          })}
                      </Route>
                    </Route>
                  </Route>
                </Route>
              ) : (
                <Route
                  path={el.path}
                  element={el.element ? <el.element {...el.props} /> : null}
                />
              )}
            </React.Fragment>
          );
        })}
      </SentryRoutes>
    </Suspense>
  );
};

export default Routing;
