import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NotificationDuration, NotificationMode } from "./enums";
import { INotificationsInitialState, showSnackBarPayloadType } from "./types";

const initialState: INotificationsInitialState = {
  isOpen: false,
  message: "",
  duration: NotificationDuration.DEFAULT,
  mode: NotificationMode.DEFAULT,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    showSnackBar: (_, action: PayloadAction<showSnackBarPayloadType>) => {
      return {
        isOpen: true,
        ...action.payload,
      };
    },
    hideSnackbar: (state) => {
      return {
        ...state,
        isOpen: false,
      };
    },
    clearSnackbarStore: () => {
      return initialState;
    },
  },
});

export const { showSnackBar, hideSnackbar, clearSnackbarStore } =
  notificationSlice.actions;
export default notificationSlice.reducer;
