import {
  getReportingListGroupedByAssignee,
  getReportingListGroupedByClass,
  getReportingListGroupedByFunds,
  getReportingListGroupedByInvestor,
  getReportingListGroupedByStatus,
} from "api";

export enum ReportingGroupByKeys {
  INVESTOR = "Investor Profile",
  INVESTOR_CLASS = "Investor class",
  INVESTOR_STATUS = "Investor status",
  PRODUCT_FUND = "Product fund",
  ASSIGNEE = "Assignee",
}

export const groupByOptionsToFunctionsMap = {
  [ReportingGroupByKeys.INVESTOR]: getReportingListGroupedByInvestor,
  [ReportingGroupByKeys.INVESTOR_STATUS]: getReportingListGroupedByStatus,
  [ReportingGroupByKeys.INVESTOR_CLASS]: getReportingListGroupedByClass,
  [ReportingGroupByKeys.PRODUCT_FUND]: getReportingListGroupedByFunds,
  [ReportingGroupByKeys.ASSIGNEE]: getReportingListGroupedByAssignee,
};
