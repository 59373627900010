import {
  EmailTemplateCreateDocument,
  EmailTemplateDeleteDocument,
  EmailTemplateGetByIdDocument,
  EmailTemplateListDocument,
  EmailTemplateUpdateDocument,
  EmailTemplatesPaginatedDocument,
} from "api/generated";

import { RequestTypes } from "../../../enums";
import { getRequestInstance } from "../../../utils";
import {
  EmailTemplateCreateUpdateResponse,
  EmailTemplateCreateVars,
  EmailTemplateUpdateVars,
  EmailTemplatesListResponse,
  IEmailTemplateModel,
  IEmailTemplatesListVars,
  IEmailTemplatesPaginatedResponse,
  IEmailTemplatesPaginatedVars,
} from "./types";

export async function getEmailTemplatesPaginated(
  variables: IEmailTemplatesPaginatedVars
): Promise<IEmailTemplatesPaginatedResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: EmailTemplatesPaginatedDocument,
      variables,
    });
    return res?.data?.emailTemplatesPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createEmailTemplate(
  variables: EmailTemplateCreateVars
): Promise<EmailTemplateCreateUpdateResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: EmailTemplateCreateDocument,
      variables,
    });
    return res?.data?.emailTemplateCreate;
  } catch (error) {
    throw error;
  }
}

export async function updateEmailTemplate(
  variables: EmailTemplateUpdateVars
): Promise<EmailTemplateCreateUpdateResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: EmailTemplateUpdateDocument,
      variables,
    });
    return res?.data?.emailTemplateUpdate;
  } catch (error) {
    throw error;
  }
}

export async function getEmailTemplate(
  emailTemplateId: number
): Promise<IEmailTemplateModel> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: EmailTemplateGetByIdDocument,
      variables: {
        emailTemplateId,
      },
    });
    return res?.data?.emailTemplateGetById;
  } catch (error) {
    throw error;
  }
}

export async function deleteEmailTemplate(
  emailTemplateId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: EmailTemplateDeleteDocument,
      variables: {
        emailTemplateId,
      },
    });
    return Boolean(res?.data?.emailTemplateDelete);
  } catch (error) {
    throw error;
  }
}

export async function getEmailTemplates(
  variables: IEmailTemplatesListVars
): Promise<EmailTemplatesListResponse[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: EmailTemplateListDocument,
      variables,
    });
    return res?.data?.emailTemplateList ?? [];
  } catch (error) {
    throw error;
  }
}
