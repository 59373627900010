import {
  SourceExchangeCreateDocument,
  SourceExchangeDeleteDocument,
  SourceExchangeGetByIdDocument,
  SourceExchangeListAssetTypesDocument,
  SourceExchangeListDocument,
  SourceExchangesPaginatedDocument,
  SourceExchangeUpdateDocument,
} from "api/generated";
import { Optional, PartialNullable, ResourceEnum, ScopeEnum } from "config";
import {
  NotificationDuration,
  NotificationMode,
  showSnackBar,
  store,
} from "store";
import { RequestTypes } from "../../enums";
import { getRequestInstance } from "../../utils";
import {
  GetSourceExchangeNameReturnType,
  ISourceExchangeAssetTypeModel,
  ICreateSourceArgs,
  IGetSourceReturnType,
  IGetSourcesArgs,
  ISourceModel,
  IUpdateSourceArgs,
} from "./types";

export async function getSources(
  variables?: IGetSourcesArgs
): Promise<IGetSourceReturnType> {
  const makeRequest = getRequestInstance(RequestTypes.QUERY);
  const res = await makeRequest({
    query: SourceExchangesPaginatedDocument,
    variables,
  });
  return res?.data?.sourceExchangesPaginated;
}

export async function createSource(
  variables: ICreateSourceArgs
): Promise<Optional<Pick<ISourceModel, "sourceExchangeId">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: SourceExchangeCreateDocument,
      variables,
    });
    return res?.data?.sourceExchangeCreate;
  } catch (error: any) {
    store.dispatch(
      showSnackBar({
        duration: NotificationDuration.ERRORS,
        message: error.message,
        mode: NotificationMode.ERROR,
      })
    );
    throw error;
  }
}

export async function getSourceById(
  id: number
): Promise<PartialNullable<ISourceModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: SourceExchangeGetByIdDocument,
      variables: {
        sourceExchangeId: id,
      },
    });
    return res?.data?.sourceExchangeGetById;
  } catch (error) {
    throw error;
  }
}

export async function updateSource(
  variables: IUpdateSourceArgs
): Promise<Optional<Pick<ISourceModel, "sourceExchangeId">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: SourceExchangeUpdateDocument,
      variables,
    });
    return res?.data?.sourceExchangeUpdate;
  } catch (error) {
    throw error;
  }
}

export async function deleteSource(sourceExchangeId: number): Promise<void> {
  const makeRequest = getRequestInstance(RequestTypes.MUTATE);
  await makeRequest({
    mutation: SourceExchangeDeleteDocument,
    variables: {
      sourceExchangeId,
    },
  });
}

export async function getSourceExchanges(
  resource: ResourceEnum,
  scope: ScopeEnum
): Promise<GetSourceExchangeNameReturnType[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: SourceExchangeListDocument,
      variables: {
        resource,
        scope,
      },
    });
    return res?.data?.sourceExchangeList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getAssetTypes(): Promise<
  ISourceExchangeAssetTypeModel[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: SourceExchangeListAssetTypesDocument,
      fetchPolicy: "cache-first",
    });
    return res?.data?.sourceExchangeListAssetTypes ?? [];
  } catch (e) {
    throw e;
  }
}
