import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { getVentureNoteTypesList, INotesListReturnType } from "api";
import { Nullable } from "config";
import { RootState } from "store/store";

import { IVentureNoteTypeModalData } from "./types";
import { VentureNoteTypeModalMode } from "./enums";

const initialState = {
  notesTypeData: null as Nullable<INotesListReturnType["edges"]>,
  totalCount: 1,
  loading: false,
  modalData: {
    open: false,
    mode: VentureNoteTypeModalMode.VIEW,
  } as IVentureNoteTypeModalData,
};

export const fetchVentureNotesTypes = createAsyncThunk(
  "venture-note-type/fetchVentureNotesTypes",
  async (_, { getState }) => {
    const filters = (getState() as RootState).filters;
    if (!Object.values(filters).length) {
      return;
    }

    const response = await getVentureNoteTypesList(filters);
    return response;
  }
);

const ventureNoteTypeSlice = createSlice({
  name: "venture-note-type",
  initialState,
  reducers: {
    setVentureNoteTypeModalData: (
      state,
      action: PayloadAction<IVentureNoteTypeModalData>
    ) => {
      state.modalData = action.payload;
    },
    closeVentureNoteTypeModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVentureNotesTypes.fulfilled, (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          loading: false,
        };
      }
      return {
        ...state,
        notesTypeData: action.payload?.edges,
        totalCount: action.payload?.totalCount,
        loading: false,
      };
    });
    builder.addCase(fetchVentureNotesTypes.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const { setVentureNoteTypeModalData, closeVentureNoteTypeModal } =
  ventureNoteTypeSlice.actions;
export default ventureNoteTypeSlice.reducer;
