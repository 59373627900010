import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IAdvisoryDistributionPaginatedResponse,
  getAdvisoryDistributionPaginated,
} from "api";
import { Nullable } from "config";
import { store } from "store";

import { AdvisoryDistributionModalMode } from "./enums";
import { IAdvisoryDistributionModalData } from "./types";

const initialState = {
  advisoryDistributionData: null as Nullable<
    IAdvisoryDistributionPaginatedResponse["edges"]
  >,
  totalCount: 1,
  loading: false,
  modalData: {
    mode: AdvisoryDistributionModalMode.VIEW,
    open: false,
    loading: false,
  } as IAdvisoryDistributionModalData,
};

export const fetchAdvisoryDistributionList = createAsyncThunk(
  "advisory/fetchAdvisoryDistributionList",
  async () => {
    const filters = store.getState().filters;
    if (!Object.values(filters).length) {
      return;
    }
    const response = await getAdvisoryDistributionPaginated(filters);
    return response;
  }
);

const advisoryDistributionSlice = createSlice({
  name: "advisoryDistribution",
  initialState,
  reducers: {
    setAdvisoryDistributionModalData: (
      state,
      action: PayloadAction<IAdvisoryDistributionModalData>
    ) => {
      state.modalData = action.payload;
    },
    closeAdvisoryDistributionModal: (state) => {
      state.modalData = initialState.modalData;
    },
    setAdvisoryDistributionModalLoading: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.modalData.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchAdvisoryDistributionList.fulfilled,
      (state, action) => {
        if (!action.payload) return;
        return {
          ...state,
          advisoryDistributionData: action.payload
            ?.edges as IAdvisoryDistributionPaginatedResponse["edges"],
          totalCount: action.payload?.totalCount,
          loading: false,
        };
      }
    );
    builder.addCase(fetchAdvisoryDistributionList.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const {
  setAdvisoryDistributionModalData,
  closeAdvisoryDistributionModal,
  setAdvisoryDistributionModalLoading,
} = advisoryDistributionSlice.actions;
export default advisoryDistributionSlice.reducer;
