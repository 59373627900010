import { Box } from "@mui/material";
import { SpinnerColor, SpinnerSize } from "./enums";
import { StyledSpinner } from "./styles";
import { ISpinnerProps } from "./types";

const Spinner: React.FC<ISpinnerProps> = ({
  spinnerColor = SpinnerColor.GRAY,
  size = SpinnerSize.NORMAL,
}: ISpinnerProps) => {
  return (
    <StyledSpinner className="spinner" spinnerColor={spinnerColor} size={size}>
      {Array(8)
        .fill("")
        .map((_, idx) => (
          <Box key={idx} />
        ))}
    </StyledSpinner>
  );
};

export default Spinner;
