import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import {
  IAdvisoryProjectListPaginatedReturnType,
  getAdvisoryProjectListPaginated,
} from "api";
import { Nullable } from "config";
import { RootState } from "store/store";

import { AdvisoryProjectModalMode } from "./enums";
import { IAdvisoryProjectModalData } from "./types";

const initialState = {
  advisoryProjectData: null as Nullable<
    IAdvisoryProjectListPaginatedReturnType["edges"]
  >,
  totalCount: 1,
  loading: false,
  modalData: {
    open: false,
    mode: AdvisoryProjectModalMode.VIEW,
  } as IAdvisoryProjectModalData,
};

export const fetchAdvisoryProjects = createAsyncThunk(
  "advisory-project/getAdvisoryProjectListPaginated",
  async (_, { getState }) => {
    try {
      const filters = (getState() as RootState).filters;
      if (!Object.values(filters).length) {
        return;
      }

      const response = await getAdvisoryProjectListPaginated(filters);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const AdvisoryProjectSlice = createSlice({
  name: "advisory-project",
  initialState,
  reducers: {
    setAdvisoryProjectModalData: (
      state,
      action: PayloadAction<IAdvisoryProjectModalData>
    ) => {
      state.modalData = action.payload;
    },
    closeAdvisoryProjectModal: (state) => {
      state.modalData = initialState.modalData;
    },
    switchAdvisoryProjectModalMode: (state) => {
      state.modalData.mode = AdvisoryProjectModalMode.EDIT;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdvisoryProjects.fulfilled, (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          loading: false,
        };
      }
      return {
        ...state,
        advisoryProjectData: action.payload?.edges,
        totalCount: action.payload?.totalCount,
        loading: false,
      };
    });
    builder.addCase(fetchAdvisoryProjects.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(fetchAdvisoryProjects.rejected, (state) => {
      return {
        ...state,
        loading: false,
      };
    });
  },
});

export const {
  setAdvisoryProjectModalData,
  closeAdvisoryProjectModal,
  switchAdvisoryProjectModalMode,
} = AdvisoryProjectSlice.actions;
export default AdvisoryProjectSlice.reducer;
