import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getUsersList } from "api";
import { IGetUserReturnType } from "api/services/user/types";
import { Nullable, Optional } from "config";
import { store } from "store";

import { IUserModalData, UserModalModes } from "./types";

const initialState = {
  userList: null as Nullable<IGetUserReturnType["edges"]>,
  totalCount: 1,
  loading: false,
  modalData: {
    mode: UserModalModes.VIEW,
    open: false,
  } as IUserModalData,
};

export const fetchUsersList = createAsyncThunk(
  "users/fetchUsersList",
  async (isFiltersIncluded: Optional<boolean> = true) => {
    const filters = store.getState().filters;
    if (!Object.values(filters).length && isFiltersIncluded) {
      return;
    }
    const response = await getUsersList(isFiltersIncluded && filters);
    return response;
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUserModalData: (state, action: PayloadAction<IUserModalData>) => {
      state.modalData = action.payload;
    },
    closeUserModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsersList.fulfilled, (state, action) => {
      if (!action.payload) return;
      return {
        ...state,
        loading: false,
        userList: action.payload?.edges,
        totalCount: action.payload?.totalCount,
      };
    });
    builder.addCase(fetchUsersList.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const { setUserModalData, closeUserModal } = usersSlice.actions;
export default usersSlice.reducer;
