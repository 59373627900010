import {
  CapitalMovementGetByIdDocument,
  CapitalMovementFileTypesDocument,
  CapitalMovementListDocument,
  CapitalMovementCreateDocument,
  CapitalMovementDeleteDocument,
  CapitalMovementExportFileDocument,
  CapitalMovementUpdateDocument,
  GetCapitalMovementsDocument,
  CapitalMovementTransferSourceInKindCurrencyDocument,
} from "api/generated";
import { Nullable, Optional, PartialNullable, mapInvestorName } from "config";

import { RequestTypes } from "../../enums";
import { getRequestInstance } from "../../utils";
import {
  ICapitalMovementExportArgs,
  ICapitalMovementModel,
  ICapitalMovementByIdReturnType,
  ICreateCapitalMovementArgs,
  IGetCapitalMovementListArgs,
  IGetCapitalMovementListReturnType,
  IUpdateCapitalMovementArgs,
  IGetCapitalMovementVars,
  IGetCapitalMovementResponse,
  IInKindCurrencyTypeModel,
} from "./types";

export async function createCapitalMovement(
  variables: ICreateCapitalMovementArgs
): Promise<Optional<Pick<ICapitalMovementModel, "capitalMovementId">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: CapitalMovementCreateDocument,
      variables,
    });
    return res?.data?.capitalMovementCreate;
  } catch (error) {
    throw error;
  }
}

export async function getCapitalMovementList(
  variables?: IGetCapitalMovementListArgs
): Promise<IGetCapitalMovementListReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: CapitalMovementListDocument,
      variables,
    });
    return (
      res?.data?.capitalMovementPaginated && {
        ...res.data.capitalMovementPaginated,
        edges: res.data.capitalMovementPaginated.edges.map((item) => ({
          ...item,
          node: {
            ...item.node,
            investorProfile: item.node.investorProfile && {
              ...item.node.investorProfile,
              ...mapInvestorName(item.node.investorProfile),
            },
          },
        })),
      }
    );
  } catch (error) {
    throw error;
  }
}

export async function getCapitalMovementById(
  capitalMovementId: number
): Promise<Nullable<PartialNullable<ICapitalMovementByIdReturnType>>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: CapitalMovementGetByIdDocument,
      variables: {
        capitalMovementId,
      },
    });
    if (!res?.data?.capitalMovementGetById) {
      return null;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { __typename, ...restData } = res.data.capitalMovementGetById;

    return {
      ...restData,
      investorProfile: {
        ...restData.investorProfile,
        ...mapInvestorName(restData.investorProfile),
      },
    };
  } catch (error) {
    throw error;
  }
}

export async function updateCapitalMovement(
  variables: IUpdateCapitalMovementArgs
): Promise<Optional<Pick<ICapitalMovementModel, "capitalMovementId">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: CapitalMovementUpdateDocument,
      variables,
    });
    return res?.data?.capitalMovementUpdate;
  } catch (error) {
    throw error;
  }
}

export async function getCapitalMovementFileTypes(): Promise<string[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: CapitalMovementFileTypesDocument,
    });
    return res?.data?.capitalMovementExportFileType ?? [];
  } catch (error) {
    throw error;
  }
}

export async function exportCapitalMovementFile(
  variables: ICapitalMovementExportArgs
): Promise<string> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: CapitalMovementExportFileDocument,
      variables,
    });
    return res?.data?.capitalMovementExport;
  } catch (error) {
    throw error;
  }
}

export async function deleteCapitalMovement(
  capitalMovementId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: CapitalMovementDeleteDocument,
      variables: {
        capitalMovementId,
      },
    });
    return Boolean(res?.data?.capitalMovementDelete);
  } catch (error) {
    throw error;
  }
}

export async function getCapitalMovements(
  variables: IGetCapitalMovementVars
): Promise<IGetCapitalMovementResponse[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: GetCapitalMovementsDocument,
      variables,
    });
    return res?.data?.capitalMovementList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getCapitalMovementInKindCurrencies(): Promise<
  IInKindCurrencyTypeModel[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: CapitalMovementTransferSourceInKindCurrencyDocument,
    });
    return res?.data?.capitalMovementTransferSourceInKindCurrency ?? [];
  } catch (error) {
    throw error;
  }
}
