import { ICommonModalData, Nullable } from "config";

export enum ProfileModalModes {
  CREATE = "create",
  VIEW = "view",
  EDIT = "edit",
}

export interface IInvestorModalData
  extends ICommonModalData<ProfileModalModes> {
  investorProfileId?: number;
  isIntakeInterview?: boolean;
  intakeInterviewId?: Nullable<number>;
}
