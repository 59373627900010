import { ICommonModalData } from "config";

export enum DocumentModalMode {
  VIEW = "view",
  EDIT = "edit",
  CREATE = "create",
  CLONE = "clone",
}
export interface IDocumentListModalData
  extends ICommonModalData<DocumentModalMode> {
  documentListId?: number;
}
