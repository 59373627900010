import { Snackbar, styled } from "@mui/material";
import { NotificationMode } from "store";

export const StyledSnackbar = styled(Snackbar, {
  shouldForwardProp: (prop) => prop !== "mode",
})<{ mode: NotificationMode }>(({ theme, mode }) => ({
  "& .MuiPaper-root": {
    boxSizing: "border-box",
    ...(mode === NotificationMode.ERROR && {
      backgroundColor: theme.palette.custom.error_main,
    }),
    ...(mode === NotificationMode.SUCCESS && {
      backgroundColor: theme.palette.custom.light_green,
    }),
    "& .MuiSnackbarContent-message": {
      width: "100%",
      textAlign: "center",
    },
  },
}));
