import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import {
  getProjectProfilesPaginated,
  IProjectProfilePaginatedResponse,
} from "api";
import { Nullable } from "config";
import { RootState } from "store/store";

import { IProjectProfileModalData } from "./types";
import { ProjectProfileModalMode } from "./enums";

const initialState = {
  projectProfileData: null as Nullable<
    IProjectProfilePaginatedResponse["edges"]
  >,
  totalCount: 1,
  loading: false,
  modalData: {
    open: false,
    mode: ProjectProfileModalMode.VIEW,
  } as IProjectProfileModalData,
};

const actionTypeName = "project-profile-type";

export const fetchProjectProfiles = createAsyncThunk(
  `${actionTypeName}/fetchProjectProfiles`,
  async (_, { getState }) => {
    const filters = (getState() as RootState).filters;
    if (!Object.values(filters).length) {
      return;
    }

    const response = await getProjectProfilesPaginated(filters);
    return response;
  }
);

const projectProfileSlice = createSlice({
  name: actionTypeName,
  initialState,
  reducers: {
    setProjectProfileModalData: (
      state,
      action: PayloadAction<IProjectProfileModalData>
    ) => {
      state.modalData = action.payload;
    },
    closeProjectProfileModal: (state) => {
      state.modalData = initialState.modalData;
    },
    setProjectProfileModalLoading: (state, action: PayloadAction<boolean>) => {
      state.modalData.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectProfiles.fulfilled, (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          loading: false,
        };
      }
      return {
        ...state,
        projectProfileData: action.payload?.edges,
        totalCount: action.payload?.totalCount,
        loading: false,
      };
    });
    builder.addCase(fetchProjectProfiles.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const {
  setProjectProfileModalData,
  closeProjectProfileModal,
  setProjectProfileModalLoading,
} = projectProfileSlice.actions;
export default projectProfileSlice.reducer;
