import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import {
  getVentureProjectTypesPaginated,
  IVentureProjectTypePaginatedResponse,
} from "api";
import { Nullable } from "config";
import { RootState } from "store/store";

import { IVentureProjectTypeModalData } from "./types";
import { VentureProjectTypeModalModes } from "./enums";

const initialState = {
  projectTypesData: null as Nullable<
    IVentureProjectTypePaginatedResponse["edges"]
  >,
  totalCount: 1,
  loading: false,
  modalData: {
    open: false,
    mode: VentureProjectTypeModalModes.VIEW,
  } as IVentureProjectTypeModalData,
};

const actionTypeName = "venture-project-type";

export const fetchVentureProjectTypes = createAsyncThunk(
  `${actionTypeName}/fetchVentureProjectTypes`,
  async (_, { getState }) => {
    const filters = (getState() as RootState).filters;
    if (!Object.values(filters).length) {
      return;
    }

    const response = await getVentureProjectTypesPaginated(filters);
    return response;
  }
);

const ventureProjectTypeSlice = createSlice({
  name: actionTypeName,
  initialState,
  reducers: {
    setVentureProjectTypeModalData: (
      state,
      action: PayloadAction<IVentureProjectTypeModalData>
    ) => {
      state.modalData = action.payload;
    },
    closeVentureProjectTypeModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVentureProjectTypes.fulfilled, (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          loading: false,
        };
      }
      return {
        ...state,
        projectTypesData: action.payload?.edges,
        totalCount: action.payload?.totalCount,
        loading: false,
      };
    });
    builder.addCase(fetchVentureProjectTypes.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const { setVentureProjectTypeModalData, closeVentureProjectTypeModal } =
  ventureProjectTypeSlice.actions;
export default ventureProjectTypeSlice.reducer;
