export enum SpinnerColor {
  GRAY = "light_gray",
  BLUE = "dark_blue",
  BLACK = "default_dark",
}

export enum SpinnerSize {
  NORMAL = "normal",
  SMALL = "small",
}
