import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";
import {
  AdvisoryPurchaseGetByIdDocument,
  AdvisoryPurchasesPaginatedDocument,
  AdvisoryPurchaseCreateDocument,
  AdvisoryPurchaseUpdateDocument,
  AdvisoryPurchaseListPurchaseTypeDocument,
  AdvisoryPurchaseListPurchaseStatusDocument,
  AdvisoryPurchaseGetGroupedDataDocument,
} from "api/generated";

import { Nullable, Optional, ResourceEnum, ScopeEnum } from "config";

import {
  IAdvisoryPurchaseModel,
  IAdvisoryPurchaseCreateVars,
  IAdvisoryPurchasePaginatedResponse,
  IAdvisoryPurchasePaginatedVars,
  IAdvisoryPurchaseUpdateVars,
  IAdvisoryPurchaseGroupedData,
} from "./types";

export async function getAdvisoryPurchaseListPurchaseType(
  advisoryProjectId?: number
): Promise<string[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AdvisoryPurchaseListPurchaseTypeDocument,
      variables: {
        advisoryProjectId,
      },
    });
    return res?.data?.advisoryPurchaseListPurchaseType ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getAdvisoryPurchaseGroupedData(
  resource: ResourceEnum,
  scope: ScopeEnum,
  advisoryProjectId: number,
  advisoryBuyerId: number
): Promise<Nullable<IAdvisoryPurchaseGroupedData>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AdvisoryPurchaseGetGroupedDataDocument,
      variables: {
        resource,
        scope,
        advisoryProjectId,
        advisoryBuyerId,
      },
    });
    return res?.data?.advisoryPurchaseGetGroupedData;
  } catch (error) {
    throw error;
  }
}

export async function getAdvisoryPurchaseListPurchaseStatus(): Promise<
  string[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AdvisoryPurchaseListPurchaseStatusDocument,
    });
    return res?.data?.advisoryPurchaseListPurchaseStatus ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getAdvisoryPurchase(
  advisoryPurchaseId: number
): Promise<Optional<IAdvisoryPurchaseModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AdvisoryPurchaseGetByIdDocument,
      variables: {
        id: advisoryPurchaseId,
      },
    });
    return res?.data?.advisoryPurchaseGetById;
  } catch (error) {
    throw error;
  }
}

export async function getAdvisoryPurchasePaginated(
  variables: IAdvisoryPurchasePaginatedVars
): Promise<Optional<IAdvisoryPurchasePaginatedResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AdvisoryPurchasesPaginatedDocument,
      variables,
    });
    return res?.data?.advisoryPurchaseListPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createAdvisoryPurchase(
  variables: IAdvisoryPurchaseCreateVars
): Promise<Optional<{ advisoryPurchaseId: number }>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AdvisoryPurchaseCreateDocument,
      variables,
    });
    return res?.data?.advisoryPurchaseCreate;
  } catch (error) {
    throw error;
  }
}

export async function updateAdvisoryPurchase(
  variables: IAdvisoryPurchaseUpdateVars
): Promise<Optional<{ advisoryPurchaseId: number }>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AdvisoryPurchaseUpdateDocument,
      variables,
    });
    return res?.data?.advisoryPurchaseUpdate;
  } catch (error) {
    throw error;
  }
}
