import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";
import {
  FireblocksWalletGetByIdDocument,
  FireblocksWalletListPaginatedDocument,
  FireblocksWalletCreateDocument,
  FireblocksWalletUpdateDocument,
  FireblocksWalletListDocument,
} from "api/generated";

import { Optional, ResourceEnum, ScopeEnum } from "config";

import {
  IFireblocksWalletModel,
  IFireblocksWalletCreateVars,
  IFireblocksWalletPaginatedResponse,
  IFireblocksWalletPaginatedVars,
  IFireblocksWalletUpdateVars,
} from "./types";

export async function getFireblocksWalletList(
  resource: ResourceEnum,
  scope: ScopeEnum
): Promise<Pick<IFireblocksWalletModel, "fireblocksWalletId" | "name">[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: FireblocksWalletListDocument,
      variables: {
        resource,
        scope,
      },
    });
    return res?.data?.fireblocksWalletList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getFireblocksWallet(
  fireblocksWalletId: number
): Promise<Optional<IFireblocksWalletModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: FireblocksWalletGetByIdDocument,
      variables: {
        fireblocksWalletId,
      },
    });
    return res?.data?.fireblocksWalletGetById;
  } catch (error) {
    throw error;
  }
}

export async function getFireblocksWalletPaginated(
  variables: IFireblocksWalletPaginatedVars
): Promise<Optional<IFireblocksWalletPaginatedResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: FireblocksWalletListPaginatedDocument,
      variables,
    });
    return res?.data?.fireblocksWalletListPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createFireblocksWallet(
  variables: IFireblocksWalletCreateVars
): Promise<Optional<{ fireblocksWalletId: number }>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: FireblocksWalletCreateDocument,
      variables,
    });
    return res?.data?.fireblocksWalletCreate;
  } catch (error) {
    throw error;
  }
}

export async function updateFireblocksWallet(
  variables: IFireblocksWalletUpdateVars
): Promise<Optional<{ fireblocksWalletId: number }>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: FireblocksWalletUpdateDocument,
      variables,
    });
    return res?.data?.fireblocksWalletUpdate;
  } catch (error) {
    throw error;
  }
}
