import { Box, CSSObject, styled } from "@mui/material";

import { absoluteCenterStyle, LinearGradients } from "config";

import { IFallbackLoaderProps } from "./types";

export const StyledFallbackLoader = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "withBackground" &&
    prop !== "disableScreenScroll" &&
    prop !== "fullHeightOfOverflowedContainer",
})<IFallbackLoaderProps>(
  ({
    theme,
    withBackground,
    backgroundColor,
    disableScreenScroll,
    fullHeightOfOverflowedContainer,
  }): CSSObject => ({
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: withBackground ? LinearGradients.SECONDARY_LAYOUT : "unset",
    opacity: 0.9,
    ...(Boolean(fullHeightOfOverflowedContainer) && {
      height: "100vh",
    }),
    "> div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      gap: "12.5px",
      h6: {
        color: withBackground
          ? theme.palette.custom.white
          : theme.palette.custom.light_dark,
        textTransform: "uppercase",
        marginLeft: "8px",
      },
      ...absoluteCenterStyle,
    },
    ...(Boolean(backgroundColor) && {
      background: backgroundColor,
      ...(Boolean(disableScreenScroll) && {
        "div:has(&)": {
          overflow: "hidden",
        },
      }),
    }),
  })
);
