import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getVentureDocumentListPaginated, IDocumentListResponse } from "api";
import { Nullable } from "config";
import { DocumentModalMode, RootState } from "store";

import { IVentureDocumentListModalData } from "./types";

const initialState = {
  documentList: null as Nullable<IDocumentListResponse["edges"]>,
  totalCount: 1,
  loading: false,
  modalData: {
    open: false,
    mode: DocumentModalMode.VIEW,
  } as IVentureDocumentListModalData,
};

export const fetchVentureDocumentList = createAsyncThunk(
  "venture-document-list/fetchVentureDocumentList",
  async (_, { getState }) => {
    const filters = (getState() as RootState).filters;
    if (!Object.values(filters).length) {
      return;
    }
    const response = await getVentureDocumentListPaginated(filters);
    return response;
  }
);

const ventureDocumentListSlice = createSlice({
  name: "venture-document-list",
  initialState,
  reducers: {
    setVentureDocumentListModalData: (state, action) => {
      state.modalData = action.payload;
    },
    closeVentureDocumentListModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVentureDocumentList.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        documentList: action.payload?.edges ?? null,
        totalCount: action.payload?.totalCount ?? 1,
      };
    });
    builder.addCase(fetchVentureDocumentList.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const {
  setVentureDocumentListModalData,
  closeVentureDocumentListModal,
} = ventureDocumentListSlice.actions;
export default ventureDocumentListSlice.reducer;
