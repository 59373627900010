import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { getWesterosNoteTypesList, INotesListReturnType } from "api";
import { Nullable } from "config";
import { RootState } from "store/store";

import { IWesterosNoteTypeModalData } from "./types";
import { WesterosNoteTypeModalMode } from "./enums";

const initialState = {
  notesTypeData: null as Nullable<INotesListReturnType["edges"]>,
  totalCount: 1,
  loading: false,
  modalData: {
    open: false,
    mode: WesterosNoteTypeModalMode.VIEW,
  } as IWesterosNoteTypeModalData,
};

export const fetchWesterosNotesTypes = createAsyncThunk(
  "westeros-note-type/fetchWesterosNotesTypes",
  async (_, { getState }) => {
    const filters = (getState() as RootState).filters;
    if (!Object.values(filters).length) {
      return;
    }

    const response = await getWesterosNoteTypesList(filters);
    return response;
  }
);

const westerosNoteTypeSlice = createSlice({
  name: "westeros-note-type",
  initialState,
  reducers: {
    setWesterosNoteTypeModalData: (
      state,
      action: PayloadAction<IWesterosNoteTypeModalData>
    ) => {
      state.modalData = action.payload;
    },
    closeWesterosNoteTypeModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWesterosNotesTypes.fulfilled, (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          loading: false,
        };
      }
      return {
        ...state,
        notesTypeData: action.payload?.edges,
        totalCount: action.payload?.totalCount,
        loading: false,
      };
    });
    builder.addCase(fetchWesterosNotesTypes.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const { setWesterosNoteTypeModalData, closeWesterosNoteTypeModal } =
  westerosNoteTypeSlice.actions;
export default westerosNoteTypeSlice.reducer;
