import { ICommonModalData } from "config";

export enum TagsModalModes {
  CREATE = "create",
  VIEW = "view",
  EDIT = "edit",
}

export interface ITagsModalData extends ICommonModalData<TagsModalModes> {
  tagId?: number;
}
