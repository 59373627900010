import { createSlice } from "@reduxjs/toolkit";

import { STORAGE_CONSTANTS } from "config";

import { ISidebarSlideState } from "./types";

const initialState: ISidebarSlideState = {
  isSidebarExpanded: Boolean(
    JSON.parse(
      localStorage.getItem(STORAGE_CONSTANTS.SIDEBAR_EXPANDED) ?? "null"
    )
  ),
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    toggleSidebarExpanded: (state) => {
      localStorage.setItem(
        STORAGE_CONSTANTS.SIDEBAR_EXPANDED,
        JSON.stringify(!state.isSidebarExpanded)
      );
      state.isSidebarExpanded = !state.isSidebarExpanded;
    },
  },
});

export const { toggleSidebarExpanded } = sidebarSlice.actions;
export default sidebarSlice.reducer;
