import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { IResourcePaginatedResponse, getResourcesPaginated } from "api";
import { Nullable } from "config";
import { RootState } from "store";

import { ResourceModalModes } from "./enums";
import { IResourceModalData } from "./types";

const initialState = {
  resourcesData: null as Nullable<IResourcePaginatedResponse["edges"]>,
  totalCount: 1,
  loading: false,
  modalData: {
    open: false,
    mode: ResourceModalModes.VIEW,
    isLinkAgentWebpage: false,
  } as IResourceModalData,
};

const resourceName = "resources";

export const fetchResources = createAsyncThunk(
  `${resourceName}/fetchResources`,
  async (_, { getState }) => {
    const filters = (getState() as RootState).filters;
    if (!Object.values(filters).length) {
      return;
    }
    const response = await getResourcesPaginated(filters);
    return response;
  }
);

const resourceSlice = createSlice({
  name: resourceName,
  initialState,
  reducers: {
    setResourceModalData: (
      state,
      action: PayloadAction<IResourceModalData>
    ) => {
      state.modalData = action.payload;
    },
    closeResourceModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchResources.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        resourcesData: action.payload?.edges ?? null,
        totalCount: action.payload?.totalCount ?? 1,
      };
    });
    builder.addCase(fetchResources.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const { setResourceModalData, closeResourceModal } =
  resourceSlice.actions;
export default resourceSlice.reducer;
