import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";
import {
  RecurringTaskActivateDocument,
  RecurringTaskCreateDocument,
  RecurringTaskDeactivateDocument,
  RecurringTaskDeleteDocument,
  RecurringTaskGetByIdDocument,
  RecurringTaskListPaginatedDocument,
  RecurringTaskUpdateDocument,
} from "api/generated";

import { Optional } from "config/types";

import {
  IRecurringTaskModel,
  IRecurringTaskCreateVars,
  IRecurringTaskPaginatedResponse,
  IRecurringTaskPaginatedVars,
  IRecurringTaskUpdateVars,
} from "./types";

export async function getRecurringTask(
  recurringTaskId: number
): Promise<Optional<IRecurringTaskModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: RecurringTaskGetByIdDocument,
      variables: {
        id: recurringTaskId,
      },
    });
    return res?.data?.recurringTaskGetById;
  } catch (error) {
    throw error;
  }
}

export async function getRecurringTaskPaginated(
  variables: IRecurringTaskPaginatedVars
): Promise<Optional<IRecurringTaskPaginatedResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: RecurringTaskListPaginatedDocument,
      variables,
    });
    return res?.data?.recurringTaskListPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createRecurringTask(
  variables: IRecurringTaskCreateVars
): Promise<Optional<{ recurringTaskId: number }>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: RecurringTaskCreateDocument,
      variables,
    });
    return res?.data?.recurringTaskCreate;
  } catch (error) {
    throw error;
  }
}

export async function updateRecurringTask(
  variables: IRecurringTaskUpdateVars
): Promise<Optional<{ recurringTaskId: number }>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: RecurringTaskUpdateDocument,
      variables,
    });
    return res?.data?.recurringTaskUpdate;
  } catch (error) {
    throw error;
  }
}

export async function activateRecurringTask(
  recurringTaskId: number
): Promise<Optional<boolean>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: RecurringTaskActivateDocument,
      variables: {
        id: recurringTaskId,
      },
    });
    return res?.data?.recurringTaskActivate;
  } catch (error) {
    throw error;
  }
}

export async function deactivateRecurringTask(
  recurringTaskId: number
): Promise<Optional<boolean>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: RecurringTaskDeactivateDocument,
      variables: {
        id: recurringTaskId,
      },
    });
    return res?.data?.recurringTaskDeactivate;
  } catch (error) {
    throw error;
  }
}

export async function deleteRecurringTask(
  recurringTaskId: number
): Promise<Optional<boolean>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: RecurringTaskDeleteDocument,
      variables: {
        id: recurringTaskId,
      },
    });
    return res?.data?.recurringTaskDelete;
  } catch (error) {
    throw error;
  }
}
