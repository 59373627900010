import { IIdToken, Optional } from "config";
import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { useAppSelector } from "store";

const useIdToken = (): Optional<IIdToken> => {
  const idToken = useAppSelector((state) => state.auth.idToken);
  const [tokenInfo, setTokenInfo] = useState<Optional<IIdToken>>(
    idToken ? jwtDecode<IIdToken>(idToken) : undefined
  );

  useEffect(() => {
    if (idToken) {
      const decoded = jwtDecode<IIdToken>(idToken);
      setTokenInfo(decoded);
    }
  }, [idToken]);

  return tokenInfo;
};

export default useIdToken;
