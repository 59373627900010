import { ThemeProvider } from "@mui/material";
import * as Sentry from "@sentry/react";

import { CustomizedSnackbar, Routing } from "components";
import { routes, theme } from "config";

function App() {
  const content = (
    <div style={{ height: "100%", minHeight: "100vh" }}>
      <ThemeProvider theme={theme}>
        <Routing routes={routes} />
        <CustomizedSnackbar />
      </ThemeProvider>
    </div>
  );

  if (process.env.REACT_APP_SENTRY_DSN && process.env.REACT_APP_PROJECT_ENV) {
    return (
      <Sentry.ErrorBoundary showDialog={false}>{content}</Sentry.ErrorBoundary>
    );
  }

  return content;
}

export default process.env.REACT_APP_SENTRY_DSN &&
process.env.REACT_APP_PROJECT_ENV
  ? Sentry.withProfiler(App)
  : App;
