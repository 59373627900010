import {
  WesterosNoteTypeCreateDocument,
  WesterosNoteTypeDeleteDocument,
  WesterosNoteTypeGetByIdDocument,
  WesterosNoteTypeListDocument,
  WesterosNoteTypesPaginatedDocument,
  WesterosNoteTypeUpdateDocument,
} from "api/generated";
import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";
import { Optional, ResourceEnum, ScopeEnum } from "config";

import {
  ICreateNoteTypeVars,
  IGetNoteTypeByIdReturnType,
  IGetNotesListArgs,
  INoteTypeModel,
  INotesListReturnType,
  IUpdateNoteTypeVars,
} from "../types";

export async function getWesterosNoteTypesList(
  variables: IGetNotesListArgs
): Promise<INotesListReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: WesterosNoteTypesPaginatedDocument,
      variables,
    });
    return res?.data?.westerosNoteTypesPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createWesterosNoteType(
  variables: ICreateNoteTypeVars
): Promise<Optional<Pick<INoteTypeModel, "noteTypeId">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: WesterosNoteTypeCreateDocument,
      variables,
    });
    return res?.data?.westerosNoteTypeCreate;
  } catch (error) {
    throw error;
  }
}

export async function updateWesterosNoteType(
  variables: IUpdateNoteTypeVars
): Promise<Optional<Pick<INoteTypeModel, "noteTypeId">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: WesterosNoteTypeUpdateDocument,
      variables,
    });
    return res?.data?.westerosNoteTypeUpdate;
  } catch (error) {
    throw error;
  }
}

export async function deleteWesterosNoteType(
  noteTypeId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: WesterosNoteTypeDeleteDocument,
      variables: {
        noteTypeId,
      },
    });
    return Boolean(res?.data?.westerosNoteTypeDelete);
  } catch (error) {
    throw error;
  }
}

export async function getWesterosNoteType(
  noteTypeId: number
): Promise<IGetNoteTypeByIdReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: WesterosNoteTypeGetByIdDocument,
      variables: {
        noteTypeId,
      },
    });
    return res?.data?.westerosNoteTypeGetById;
  } catch (error) {
    throw error;
  }
}

export async function getWesterosNoteTypes(
  resource: ResourceEnum,
  scope: ScopeEnum
): Promise<Pick<INoteTypeModel, "noteTypeId" | "name">[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: WesterosNoteTypeListDocument,
      variables: {
        resource,
        scope,
      },
    });
    return res?.data?.westerosNoteTypeList ?? [];
  } catch (error) {
    throw error;
  }
}
