import { createSlice } from "@reduxjs/toolkit";

const initialState = {} as Record<string, any[]>;

const filtersDataSlice = createSlice({
  name: "filters-data",
  initialState,
  reducers: {
    setFiltersData: (state, action) => {
      state = action.payload;
      return state;
    },
    removeFiltersData: () => {
      return initialState;
    },
  },
});

export const { setFiltersData, removeFiltersData } = filtersDataSlice.actions;
export default filtersDataSlice.reducer;
