import {
  ApolloClient,
  ApolloLink,
  from,
  fromPromise,
  InMemoryCache,
} from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";

import {
  errorEnumFromBeToMessageMap,
  errorMessagesForRequests,
  INTERNAL_ERROR_BE_MESSAGE,
  ITokens,
  refreshToken,
} from "api";
import { STORAGE_CONSTANTS } from "config/enums";
import {
  showSnackBar,
  store,
  NotificationDuration,
  NotificationMode,
  signOutAndClearStore,
} from "store";

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: `Bearer ${JSON.parse(
        localStorage.getItem(STORAGE_CONSTANTS.ACCESS_TOKEN) ?? "null"
      )}`,
      "Apollo-Require-Preflight": "true",
    },
  });

  return forward(operation);
});

const uploadLink = createUploadLink({ uri: process.env.REACT_APP_BACKEND_URL });

const errorLink = onError(
  ({ graphQLErrors, networkError, forward, operation }) => {
    console.log(graphQLErrors, networkError, "ERRROR");
    if (graphQLErrors) {
      for (const err of graphQLErrors as any) {
        switch (err.extensions.code) {
          case "UNAUTHENTICATED": {
            const token = JSON.parse(
              localStorage.getItem(STORAGE_CONSTANTS.REFRESH_TOKEN) ?? "null"
            );
            return fromPromise(
              refreshToken(token).catch(() => {
                localStorage.clear();
                store.dispatch(
                  showSnackBar({
                    mode: NotificationMode.ERROR,
                    duration: NotificationDuration.ERRORS,
                    message: errorMessagesForRequests.expired,
                  })
                );
                setTimeout(() => {
                  store.dispatch(signOutAndClearStore());
                }, 3000);
                return;
              })
            )
              .filter((el): el is ITokens => Boolean(el))
              .flatMap(({ accessToken }) => {
                const oldHeaders = operation.getContext().headers;
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: `Bearer ${accessToken}`,
                  },
                });
                return forward(operation);
              });
          }
          case "INTERNAL_SERVER_ERROR": {
            const code = err?.extensions?.exception?.code ?? "";
            let message =
              errorEnumFromBeToMessageMap[
                code as keyof typeof errorEnumFromBeToMessageMap
              ] ?? err?.message;
            if (!message || message === INTERNAL_ERROR_BE_MESSAGE) {
              message = errorMessagesForRequests.default;
            }
            store.dispatch(
              showSnackBar({
                message: `${message} ${code ? `[code: ${code}]` : ""} `,
                duration: NotificationDuration.ERRORS,
                mode: NotificationMode.ERROR,
              })
            );
            break;
          }
          default: {
            store.dispatch(
              showSnackBar({
                message: `${err.extensions.code}: ${err.message} `,
                duration: NotificationDuration.ERRORS,
                mode: NotificationMode.ERROR,
              })
            );
            throw err;
          }
        }
      }
    }

    if (networkError) {
      store.dispatch(
        showSnackBar({
          message: errorMessagesForRequests.default,
          duration: NotificationDuration.ERRORS,
          mode: NotificationMode.ERROR,
        })
      );
    }
  }
);

export const client = new ApolloClient({
  link: from([authLink, errorLink, uploadLink as unknown as ApolloLink]),
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV === "development",
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "ignore",
      nextFetchPolicy: "cache-first",
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
  },
});
