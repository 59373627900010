import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getTaskTypeListPaginated, IFullTaskTypeModel } from "api";
import { GraphqlDataSchema, Nullable } from "config";
import { RootState } from "store";

import { TaskTypeModalModes } from "./enums";
import { ITaskTypeModalData } from "./types";

const initialState = {
  taskTypeData: null as Nullable<
    GraphqlDataSchema<IFullTaskTypeModel>["edges"]
  >,
  totalCount: 1,
  loading: false,
  modalData: {
    mode: TaskTypeModalModes.VIEW,
    open: false,
  } as ITaskTypeModalData,
};

const taskTypeName = "task-type";

export const fetchTaskTypeList = createAsyncThunk(
  `${taskTypeName}/fetchTaskTypeList`,
  async (_, { getState }) => {
    const filters = (getState() as RootState).filters;
    if (!Object.values(filters).length) {
      return;
    }
    const response = await getTaskTypeListPaginated(filters);
    return response;
  }
);

const taskTypeSlice = createSlice({
  name: taskTypeName,
  initialState,
  reducers: {
    setTaskTypeModalData: (
      state,
      action: PayloadAction<ITaskTypeModalData>
    ) => {
      state.modalData = action.payload;
    },
    closeTaskTypeModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTaskTypeList.fulfilled, (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          loading: false,
        };
      }
      return {
        ...state,
        taskTypeData: action.payload?.edges,
        totalCount: action.payload?.totalCount,
        loading: false,
      };
    });
    builder.addCase(fetchTaskTypeList.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const { setTaskTypeModalData, closeTaskTypeModal } =
  taskTypeSlice.actions;
export default taskTypeSlice.reducer;
