export const errorMessages = {
  email: "Sign in failed. Please enter a valid username",
  password: "Sign in failed. Please enter a valid password",
  emailGeneral: "Not email pattern",
  phoneNumber: "Not a phone pattern, e.g. (+XX) XXXXXXXX",
  number: "Value should be number",
  link: "Should be link to account",
  website: "Should be a link to website",
  line: "Should be username or phone number",
  username: "Should be username pattern, e.g. @username",
  discord: "Should be username pattern, e.g. user#1234",
  telegram: "Should be telegram link",
  slack: "Should be SlackId",
  numberTrunc: "Should be number",
  integer: "Should be an integer",
  environment: "Please, follow the pattern",
  country: "No such country exist",
  phoneCode: "Incorrect phone code",
  default: "Required field",
  validation: "Error occurred during validation",
};
