import {
  CreateReportTypeDocument,
  DeleteReportTypeDocument,
  ReportTypeGetByIdDocument,
  ReportTypeListDocument,
  ReportTypesPaginatedDocument,
  UpdateReportTypeDocument,
} from "api/generated";
import { Optional, PartialNullable, ResourceEnum, ScopeEnum } from "config";

import {
  GetReportTypes,
  IReportTypeCreateVars,
  IReportTypeFilters,
  IReportTypeModel,
  IReportTypeUpdateVars,
  IReportTypesPaginatedResponse,
  ReportTypeCreateUpdateResponse,
} from "./types";
import { getRequestInstance } from "../../utils";
import { RequestTypes } from "../../enums";

export async function getReportTypes(
  resource: ResourceEnum,
  scope: ScopeEnum
): Promise<GetReportTypes[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: ReportTypeListDocument,
      variables: {
        scope,
        resource,
      },
    });
    return res?.data?.reportTypeList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getReportTypesPaginated(
  variables: IReportTypeFilters
): Promise<IReportTypesPaginatedResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: ReportTypesPaginatedDocument,
      variables,
    });
    return res?.data?.reportTypesPaginated;
  } catch (error) {
    throw error;
  }
}

export async function getReportType(
  reportTypeId: number
): Promise<PartialNullable<IReportTypeModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: ReportTypeGetByIdDocument,
      variables: { reportTypeId },
    });
    return res?.data?.reportTypeGetById;
  } catch (error) {
    throw error;
  }
}

export async function createReportType(
  variables: IReportTypeCreateVars
): Promise<Optional<ReportTypeCreateUpdateResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: CreateReportTypeDocument,
      variables,
    });
    return res?.data?.reportTypeCreate;
  } catch (error) {
    throw error;
  }
}

export async function updateReportType(
  variables: IReportTypeUpdateVars
): Promise<Optional<ReportTypeCreateUpdateResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: UpdateReportTypeDocument,
      variables,
    });
    return res?.data?.reportTypeUpdate;
  } catch (error) {
    throw error;
  }
}

export async function deleteReportType(reportTypeId: number): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: DeleteReportTypeDocument,
      variables: { reportTypeId },
    });
    return Boolean(res?.data?.reportTypeDelete);
  } catch (error) {
    throw error;
  }
}
