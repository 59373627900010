import {
  LinkAgentReportAggregatedExportDocument,
  LinkAgentReportDetailedExportDocument,
  LinkAgentReportExportFileTypeDocument,
  LinkAgentReportPaginatedAggregatedDocument,
  LinkAgentReportPaginatedDetailedDocument,
} from "api/generated";

import { RequestTypes } from "../../enums";
import { getRequestInstance } from "../../utils";
import {
  ILinkAgentAggregatedReportExportVars,
  ILinkAgentAggregatedReportResponse,
  ILinkAgentAggregatedReportVars,
  ILinkAgentDetailedReportExportVars,
  ILinkAgentDetailedReportResponse,
  ILinkAgentDetailedReportVars,
} from "./types";

export async function getLinkAgentReportsAggregated(
  variables: ILinkAgentAggregatedReportVars
): Promise<ILinkAgentAggregatedReportResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: LinkAgentReportPaginatedAggregatedDocument,
      variables,
    });
    return res?.data?.linkAgentReportPaginatedAggregated;
  } catch (e) {
    throw e;
  }
}

export async function getLinkAgentReportsDetailed(
  variables: ILinkAgentDetailedReportVars
): Promise<ILinkAgentDetailedReportResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: LinkAgentReportPaginatedDetailedDocument,
      variables,
    });
    return res?.data?.linkAgentReportPaginatedDetailed;
  } catch (e) {
    throw e;
  }
}

export async function getLinkAgentReportExportFileTypes(): Promise<string[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: LinkAgentReportExportFileTypeDocument,
      fetchPolicy: "cache-first",
    });
    return res?.data?.linkAgentReportExportFileType ?? [];
  } catch (error) {
    throw error;
  }
}

export async function exportLinkAgentReportAggregatedData(
  variables: ILinkAgentAggregatedReportExportVars
): Promise<string> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: LinkAgentReportAggregatedExportDocument,
      variables,
    });
    return res?.data?.linkAgentReportAggregatedExport;
  } catch (error) {
    throw error;
  }
}

export async function exportLinkAgentReportDetailedData(
  variables: ILinkAgentDetailedReportExportVars
): Promise<string> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: LinkAgentReportDetailedExportDocument,
      variables,
    });
    return res?.data?.linkAgentReportDetailedExport;
  } catch (error) {
    throw error;
  }
}
