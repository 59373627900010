import { client, STORAGE_CONSTANTS } from "config";
import { setAuthData, store } from "store";
import { RequestTypes } from "./enums";
import { ITokens, RestOrGraph } from "./types";

export const getRequestInstance = <T extends RequestTypes>(
  type: T
): RestOrGraph<T> => {
  switch (type) {
    case RequestTypes.REST: {
      return fetch as RestOrGraph<T>;
    }
    case RequestTypes.QUERY: {
      return client.query as RestOrGraph<T>;
    }
    case RequestTypes.MUTATE: {
      return client.mutate as RestOrGraph<T>;
    }
    default: {
      return client.query as RestOrGraph<T>;
    }
  }
};

export function setTokensIntoStorage(tokens: ITokens): void {
  const { accessToken, refreshToken, idToken } = tokens;
  localStorage.setItem(
    STORAGE_CONSTANTS.ACCESS_TOKEN,
    JSON.stringify(accessToken || null)
  );
  localStorage.setItem(
    STORAGE_CONSTANTS.REFRESH_TOKEN,
    JSON.stringify(refreshToken || null)
  );
  localStorage.setItem(
    STORAGE_CONSTANTS.ID_TOKEN,
    JSON.stringify(idToken || null)
  );
  store.dispatch(setAuthData({ accessToken, refreshToken, idToken }));
}
