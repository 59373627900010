import {
  VentureSectorCreateDocument,
  VentureSectorDeleteDocument,
  VentureSectorGetByIdDocument,
  VentureSectorListDocument,
  VentureSectorUpdateDocument,
  VentureSectorsPaginatedDocument,
} from "api/generated";
import { Optional, PartialNullable } from "config";

import {} from "../../types";
import {
  IVentureSectorCreateVars,
  IVentureSectorListVars,
  IVentureSectorModel,
  IVentureSectorUpdateVars,
  IVentureSectorsPaginatedResponse,
  IVentureSectorsPaginatedVars,
  VentureSectorCreateUpdateResponse,
  VentureSectorListResponse,
} from "./types";
import { getRequestInstance } from "../../utils";
import { RequestTypes } from "../../enums";

export async function getVentureSectorList(
  variables: IVentureSectorListVars
): Promise<VentureSectorListResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureSectorListDocument,
      variables,
    });
    return res?.data?.ventureSectorList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getVentureSectorsPaginated(
  variables: IVentureSectorsPaginatedVars
): Promise<IVentureSectorsPaginatedResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureSectorsPaginatedDocument,
      variables,
    });
    return res?.data?.ventureSectorsPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createVentureSector(
  variables: IVentureSectorCreateVars
): Promise<Optional<VentureSectorCreateUpdateResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureSectorCreateDocument,
      variables,
    });
    return res?.data?.ventureSectorCreate;
  } catch (error) {
    throw error;
  }
}

export async function updateVentureSector(
  variables: IVentureSectorUpdateVars
): Promise<Optional<VentureSectorCreateUpdateResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureSectorUpdateDocument,
      variables,
    });
    return res?.data?.ventureSectorUpdate;
  } catch (error) {
    throw error;
  }
}

export async function getVentureSector(
  ventureSectorId: number
): Promise<PartialNullable<IVentureSectorModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureSectorGetByIdDocument,
      variables: {
        ventureSectorId,
      },
    });
    return res?.data?.ventureSectorGetById;
  } catch (error) {
    throw error;
  }
}

export async function deleteVentureSector(
  ventureSectorId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureSectorDeleteDocument,
      variables: {
        ventureSectorId,
      },
    });
    return Boolean(res?.data?.ventureSectorDelete);
  } catch (error) {
    throw error;
  }
}
