import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSnapshotsList } from "api";
import { ISnapshotListReturnType } from "api/services/snapshots/types";
import { Nullable } from "config";
import { RootState } from "store/store";

const initialState = {
  data: null as Nullable<ISnapshotListReturnType["edges"]>,
  totalCount: 1,
  loading: false,
};

export const fetchSnapshotsData = createAsyncThunk(
  "snapshots/fetchSnapshotsData",
  async (_, { getState }) => {
    const filters = (getState() as RootState).filters;
    if (!Object.values(filters).length) {
      return;
    }
    const response = await getSnapshotsList(filters);
    return response;
  }
);

const snapshotsSlice = createSlice({
  name: "snapshots",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSnapshotsData.fulfilled, (state, action) => {
      if (!action.payload) return;
      return {
        ...state,
        data: action.payload?.edges as ISnapshotListReturnType["edges"],
        totalCount: action.payload?.totalCount,
        loading: false,
      };
    });
    builder.addCase(fetchSnapshotsData.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export default snapshotsSlice.reducer;
