import {
  EmailDocumentDownloadDocument,
  EmailHistoriesPaginatedDocument,
  EmailHistoryGetByIdDocument,
  InvestorProfileFullNamesDocument,
  InvestorProfileSendEmailByFilterDocument,
} from "api/generated";
import { OptionalNullable, PartialNullable, mapInvestorName } from "config";

import { RequestTypes } from "../../../enums";
import { getRequestInstance } from "../../../utils";
import {
  IEmailHistory,
  IEmailHistoryPaginatedResponse,
  IEmailHistoryPaginatedVars,
  IInvestorProfileEmailsFilters,
  ISendEmailForInvestorProfiles,
  InvestorProfileFullNameResponse,
} from "./types";

export async function getInvestorProfilesFullNames(
  variables?: IInvestorProfileEmailsFilters
): Promise<InvestorProfileFullNameResponse[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestorProfileFullNamesDocument,
      variables,
    });
    return (res?.data?.investorProfileFullNames ?? []).map(mapInvestorName);
  } catch (error) {
    throw error;
  }
}

export async function sendEmailForInvestorProfiles(
  variables: ISendEmailForInvestorProfiles
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: InvestorProfileSendEmailByFilterDocument,
      variables,
    });
    return Boolean(res?.data?.investorProfileSendEmailByFilter);
  } catch (error) {
    throw error;
  }
}

export async function getEmailHistoriesPaginatedData(
  variables: IEmailHistoryPaginatedVars
): Promise<IEmailHistoryPaginatedResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: EmailHistoriesPaginatedDocument,
      variables,
    });
    return (
      res?.data?.emailHistoriesPaginated && {
        ...res.data.emailHistoriesPaginated,
        edges: res.data.emailHistoriesPaginated.edges.map((item) => ({
          node: {
            ...item.node,
            investorProfiles: item.node.investorProfiles.map(mapInvestorName),
          },
        })),
      }
    );
  } catch (error) {
    throw error;
  }
}

export async function getEmailHistory(
  emailHistoryId: number
): Promise<PartialNullable<IEmailHistory>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: EmailHistoryGetByIdDocument,
      variables: { emailHistoryId },
    });
    return (
      res?.data?.emailHistoryGetById && {
        ...res.data.emailHistoryGetById,
        investorProfiles:
          res.data.emailHistoryGetById.investorProfiles.map(mapInvestorName),
      }
    );
  } catch (error) {
    throw error;
  }
}

export async function downloadEmailDocument(
  emailDocumentId: number
): Promise<OptionalNullable<string>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: EmailDocumentDownloadDocument,
      variables: { emailDocumentId },
    });
    return res?.data?.emailDocumentDownload;
  } catch (error) {
    throw error;
  }
}
