import {
  ResourceCreateDocument,
  ResourceDeleteDocument,
  ResourceDocumentDownloadDocument,
  ResourceDocumentUploadDocument,
  ResourceGetByIdDocument,
  ResourceListDocument,
  ResourceUpdateDocument,
  ResourcesPaginatedDocument,
} from "api/generated";
import { Optional, PartialNullable } from "config";

import { RequestTypes } from "../../enums";
import { getRequestInstance } from "../../utils";
import {
  IResourceCreateVars,
  IResourceDocumentModel,
  IResourceListResponse,
  IResourceListVars,
  IResourceModel,
  IResourcePaginatedResponse,
  IResourcePaginatedVars,
  IResourceUpdateVars,
  ResourceCreateUpdateResponseType,
} from "./types";
import { IFile } from "../../types";

export async function getResourcesPaginated(
  variables: IResourcePaginatedVars
): Promise<IResourcePaginatedResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: ResourcesPaginatedDocument,
      variables,
    });
    return res?.data?.resourcesPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createResource(
  variables: IResourceCreateVars
): Promise<ResourceCreateUpdateResponseType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: ResourceCreateDocument,
      variables,
    });
    return res?.data?.resourceCreate;
  } catch (error) {
    throw error;
  }
}

export async function updateResource(
  variables: IResourceUpdateVars
): Promise<ResourceCreateUpdateResponseType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: ResourceUpdateDocument,
      variables,
    });
    return res?.data?.resourceUpdate;
  } catch (error) {
    throw error;
  }
}

export async function getResource(
  resourceId: number
): Promise<PartialNullable<IResourceModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: ResourceGetByIdDocument,
      variables: {
        resourceId,
      },
    });
    return res?.data?.resourceGetById;
  } catch (error) {
    throw error;
  }
}

export async function deleteResource(resourceId: number): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: ResourceDeleteDocument,
      variables: {
        resourceId,
      },
    });
    return Boolean(res?.data?.resourceDelete);
  } catch (error) {
    throw error;
  }
}

export async function getResources(
  variables: IResourceListVars
): Promise<IResourceListResponse[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: ResourceListDocument,
      variables,
    });
    return res?.data?.resourceList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function uploadResourceDocument(
  variables: IFile
): Promise<Optional<IResourceDocumentModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: ResourceDocumentUploadDocument,
      variables,
    });
    return res?.data?.resourceDocumentUpload;
  } catch (error) {
    throw error;
  }
}

export async function downloadResourceDocument(
  resourceDocumentId: number
): Promise<Optional<string>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: ResourceDocumentDownloadDocument,
      variables: {
        resourceDocumentId,
      },
    });
    return res?.data?.resourceDocumentDownload;
  } catch (error) {
    throw error;
  }
}
