import {
  DocumentListPaginatedDocument,
  DocumentListCreateDocument,
  DocumentListGetByIdDocument,
  DocumentListDeleteDocument,
  DocumentListUpdateDocument,
  DocumentListListDocument,
} from "api/generated";
import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";
import { Optional } from "config";
import {
  NotificationDuration,
  NotificationMode,
  showSnackBar,
  store,
} from "store";

import {
  DocumentListByIdResponse,
  IDocumentListArgs,
  IDocumentListModel,
  IDocumentListResponse,
  IDocumentModel,
} from "../types";
import {
  ICreateInvestmentDocumentListArgs,
  IInvestmentDocumentListUpdateArgs,
} from "./types";

export async function getDocumentsList(
  variables?: IDocumentListArgs
): Promise<IDocumentListResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: DocumentListPaginatedDocument,
      variables,
    });
    return res?.data?.documentListPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createDocumentList(
  variables: ICreateInvestmentDocumentListArgs
): Promise<Optional<Pick<IDocumentListModel, "documentListId">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: DocumentListCreateDocument,
      variables,
    });
    return res?.data?.documentListCreate;
  } catch (error: any) {
    store.dispatch(
      showSnackBar({
        duration: NotificationDuration.ERRORS,
        message: error.message,
        mode: NotificationMode.ERROR,
      })
    );
    throw error;
  }
}

export async function getDocumentsListById(
  documentListId: number
): Promise<DocumentListByIdResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: DocumentListGetByIdDocument,
      variables: {
        documentListId,
      },
    });
    return res?.data?.documentListGetById;
  } catch (error) {
    throw error;
  }
}

export async function deleteDocumentList(
  documentListId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: DocumentListDeleteDocument,
      variables: {
        documentListId,
      },
    });
    return Boolean(res?.data?.documentListDelete);
  } catch (error) {
    throw error;
  }
}

export async function updateDocumentList(
  variables: IInvestmentDocumentListUpdateArgs
): Promise<Optional<Pick<IDocumentListModel, "documentListId">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: DocumentListUpdateDocument,
      variables,
    });
    return res?.data?.documentListUpdate;
  } catch (error) {
    throw error;
  }
}

export async function getDocumentListsNames(): Promise<
  Array<
    Pick<IDocumentListModel, "name" | "documentListId"> & {
      documents: Pick<IDocumentModel, "documentId" | "name">[];
    }
  >
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: DocumentListListDocument,
    });
    return res?.data?.documentListList ?? [];
  } catch (error) {
    throw error;
  }
}
