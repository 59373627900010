import {
  VentureContactTypeCreateDocument,
  VentureContactTypeDeleteDocument,
  VentureContactTypeGetByIdDocument,
  VentureContactTypeListDocument,
  VentureContactTypeUpdateDocument,
  VentureContactTypesPaginatedDocument,
} from "api/generated";
import { PartialNullable } from "config";

import { RequestTypes } from "../../enums";
import { getRequestInstance } from "../../utils";
import {
  IVentureContactTypeCreateVars,
  IVentureContactTypeModel,
  IVentureContactTypePaginatedResponse,
  IVentureContactTypeUpdateVars,
  IVentureContactTypesListVars,
  IVentureContactTypesPaginatedVars,
  VentureContactTypeCreateUpdateResponse,
  VentureContactTypesListResponse,
} from "./types";

export async function getVentureContactTypesPaginated(
  variables: IVentureContactTypesPaginatedVars
): Promise<IVentureContactTypePaginatedResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureContactTypesPaginatedDocument,
      variables,
    });
    return res?.data?.ventureContactTypesPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createVentureContactType(
  variables: IVentureContactTypeCreateVars
): Promise<VentureContactTypeCreateUpdateResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureContactTypeCreateDocument,
      variables,
    });
    return res?.data?.ventureContactTypeCreate;
  } catch (error) {
    throw error;
  }
}

export async function updateVentureContactType(
  variables: IVentureContactTypeUpdateVars
): Promise<VentureContactTypeCreateUpdateResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureContactTypeUpdateDocument,
      variables,
    });
    return res?.data?.ventureContactTypeUpdate;
  } catch (error) {
    throw error;
  }
}

export async function getVentureContactType(
  ventureContactTypeId: number
): Promise<PartialNullable<IVentureContactTypeModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureContactTypeGetByIdDocument,
      variables: {
        ventureContactTypeId,
      },
    });
    return res?.data?.ventureContactTypeGetById;
  } catch (error) {
    throw error;
  }
}

export async function deleteVentureContactType(
  ventureContactTypeId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureContactTypeDeleteDocument,
      variables: {
        ventureContactTypeId,
      },
    });
    return Boolean(res?.data?.ventureContactTypeDelete);
  } catch (error) {
    throw error;
  }
}

export async function getVentureContactTypes(
  variables: IVentureContactTypesListVars
): Promise<VentureContactTypesListResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureContactTypeListDocument,
      variables,
    });
    return res?.data?.ventureContactTypeList;
  } catch (error) {
    throw error;
  }
}
