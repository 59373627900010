import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  getVentureValuationControlsPaginated,
  IVentureValuationControlPaginatedResponse,
} from "api";
import { Nullable } from "config";
import { RootState } from "store";

import { ValuationControlModalModes } from "./enums";
import { IValuationControlModalData } from "./types";

const initialState = {
  valuationControlData: null as Nullable<
    IVentureValuationControlPaginatedResponse["edges"]
  >,
  totalCount: 1,
  loading: false,
  modalData: {
    mode: ValuationControlModalModes.VIEW,
    open: false,
  } as IValuationControlModalData,
};

const valuationControlName = "venture-valuation-control";

export const fetchValuationControlList = createAsyncThunk(
  `${valuationControlName}/fetchValuationControlList`,
  async (_, { getState }) => {
    const filters = (getState() as RootState).filters;
    if (!Object.values(filters).length) {
      return;
    }
    const response = await getVentureValuationControlsPaginated(filters);
    return response;
  }
);

const valuationControlSlice = createSlice({
  name: valuationControlName,
  initialState,
  reducers: {
    setValuationControlModalData: (
      state,
      action: PayloadAction<IValuationControlModalData>
    ) => {
      state.modalData = action.payload;
    },
    closeValuationControlModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchValuationControlList.fulfilled, (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          loading: false,
        };
      }
      return {
        ...state,
        valuationControlData: action.payload?.edges,
        totalCount: action.payload?.totalCount,
        loading: false,
      };
    });
    builder.addCase(fetchValuationControlList.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const { setValuationControlModalData, closeValuationControlModal } =
  valuationControlSlice.actions;
export default valuationControlSlice.reducer;
