import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { Nullable } from "config";
import { RootState } from "store";

import { StatementTypeModalModes } from "./enums";
import { IStatementTypeModalData } from "./types";
import {
  IStatementTypesPaginatedResponse,
  getStatementTypesPaginated,
} from "api";

const initialState = {
  statementTypesData: null as Nullable<
    IStatementTypesPaginatedResponse["edges"]
  >,
  totalCount: 1,
  loading: false,
  modalData: {
    open: false,
    mode: StatementTypeModalModes.VIEW,
  } as IStatementTypeModalData,
};

export const fetchStatementTypes = createAsyncThunk(
  "statement-type/fetchStatementTypes",
  async (_, { getState }) => {
    const filters = (getState() as RootState).filters;
    if (!Object.values(filters).length) {
      return;
    }
    const response = await getStatementTypesPaginated(filters);
    return response;
  }
);

const statementTypeSlice = createSlice({
  name: "statement-type",
  initialState,
  reducers: {
    setStatementTypeModalData: (
      state,
      action: PayloadAction<IStatementTypeModalData>
    ) => {
      state.modalData = action.payload;
    },
    closeStatementTypeModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchStatementTypes.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        statementTypesData: action.payload?.edges ?? null,
        totalCount: action.payload?.totalCount ?? 1,
      };
    });
    builder.addCase(fetchStatementTypes.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const { setStatementTypeModalData, closeStatementTypeModal } =
  statementTypeSlice.actions;
export default statementTypeSlice.reducer;
