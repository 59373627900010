import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: {
  sorting?: any;
  paging?: any;
  filters?: any;
  searchingQuery?: string;
} = {};

const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setFilters: (_, action: PayloadAction<typeof initialState>) => {
      const payload = action.payload;
      return {
        ...payload,
        ...(payload.filters && {
          filters: Object.entries(payload.filters).map(([key, value]) => ({
            [key]: value,
          })),
        }),
      };
    },
    removeSortFilter: (state) => {
      delete state.sorting;
      return state;
    },
  },
});

export const { setFilters, removeSortFilter } = filtersSlice.actions;
export default filtersSlice.reducer;
