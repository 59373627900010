import {
  ClientDocumentDownloadDocument,
  ClientDocumentUploadDocument,
  ClientNoteCreateDocument,
  ClientNoteDeleteDocument,
  ClientNotesDocumentDeleteDocument,
  ClientNotesDocumentDownloadDocument,
  ClientNotesDocumentUploadDocument,
  ClientNoteUpdateDocument,
  ClientNoteVerifyDocument,
  ClientProfileAddTagDocument,
  ClientProfileCreateDocument,
  ClientProfileDeleteDocument,
  ClientProfileExportDocument,
  ClientProfileExportFileTypeDocument,
  ClientProfileGetByIdDocument,
  ClientProfileListDocument,
  ClientProfileListLiquidSuppliesDocument,
  ClientProfileRemoveTagDocument,
  ClientProfileSendEmailDocument,
  ClientProfilesPaginatedDocument,
  ClientProfileUpdateDocument,
  ClientProfileUploadAttachmentDocument,
  TokenListDocument,
} from "api/generated";
import { Optional, PartialNullable, ResourceEnum, ScopeEnum } from "config";

import { RequestTypes } from "../../enums";
import { getRequestInstance } from "../../utils";
import {
  IClientEmailVars,
  IClientNotesCreateReturnType,
  IClientNotesModel,
  ICreateWesterosClientNotesForNotes,
  ICreateWesterosClientVars,
  IExportClientProfilesVars,
  ILiquidSupply,
  ITokensModel,
  IUpdateClientNoteResponse,
  IUpdateWesterosClientNotesForNotes,
  IUpdateWesterosClientVars,
  IUploadClientAttachmentReturnType,
  IUploadClientDocumentReturnType,
  IUploadClientNoteDocumentReturnType,
  IUploadClientVars,
  IWesterosClientProfileModel,
  IWesterosClientProfilesListReturnType,
  IWesterosClientProfilesListVars,
  IWesterosClientTagVars,
} from "./types";
import { IFile } from "../../types";

export async function getWesterosClientProfilesList(
  variables: IWesterosClientProfilesListVars
): Promise<IWesterosClientProfilesListReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: ClientProfilesPaginatedDocument,
      variables,
    });
    if (res?.errors) throw res?.errors;
    return res?.data?.clientProfilesPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createWesterosClientProfile(
  variables: ICreateWesterosClientVars
): Promise<Optional<Pick<IWesterosClientProfileModel, "clientProfileId">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: ClientProfileCreateDocument,
      variables,
    });
    return res?.data?.clientProfileCreate;
  } catch (error) {
    throw error;
  }
}

export async function updateWesterosClientProfile(
  variables: IUpdateWesterosClientVars
): Promise<Optional<Pick<IWesterosClientProfileModel, "clientProfileId">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: ClientProfileUpdateDocument,
      variables,
    });
    return res?.data?.clientProfileUpdate;
  } catch (error) {
    throw error;
  }
}

export async function getWesterosClientProfile(
  clientProfileId: number
): Promise<PartialNullable<IWesterosClientProfileModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: ClientProfileGetByIdDocument,
      variables: {
        clientProfileId,
      },
    });
    return res?.data?.clientProfileGetById;
  } catch (error) {
    throw error;
  }
}

export async function uploadWesterosClientDocument(
  variables: IUploadClientVars
): Promise<Optional<IUploadClientDocumentReturnType>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: ClientDocumentUploadDocument,
      variables,
    });
    return res?.data?.clientDocumentUpload;
  } catch (error) {
    throw error;
  }
}

export async function downloadWesterosClientDocument(
  clientDocumentId: number
): Promise<Optional<string>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: ClientDocumentDownloadDocument,
      variables: {
        clientDocumentId,
      },
    });
    return res?.data?.clientDocumentDownload;
  } catch (error) {
    throw error;
  }
}

export async function getWesterosClientProfiles(
  scope: ScopeEnum,
  resource: ResourceEnum
): Promise<
  Pick<
    IWesterosClientProfileModel,
    "clientProfileId" | "firstName" | "lastName"
  >[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: ClientProfileListDocument,
      variables: {
        scope,
        resource,
      },
    });
    return res?.data?.clientProfileList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function addWesterosClientProfileTag(
  variables: IWesterosClientTagVars
): Promise<Optional<Pick<IWesterosClientProfileModel, "clientProfileId">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: ClientProfileAddTagDocument,
      variables,
    });
    return res?.data?.clientProfileAddTag;
  } catch (error) {
    throw error;
  }
}

export async function removeWesterosClientProfileTag(
  variables: IWesterosClientTagVars
): Promise<Optional<Pick<IWesterosClientProfileModel, "clientProfileId">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: ClientProfileRemoveTagDocument,
      variables,
    });
    return res?.data?.clientProfileRemoveTag;
  } catch (error) {
    throw error;
  }
}

export async function createWesterosClientNote(
  variables: ICreateWesterosClientNotesForNotes
): Promise<Optional<PartialNullable<IClientNotesCreateReturnType>>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: ClientNoteCreateDocument,
      variables,
    });
    return res?.data?.clientNoteCreate;
  } catch (error) {
    throw error;
  }
}

export async function updateWesterosClientNote(
  variables: IUpdateWesterosClientNotesForNotes
): Promise<Optional<IUpdateClientNoteResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: ClientNoteUpdateDocument,
      variables,
    });
    return res?.data?.clientNoteUpdate;
  } catch (error) {
    throw error;
  }
}

export async function deleteWesterosClientNote(
  clientNoteId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: ClientNoteDeleteDocument,
      variables: {
        clientNoteId,
      },
    });
    return Boolean(res?.data?.clientNoteDelete);
  } catch (error) {
    throw error;
  }
}

export async function verifyWesterosClientNote(
  clientNoteId: number
): Promise<Optional<Pick<IClientNotesModel, "isLocked">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: ClientNoteVerifyDocument,
      variables: {
        clientNoteId,
      },
    });
    return res?.data?.clientNoteVerify;
  } catch (error) {
    throw error;
  }
}

export async function uploadClientNoteDocument(
  variables: IUploadClientVars
): Promise<Optional<PartialNullable<IUploadClientNoteDocumentReturnType>>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: ClientNotesDocumentUploadDocument,
      variables,
    });
    return res?.data?.clientNotesDocumentUpload;
  } catch (error) {
    throw error;
  }
}

export async function downloadClientNoteDocument(
  clientDocumentId: number
): Promise<Optional<string>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: ClientNotesDocumentDownloadDocument,
      variables: { clientDocumentId },
    });
    return res?.data?.clientNotesDocumentDownload;
  } catch (error) {
    throw error;
  }
}

export async function deleteClientNoteDocument(
  clientDocumentId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: ClientNotesDocumentDeleteDocument,
      variables: { clientDocumentId },
    });
    return Boolean(res?.data?.clientNotesDocumentDelete);
  } catch (error) {
    throw error;
  }
}

export async function getClientExportTypes(): Promise<string[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: ClientProfileExportFileTypeDocument,
    });
    return res?.data?.clientProfileExportFileType ?? [];
  } catch (error) {
    throw error;
  }
}

export async function exportClientProfiles(
  variables: IExportClientProfilesVars
): Promise<string> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: ClientProfileExportDocument,
      variables,
    });
    return res?.data?.clientProfileExport;
  } catch (error) {
    throw error;
  }
}

export async function sendClientEmail(
  variables: IClientEmailVars
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: ClientProfileSendEmailDocument,
      variables,
    });
    return Boolean(res?.data?.clientProfileSendEmail);
  } catch (error) {
    throw error;
  }
}

export async function uploadClientEmailAttachment(
  variables: IFile
): Promise<Optional<IUploadClientAttachmentReturnType>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: ClientProfileUploadAttachmentDocument,
      variables,
    });
    return res?.data?.clientProfileUploadAttachment;
  } catch (error) {
    throw error;
  }
}

export async function getTokens(): Promise<ITokensModel[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: TokenListDocument,
    });
    return res?.data?.tokenList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function deleteClientProfile(
  clientProfileId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: ClientProfileDeleteDocument,
      variables: { clientProfileId },
    });
    return Boolean(res?.data?.clientProfileDelete);
  } catch (error) {
    throw error;
  }
}

export async function getLiquidSuppliesForClientProfile(): Promise<
  ILiquidSupply[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: ClientProfileListLiquidSuppliesDocument,
    });
    return res?.data?.clientProfileListLiquidSupplies ?? [];
  } catch (e) {
    throw e;
  }
}
