import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";
import {
  WesterosDocumentListCreateDocument,
  WesterosDocumentListDeleteDocument,
  WesterosDocumentListGetByIdDocument,
  WesterosDocumentListPaginatedDocument,
  WesterosDocumentListListDocument,
  WesterosDocumentListUpdateDocument,
} from "api/generated";
import { Optional } from "config";

import {
  DocumentListByIdResponse,
  ICreateDocumentListArgs,
  IDocumentListArgs,
  IDocumentListModel,
  IDocumentListResponse,
  IDocumentListUpdateArgs,
  IDocumentModel,
} from "../types";

export async function getWesterosDocumentListPaginated(
  variables: IDocumentListArgs
): Promise<IDocumentListResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: WesterosDocumentListPaginatedDocument,
      variables,
    });
    return res?.data?.westerosDocumentListPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createWesterosDocumentList(
  variables: ICreateDocumentListArgs
): Promise<Optional<Pick<IDocumentListModel, "documentListId">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: WesterosDocumentListCreateDocument,
      variables,
    });
    return res?.data?.westerosDocumentListCreate;
  } catch (error) {
    throw error;
  }
}

export async function getWesterosDocumentList(
  documentListId: number
): Promise<DocumentListByIdResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: WesterosDocumentListGetByIdDocument,
      variables: {
        documentListId,
      },
    });
    return res?.data?.westerosDocumentListGetById;
  } catch (error) {
    throw error;
  }
}

export async function updateWesterosDocumentList(
  variables: IDocumentListUpdateArgs
): Promise<Optional<Pick<IDocumentListModel, "documentListId">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: WesterosDocumentListUpdateDocument,
      variables,
    });
    return res?.data?.westerosDocumentListUpdate;
  } catch (error) {
    throw error;
  }
}

export async function deleteWesterosDocumentList(
  documentListId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: WesterosDocumentListDeleteDocument,
      variables: {
        documentListId,
      },
    });
    return Boolean(res?.data?.westerosDocumentListDelete);
  } catch (error) {
    throw error;
  }
}

export async function getWesterosDocumentLists(): Promise<
  (Pick<IDocumentListModel, "name" | "documentListId"> & {
    documents: Pick<IDocumentModel, "documentId" | "name">[];
  })[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: WesterosDocumentListListDocument,
    });
    return res?.data?.westerosDocumentListList ?? [];
  } catch (error) {
    throw error;
  }
}
