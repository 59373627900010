import {
  CreateStatementDocument,
  DeleteStatementDocument,
  DownloadStatementDocumentDocument,
  StatementDocument,
  StatementsOwnPaginatedDocument,
  StatementsPaginatedDocument,
  UpdateStatementDocument,
  UploadStatementDocumentDocument,
} from "api/generated";
import { Optional, PartialNullable, mapInvestorName } from "config";

import { RequestTypes } from "../../enums";
import { getRequestInstance } from "../../utils";
import {
  ICreateStatementVars,
  IStatementDocumentModel,
  IStatementsModel,
  IStatementsPaginatedResponse,
  IStatementsPaginatedVars,
  IUpdateStatementVars,
  StatementCreateUpdateResponse,
} from "./types";
import { IFile } from "../../types";

export async function getStatementsPaginated(
  variables: IStatementsPaginatedVars
): Promise<IStatementsPaginatedResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: StatementsPaginatedDocument,
      variables,
    });

    return (
      res?.data?.statementsPaginated && {
        ...res.data.statementsPaginated,
        edges: res.data.statementsPaginated.edges.map((item) => ({
          ...item,
          node: {
            ...item.node,
            investorProfile:
              item.node.investorProfile &&
              mapInvestorName(item.node.investorProfile),
          },
        })),
      }
    );
  } catch (error) {
    throw error;
  }
}

export async function getStatement(
  statementId: number
): Promise<Optional<PartialNullable<IStatementsModel>>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: StatementDocument,
      variables: { statementId },
    });
    return res?.data?.statementGetById;
  } catch (error) {
    throw error;
  }
}

export async function createStatement(
  variables: ICreateStatementVars
): Promise<Optional<StatementCreateUpdateResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: CreateStatementDocument,
      variables,
    });
    return res?.data?.statementCreate;
  } catch (error) {
    throw error;
  }
}

export async function updateStatement(
  variables: IUpdateStatementVars
): Promise<Optional<StatementCreateUpdateResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: UpdateStatementDocument,
      variables,
    });
    return res?.data?.statementUpdate;
  } catch (error) {
    throw error;
  }
}

export async function deleteStatement(statementId: number): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: DeleteStatementDocument,
      variables: { statementId },
    });
    return Boolean(res?.data?.statementDelete);
  } catch (error) {
    throw error;
  }
}

export async function uploadStatementDocument(
  variables: IFile
): Promise<Optional<IStatementDocumentModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: UploadStatementDocumentDocument,
      variables,
    });
    return res?.data?.statementDocumentUpload;
  } catch (error) {
    throw error;
  }
}

export async function downloadStatementDocument(
  statementDocumentId: number
): Promise<Optional<string>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: DownloadStatementDocumentDocument,
      variables: { statementDocumentId },
    });
    return res?.data?.statementDocumentDownload;
  } catch (error) {
    throw error;
  }
}

export async function getStatementsPaginatedForInvestor(
  variables: IStatementsPaginatedVars
): Promise<IStatementsPaginatedResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: StatementsOwnPaginatedDocument,
      variables,
    });
    return res?.data?.statementsOwnPaginated;
  } catch (error) {
    throw error;
  }
}
