import {
  TaskTypeCreateDocument,
  TaskTypeDeleteDocument,
  TaskTypeGetByIdDocument,
  TaskTypeListByEntityTypeDocument,
  TaskTypeListDocument,
  TaskTypeListPaginatedDocument,
  TaskTypeUpdateDocument,
} from "api/generated";
import { Optional, ResourceEnum, ScopeEnum } from "config";

import { RequestTypes } from "../../../enums";
import { getRequestInstance } from "../../../utils";
import {
  ITaskTypeCreateUpdateResponse,
  ITaskTypeCreateVars,
  ITaskTypeGetByIdResponse,
  ITaskTypeListResponse,
  ITaskTypePaginatedResponse,
  ITaskTypePaginatedVars,
  ITaskTypeUpdateVars,
} from "./types";

export async function getTaskTypeListPaginated(
  variables: ITaskTypePaginatedVars
): Promise<Optional<ITaskTypePaginatedResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: TaskTypeListPaginatedDocument,
      variables,
    });
    return res?.data?.taskTypeListPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createTaskType(
  variables: ITaskTypeCreateVars
): Promise<Optional<ITaskTypeCreateUpdateResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: TaskTypeCreateDocument,
      variables,
    });
    return res?.data?.taskTypeCreate;
  } catch (error) {
    throw error;
  }
}

export async function updateTaskType(
  variables: ITaskTypeUpdateVars
): Promise<Optional<ITaskTypeCreateUpdateResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: TaskTypeUpdateDocument,
      variables,
    });
    return res?.data?.taskTypeUpdate;
  } catch (error) {
    throw error;
  }
}

export async function getTaskType(
  taskTypeId: number
): Promise<Optional<ITaskTypeGetByIdResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: TaskTypeGetByIdDocument,
      variables: {
        taskTypeId,
      },
    });
    return res?.data?.taskTypeGetById;
  } catch (error) {
    throw error;
  }
}

export async function deleteTaskType(taskTypeId: number): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: TaskTypeDeleteDocument,
      variables: {
        taskTypeId,
      },
    });
    return Boolean(res?.data?.taskTypeDelete);
  } catch (error) {
    throw error;
  }
}

export async function getTaskTypeList(
  resource: ResourceEnum,
  scope: ScopeEnum
): Promise<ITaskTypeListResponse[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: TaskTypeListDocument,
      variables: {
        resource,
        scope,
      },
    });
    return res?.data?.taskTypeList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getTaskTypeListByEntityType(
  resource: ResourceEnum,
  scope: ScopeEnum,
  entityTypeName?: string
): Promise<ITaskTypeListResponse[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: TaskTypeListByEntityTypeDocument,
      variables: {
        resource,
        scope,
        entityTypeName,
      },
    });
    return res?.data?.taskTypeListByEntityType ?? [];
  } catch (error) {
    throw error;
  }
}
