import { ErrorCodes } from "api/services";

export const errorMessagesForRequests = {
  signInUnauthorized: "Wrong email or password",
  signInWrongCode: "Wrong code",
  default: "Something went wrong",
  defaultWithContactInfo:
    "Something went wrong, please contact with administrator",
  longData: "Too long data",
  expired: "Session expired!",
};

export const errorMessagesFromBE = {
  unauthorized: "Unauthorized error.",
};

export const errorEnumFromBeToMessageMap = {
  [ErrorCodes.NOTE_NOT_FOUND]: "This note is already deleted",
};

export const INTERNAL_ERROR_BE_MESSAGE = "Internal error.";
