import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  IEmailHistoryPaginatedResponse,
  getEmailHistoriesPaginatedData,
} from "api";
import { Nullable } from "config";
import { RootState } from "store";

const initialState = {
  emailHistoryData: null as Nullable<IEmailHistoryPaginatedResponse["edges"]>,
  totalCount: 1,
  loading: false,
};

export const fetchEmailHistory = createAsyncThunk(
  "email-system/fetchEmailHistory",
  async (_, { getState }) => {
    const filters = (getState() as RootState).filters;
    if (!Object.values(filters).length) {
      return;
    }
    const response = await getEmailHistoriesPaginatedData(filters);
    return response;
  }
);

const emailSystemSlice = createSlice({
  name: "email-system",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEmailHistory.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        emailHistoryData: action.payload?.edges ?? null,
        totalCount: action.payload?.totalCount ?? 1,
      };
    });
    builder.addCase(fetchEmailHistory.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export default emailSystemSlice.reducer;
