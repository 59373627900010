import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";
import {
  VentureDocumentListCreateDocument,
  VentureDocumentListDeleteDocument,
  VentureDocumentListGetByIdDocument,
  VentureDocumentListPaginatedDocument,
  VentureDocumentListListDocument,
  VentureDocumentListUpdateDocument,
} from "api/generated";
import { Optional } from "config";

import {
  DocumentListByIdResponse,
  ICreateDocumentListArgs,
  IDocumentListArgs,
  IDocumentListModel,
  IDocumentListResponse,
  IDocumentListUpdateArgs,
  IDocumentModel,
} from "../types";

export async function getVentureDocumentListPaginated(
  variables: IDocumentListArgs
): Promise<IDocumentListResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureDocumentListPaginatedDocument,
      variables,
    });
    return res?.data?.ventureDocumentListPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createVentureDocumentList(
  variables: ICreateDocumentListArgs
): Promise<Optional<Pick<IDocumentListModel, "documentListId">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureDocumentListCreateDocument,
      variables,
    });
    return res?.data?.ventureDocumentListCreate;
  } catch (error) {
    throw error;
  }
}

export async function getVentureDocumentList(
  documentListId: number
): Promise<DocumentListByIdResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureDocumentListGetByIdDocument,
      variables: {
        documentListId,
      },
    });
    return res?.data?.ventureDocumentListGetById;
  } catch (error) {
    throw error;
  }
}

export async function updateVentureDocumentList(
  variables: IDocumentListUpdateArgs
): Promise<Optional<Pick<IDocumentListModel, "documentListId">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureDocumentListUpdateDocument,
      variables,
    });
    return res?.data?.ventureDocumentListUpdate;
  } catch (error) {
    throw error;
  }
}

export async function deleteVentureDocumentList(
  documentListId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureDocumentListDeleteDocument,
      variables: {
        documentListId,
      },
    });
    return Boolean(res?.data?.ventureDocumentListDelete);
  } catch (error) {
    throw error;
  }
}

export async function getVentureDocumentLists(): Promise<
  (Pick<IDocumentListModel, "name" | "documentListId"> & {
    documents: Pick<IDocumentModel, "documentId" | "name">[];
  })[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureDocumentListListDocument,
    });
    return res?.data?.ventureDocumentListList ?? [];
  } catch (error) {
    throw error;
  }
}
