import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getWesterosTagsList, ITagModel } from "api";
import { GraphqlDataSchema, Nullable } from "config";
import { RootState } from "store";

import { WesterosTagsModalModes } from "./enums";
import { IWesterosTagsModalData } from "./types";

const initialState = {
  tagsData: null as Nullable<GraphqlDataSchema<ITagModel>["edges"]>,
  totalCount: 1,
  loading: false,
  modalData: {
    mode: WesterosTagsModalModes.VIEW,
    open: false,
  } as IWesterosTagsModalData,
};

export const fetchWesterosTags = createAsyncThunk(
  "westeros-tags/fetchWesterosTags",
  async (_, { getState }) => {
    const filters = (getState() as RootState).filters;
    if (!Object.values(filters).length) {
      return;
    }
    const response = await getWesterosTagsList(filters);
    return response;
  }
);

const westerosTagsSlice = createSlice({
  name: "westeros-tags",
  initialState,
  reducers: {
    setWesterosTagsModalData: (
      state,
      action: PayloadAction<IWesterosTagsModalData>
    ) => {
      state.modalData = action.payload;
    },
    closeWesterosTagsModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWesterosTags.fulfilled, (state, action) => {
      if (!action.payload) return;
      return {
        ...state,
        tagsData: action.payload
          ?.edges as GraphqlDataSchema<ITagModel>["edges"],
        totalCount: action.payload?.totalCount,
        loading: false,
      };
    });
    builder.addCase(fetchWesterosTags.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const { setWesterosTagsModalData, closeWesterosTagsModal } =
  westerosTagsSlice.actions;
export default westerosTagsSlice.reducer;
