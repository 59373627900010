import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getTaskList } from "api";
import { TaskListSectionType } from "pages/employee-homepage";
import { store } from "store";
import { TaskStatusEnum, getMaxDate, getMinDate } from "config";

import { EmployeeHomepageState } from "./types";
import {
  getFirstDayOfNextWeekStartOfDayUTC,
  getLastDayOfWeekEndOfDayUTC,
  getTaskListFilters,
  getTodayEndOfDayUTC,
  getTodayStartOfDayUTC,
  getTomorrowStartOfDayUTC,
  getYesterdayEndOfDayUTC,
} from "./utils";

const initialState: EmployeeHomepageState = {
  [TaskListSectionType.DUE_TODAY]: {
    taskList: [],
    loading: false,
  },
  [TaskListSectionType.DUE_END_OF_WEEK]: {
    taskList: [],
    loading: false,
  },
  [TaskListSectionType.OTHER]: {
    taskList: [],
    loading: false,
  },
};

export const fetchTaskList = {
  [TaskListSectionType.DUE_TODAY]: createAsyncThunk(
    `employeeHomepage/fetchTaskList${TaskListSectionType.DUE_TODAY}`,
    async () => {
      const userFilters = store.getState().filters?.filters || [];
      const dueTodayVars = getTaskListFilters({
        fromDate: getTodayStartOfDayUTC(),
        toDate: getTodayEndOfDayUTC(),
      });
      const notCompletedVars = getTaskListFilters({
        fromDate: getMinDate(),
        toDate: getYesterdayEndOfDayUTC(),
        neStatus: TaskStatusEnum.COMPLETED,
      });
      const response = await Promise.all([
        getTaskList([...notCompletedVars, ...userFilters]),
        getTaskList([...dueTodayVars, ...userFilters]),
      ]);
      return response.reduce((acc, item) => {
        acc.push(...item);

        return acc;
      }, []);
    }
  ),
  [TaskListSectionType.DUE_END_OF_WEEK]: createAsyncThunk(
    `employeeHomepage/fetchTaskList${TaskListSectionType.DUE_END_OF_WEEK}`,
    async () => {
      const userFilters = store.getState().filters?.filters || [];
      const vars = getTaskListFilters({
        fromDate: getTomorrowStartOfDayUTC(),
        toDate: getLastDayOfWeekEndOfDayUTC(),
      });
      const response = await getTaskList([...vars, ...userFilters]);
      return response;
    }
  ),
  [TaskListSectionType.OTHER]: createAsyncThunk(
    `employeeHomepage/fetchTaskList${TaskListSectionType.OTHER}`,
    async () => {
      const userFilters = store.getState().filters?.filters || [];
      const vars = getTaskListFilters({
        fromDate: getFirstDayOfNextWeekStartOfDayUTC(),
        toDate: getMaxDate(),
      });
      const response = await getTaskList([...vars, ...userFilters]);
      return response;
    }
  ),
};

const employeeHomepageSlice = createSlice({
  name: "employeeHomepage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    Object.entries(fetchTaskList).forEach(([key, value]) => {
      builder.addCase(value.fulfilled, (state, action) => {
        if (!action.payload) return;
        return {
          ...state,
          [key]: {
            taskList: action.payload,
            loading: false,
          },
        };
      });
      builder.addCase(value.pending, (state) => {
        return {
          ...state,
          [key]: {
            ...{
              ...state[key as TaskListSectionType],
              loading: true,
            },
          },
        };
      });
    });
  },
});

export default employeeHomepageSlice.reducer;
