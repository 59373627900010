import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getFireblocksList, IGetFireblocksListReturnType } from "api";
import { Nullable } from "config";
import { FireblockGroupByKeys, store } from "store";

import { groupByOptionsToFunctionsMap } from "./constants";
import { IInitialState } from "./types";

const initialState: IInitialState = {
  fireblocksData: null,
  totalCount: 1,
  gasStationValue: {
    value: 0,
  },
  totalValue: 0,
  loading: false,
};

export const fetchFireblocks = createAsyncThunk(
  "fireblocks/fetchFireblocks",
  async (groupByOption?: Nullable<FireblockGroupByKeys>) => {
    const filters = store.getState().filters;
    if (!Object.values(filters).length) {
      return;
    }
    let fetchFunction = getFireblocksList;
    if (groupByOption && groupByOptionsToFunctionsMap[groupByOption]) {
      fetchFunction = groupByOptionsToFunctionsMap[groupByOption] as any;
    }
    const response = await fetchFunction(filters);
    return response;
  }
);

const fireblocksSlice = createSlice({
  name: "fireblocks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFireblocks.fulfilled, (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          loading: false,
        };
      }
      return {
        fireblocksData: action.payload
          ?.edges as IGetFireblocksListReturnType["edges"],
        totalCount: action.payload?.totalCount,
        totalValue: action.payload?.totalValue,
        gasStationValue: action.payload?.gasStationValue,
        loading: false,
      };
    });
    builder.addCase(fetchFireblocks.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export default fireblocksSlice.reducer;
