import { ICommonModalData } from "config";

export enum FundModalModes {
  VIEW = "view",
  EDIT = "edit",
  CREATE = "create",
}

export interface IFundsModalData extends ICommonModalData<FundModalModes> {
  productFundId?: number;
}
