import {
  WesterosServiceCreateDocument,
  WesterosServiceDeleteDocument,
  WesterosServiceGetByIdDocument,
  WesterosServiceListDocument,
  WesterosServicesPaginatedDocument,
  WesterosServiceUpdateDocument,
} from "api/generated";
import { Optional, PartialNullable, ResourceEnum, ScopeEnum } from "config";

import { RequestTypes } from "../../enums";
import { getRequestInstance } from "../../utils";
import {
  IWesterosServiceListVars,
  IWesterosServiceListReturnType,
  ICreateWesterosServiceVars,
  IWesterosServiceModel,
  IGetWesterosServicesReturnType,
  IUpdateWesterosServiceVars,
} from "./types";

export async function getWesterosServicesList(
  variables: IWesterosServiceListVars
): Promise<IWesterosServiceListReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: WesterosServicesPaginatedDocument,
      variables,
    });
    return res?.data?.westerosServicesPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createWesterosService(
  variables: ICreateWesterosServiceVars
): Promise<Optional<Pick<IWesterosServiceModel, "westerosServiceId">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: WesterosServiceCreateDocument,
      variables,
    });
    return res?.data?.westerosServiceCreate;
  } catch (error) {
    throw error;
  }
}

export async function getWesterosService(
  westerosServiceId: number
): Promise<PartialNullable<IWesterosServiceModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: WesterosServiceGetByIdDocument,
      variables: {
        westerosServiceId,
      },
    });
    return res?.data?.westerosServiceGetById;
  } catch (error) {
    throw error;
  }
}

export async function getWesterosServices(
  scope: ScopeEnum,
  resource: ResourceEnum
): Promise<IGetWesterosServicesReturnType[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: WesterosServiceListDocument,
      variables: {
        scope,
        resource,
      },
    });
    return res?.data?.westerosServiceList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function updateWesterosService(
  variables: IUpdateWesterosServiceVars
): Promise<Optional<Pick<IWesterosServiceModel, "westerosServiceId">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: WesterosServiceUpdateDocument,
      variables,
    });
    return res?.data?.westerosServiceUpdate;
  } catch (error) {
    throw error;
  }
}

export async function deleteWesterosService(
  westerosServiceId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: WesterosServiceDeleteDocument,
      variables: {
        westerosServiceId,
      },
    });
    return Boolean(res?.data?.westerosServiceDelete);
  } catch (error) {
    throw error;
  }
}
