import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { getTaskManagementNoteTypesList, INotesListReturnType } from "api";
import { Nullable } from "config";
import { RootState } from "store/store";

import { ITaskManagementNoteTypeModalData } from "./types";
import { TaskManagementNoteTypeModalMode } from "./enums";

const initialState = {
  notesTypeData: null as Nullable<INotesListReturnType["edges"]>,
  totalCount: 1,
  loading: false,
  modalData: {
    open: false,
    mode: TaskManagementNoteTypeModalMode.VIEW,
  } as ITaskManagementNoteTypeModalData,
};

export const fetchTaskManagementNotesTypes = createAsyncThunk(
  "taskManagement-note-type/fetchTaskManagementNotesTypes",
  async (_, { getState }) => {
    const filters = (getState() as RootState).filters;
    if (!Object.values(filters).length) {
      return;
    }

    const response = await getTaskManagementNoteTypesList(filters);
    return response;
  }
);

const taskManagementNoteTypeSlice = createSlice({
  name: "taskManagement-note-type",
  initialState,
  reducers: {
    setTaskManagementNoteTypeModalData: (
      state,
      action: PayloadAction<ITaskManagementNoteTypeModalData>
    ) => {
      state.modalData = action.payload;
    },
    closeTaskManagementNoteTypeModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchTaskManagementNotesTypes.fulfilled,
      (state, action) => {
        if (!action.payload) {
          return {
            ...state,
            loading: false,
          };
        }
        return {
          ...state,
          notesTypeData: action.payload?.edges,
          totalCount: action.payload?.totalCount,
          loading: false,
        };
      }
    );
    builder.addCase(fetchTaskManagementNotesTypes.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const {
  setTaskManagementNoteTypeModalData,
  closeTaskManagementNoteTypeModal,
} = taskManagementNoteTypeSlice.actions;
export default taskManagementNoteTypeSlice.reducer;
