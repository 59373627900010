import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { store } from "store";
import { Nullable } from "config";
import {
  getReportingList,
  IGetReportingListReturnType,
  IGetReportingListGroupedByAssigneeReturnType,
} from "api";
import {
  groupByOptionsToFunctionsMap,
  ReportingGroupByKeys,
} from "./constants";

const initialState = {
  reportingData: null as Nullable<
    | IGetReportingListReturnType["edges"]
    | IGetReportingListGroupedByAssigneeReturnType["edges"]
  >,
  totalCount: 1,
  loading: false,
};

export const fetchReportingData = createAsyncThunk(
  "reporting/fetchReportingData",
  async (groupByValue: Nullable<ReportingGroupByKeys>) => {
    const filters = store.getState().filters;
    if (!Object.values(filters).length) {
      return;
    }
    let asyncFunction = getReportingList;
    if (groupByValue && (groupByOptionsToFunctionsMap as any)[groupByValue]) {
      asyncFunction = (groupByOptionsToFunctionsMap as any)[
        groupByValue
      ] as any;
    }
    const response = await asyncFunction(filters);
    return response;
  }
);

const reportingSlice = createSlice({
  name: "reporting",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchReportingData.fulfilled, (state, action) => {
      if (!action.payload) return;
      return {
        ...state,
        reportingData: action.payload
          ?.edges as IGetReportingListReturnType["edges"],
        totalCount: action.payload?.totalCount,
        loading: false,
      };
    });
    builder.addCase(fetchReportingData.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export default reportingSlice.reducer;
