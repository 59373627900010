import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";
import {
  AdvisoryTrancheGetByIdDocument,
  AdvisoryTranchesPaginatedDocument,
  AdvisoryTrancheCreateDocument,
  AdvisoryTrancheUpdateDocument,
  AdvisoryTrancheListAvailableNameDocument,
  AdvisoryTrancheListDocument,
  AdvisoryTrancheListExitsByTrancheDocument,
} from "api/generated";

import { Optional, ResourceEnum, ScopeEnum } from "config";

import {
  IAdvisoryTrancheModel,
  IAdvisoryTrancheCreateVars,
  IAdvisoryTranchePaginatedResponse,
  IAdvisoryTranchePaginatedVars,
  IAdvisoryTrancheUpdateVars,
} from "./types";

export async function getAdvisoryTrancheListAvailableName(
  advisoryProjectId: number,
  resource: ResourceEnum,
  scope: ScopeEnum
): Promise<string[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AdvisoryTrancheListAvailableNameDocument,
      variables: {
        resource,
        scope,
        advisoryProjectId,
      },
    });
    return res?.data?.advisoryTrancheListAvailableName ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getAdvisoryTrancheList(
  resource: ResourceEnum,
  scope: ScopeEnum,
  advisoryProjectId: number
): Promise<
  Pick<IAdvisoryTrancheModel, "advisoryTrancheId" | "name" | "price">[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AdvisoryTrancheListDocument,
      variables: {
        resource,
        scope,
        advisoryProjectId,
      },
    });
    return res?.data?.advisoryTrancheList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getAdvisoryTrancheExitsDetails(
  advisoryTrancheId: number
): Promise<
  {
    firstName: string;
    lastName: string;
    trancheTokens: number;
    distributedTokens: number;
  }[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AdvisoryTrancheListExitsByTrancheDocument,
      variables: {
        advisoryTrancheId,
      },
    });
    return res?.data?.advisoryTrancheListExitsByTranche ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getAdvisoryTranche(
  advisoryTrancheId: number
): Promise<Optional<IAdvisoryTrancheModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AdvisoryTrancheGetByIdDocument,
      variables: {
        id: advisoryTrancheId,
      },
    });
    return res?.data?.advisoryTrancheGetById;
  } catch (error) {
    throw error;
  }
}

export async function getAdvisoryTranchePaginated(
  variables: IAdvisoryTranchePaginatedVars
): Promise<Optional<IAdvisoryTranchePaginatedResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: AdvisoryTranchesPaginatedDocument,
      variables,
    });
    return res?.data?.advisoryTrancheListPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createAdvisoryTranche(
  variables: IAdvisoryTrancheCreateVars
): Promise<Optional<{ advisoryTrancheId: number }>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AdvisoryTrancheCreateDocument,
      variables,
    });
    return res?.data?.advisoryTrancheCreate;
  } catch (error) {
    throw error;
  }
}

export async function updateAdvisoryTranche(
  variables: IAdvisoryTrancheUpdateVars
): Promise<Optional<{ advisoryTrancheId: number }>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: AdvisoryTrancheUpdateDocument,
      variables,
    });
    return res?.data?.advisoryTrancheUpdate;
  } catch (error) {
    throw error;
  }
}
