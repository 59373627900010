import {
  CreateStatementTypeDocument,
  DeleteStatementTypeDocument,
  StatementTypeGetByIdDocument,
  StatementTypeListDocument,
  StatementTypesPaginatedDocument,
  UpdateStatementTypeDocument,
} from "api/generated";
import { Optional, PartialNullable, ResourceEnum, ScopeEnum } from "config";

import {
  GetStatementTypes,
  IStatementTypeCreateVars,
  IStatementTypeFilters,
  IStatementTypeModel,
  IStatementTypeUpdateVars,
  IStatementTypesPaginatedResponse,
  StatementTypeCreateUpdateResponse,
} from "./types";
import { getRequestInstance } from "../../utils";
import { RequestTypes } from "../../enums";

export async function getStatementTypes(
  resource: ResourceEnum,
  scope: ScopeEnum
): Promise<GetStatementTypes[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: StatementTypeListDocument,
      variables: {
        scope,
        resource,
      },
    });
    return res?.data?.statementTypeList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getStatementTypesPaginated(
  variables: IStatementTypeFilters
): Promise<IStatementTypesPaginatedResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: StatementTypesPaginatedDocument,
      variables,
    });
    return res?.data?.statementTypesPaginated;
  } catch (error) {
    throw error;
  }
}

export async function getStatementType(
  statementTypeId: number
): Promise<PartialNullable<IStatementTypeModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: StatementTypeGetByIdDocument,
      variables: { statementTypeId },
    });
    return res?.data?.statementTypeGetById;
  } catch (error) {
    throw error;
  }
}

export async function createStatementType(
  variables: IStatementTypeCreateVars
): Promise<Optional<StatementTypeCreateUpdateResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: CreateStatementTypeDocument,
      variables,
    });
    return res?.data?.statementTypeCreate;
  } catch (error) {
    throw error;
  }
}

export async function updateStatementType(
  variables: IStatementTypeUpdateVars
): Promise<Optional<StatementTypeCreateUpdateResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: UpdateStatementTypeDocument,
      variables,
    });
    return res?.data?.statementTypeUpdate;
  } catch (error) {
    throw error;
  }
}

export async function deleteStatementType(
  statementTypeId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: DeleteStatementTypeDocument,
      variables: { statementTypeId },
    });
    return Boolean(res?.data?.statementTypeDelete);
  } catch (error) {
    throw error;
  }
}
