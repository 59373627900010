import {
  UpcomingEventCreateDocument,
  UpcomingEventDeleteDocument,
  UpcomingEventDocumentDownloadDocument,
  UpcomingEventDocumentUploadDocument,
  UpcomingEventForHomepageByIdDocument,
  UpcomingEventGetByIdDocument,
  UpcomingEventListTimeZonesDocument,
  UpcomingEventsByUserDocument,
  UpcomingEventsPaginatedDocument,
  UpcomingEventUpdateDocument,
} from "api/generated";
import { Optional, PartialNullable } from "config";

import { RequestTypes } from "../../enums";
import { getRequestInstance } from "../../utils";
import {
  GetUpcomingEventsReturnType,
  ICreateUpcomingEventVars,
  ITimezoneModel,
  IUpcomingEventDocumentModel,
  IUpcomingEventModel,
  IUpcomingEventsPaginatedResponse,
  IUpcomingEventsPaginatedVars,
  IUpdateUpcomingEventVars,
  UpcomingEventCreateUpdateReturnType,
  UpcomingEventsForHomepageResponse,
} from "./types";
import { IFile } from "../../types";

export async function getUpcomingEventsPaginated(
  variables: IUpcomingEventsPaginatedVars
): Promise<IUpcomingEventsPaginatedResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: UpcomingEventsPaginatedDocument,
      variables,
    });
    return res?.data?.upcomingEventsPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createUpcomingEvent(
  variables: ICreateUpcomingEventVars
): Promise<Optional<UpcomingEventCreateUpdateReturnType>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: UpcomingEventCreateDocument,
      variables,
    });
    return res?.data?.upcomingEventCreate;
  } catch (error) {
    throw error;
  }
}

export async function updateUpcomingEvent(
  variables: IUpdateUpcomingEventVars
): Promise<Optional<UpcomingEventCreateUpdateReturnType>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: UpcomingEventUpdateDocument,
      variables,
    });
    return res?.data?.upcomingEventUpdate;
  } catch (error) {
    throw error;
  }
}

export async function getUpcomingEvent(
  upcomingEventId: number
): Promise<PartialNullable<IUpcomingEventModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: UpcomingEventGetByIdDocument,
      variables: { upcomingEventId },
    });
    return res?.data?.upcomingEventGetById;
  } catch (error) {
    throw error;
  }
}

export async function getUpcomingEvents(): Promise<
  GetUpcomingEventsReturnType[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: UpcomingEventsByUserDocument,
    });
    return res?.data?.upcomingEventsByUser ?? [];
  } catch (error) {
    throw error;
  }
}

export async function deleteUpcomingEvent(
  upcomingEventId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: UpcomingEventDeleteDocument,
      variables: { upcomingEventId },
    });
    return Boolean(res?.data?.upcomingEventDelete);
  } catch (error) {
    throw error;
  }
}

export async function uploadUpcomingEventDocument(
  variables: IFile
): Promise<Optional<IUpcomingEventDocumentModel<number>>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: UpcomingEventDocumentUploadDocument,
      variables,
    });
    return res?.data?.upcomingEventDocumentUpload;
  } catch (error) {
    throw error;
  }
}

export async function downloadUpcomingEventDocument(
  upcomingEventDocumentId: number
): Promise<Optional<string>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: UpcomingEventDocumentDownloadDocument,
      variables: { upcomingEventDocumentId },
    });
    return res?.data?.upcomingEventDocumentDownload;
  } catch (error) {
    throw error;
  }
}

export async function getUpcomingEventForHomepage(
  upcomingEventId: number
): Promise<UpcomingEventsForHomepageResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: UpcomingEventForHomepageByIdDocument,
      variables: { upcomingEventId },
    });
    return res?.data?.upcomingEventViewForInvestor;
  } catch (error) {
    throw error;
  }
}

export async function getTimeZones(): Promise<ITimezoneModel[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: UpcomingEventListTimeZonesDocument,
      fetchPolicy: "cache-first",
    });
    return res?.data?.upcomingEventListTimeZones ?? [];
  } catch (e) {
    throw e;
  }
}
