import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { IWesterosServiceListReturnType, getWesterosServicesList } from "api";
import { Nullable } from "config";
import { RootState } from "store/store";

import { IWesterosServiceModalData } from "./types";
import { WesterosServiceModalMode } from "./enums";

const initialState = {
  serviceData: null as Nullable<IWesterosServiceListReturnType["edges"]>,
  totalCount: 1,
  loading: false,
  modalData: {
    open: false,
    mode: WesterosServiceModalMode.VIEW,
  } as IWesterosServiceModalData,
};

export const fetchWesterosServices = createAsyncThunk(
  "westeros-service/fetchWesterosServices",
  async (_, { getState }) => {
    const filters = (getState() as RootState).filters;
    if (!Object.values(filters).length) {
      return;
    }

    const response = await getWesterosServicesList(filters);
    return response;
  }
);

const westerosServiceSlice = createSlice({
  name: "westeros-service",
  initialState,
  reducers: {
    setWesterosServiceModalData: (
      state,
      action: PayloadAction<IWesterosServiceModalData>
    ) => {
      state.modalData = action.payload;
    },
    closeWesterosServiceModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWesterosServices.fulfilled, (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          loading: false,
        };
      }
      return {
        ...state,
        serviceData: action.payload?.edges,
        totalCount: action.payload?.totalCount,
        loading: false,
      };
    });
    builder.addCase(fetchWesterosServices.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const { setWesterosServiceModalData, closeWesterosServiceModal } =
  westerosServiceSlice.actions;
export default westerosServiceSlice.reducer;
