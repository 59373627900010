import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { getNotesTypeList, INotesListReturnType } from "api";
import { Nullable } from "config";
import { RootState } from "store/store";

import { NoteTypeModalMode } from "./enums";
import { INoteTypeModalData } from "./types";

const initialState = {
  notesTypeData: null as Nullable<INotesListReturnType["edges"]>,
  totalCount: 1,
  loading: false,
  modalData: {
    open: false,
    mode: NoteTypeModalMode.VIEW,
  } as INoteTypeModalData,
};

export const fetchNotesTypes = createAsyncThunk(
  "note-type/fetchNotesTypes",
  async (_, { getState }) => {
    const filters = (getState() as RootState).filters;
    if (!Object.values(filters).length) {
      return;
    }
    const fetchFunction = getNotesTypeList;
    const response = await fetchFunction(filters);
    return response;
  }
);

const noteTypeSlice = createSlice({
  name: "note-type",
  initialState,
  reducers: {
    setNoteTypeModalData: (
      state,
      action: PayloadAction<INoteTypeModalData>
    ) => {
      state.modalData = action.payload;
    },
    closeNoteTypeModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotesTypes.fulfilled, (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          loading: false,
        };
      }
      return {
        ...state,
        notesTypeData: action.payload?.edges,
        totalCount: action.payload?.totalCount,
        loading: false,
      };
    });
    builder.addCase(fetchNotesTypes.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const { setNoteTypeModalData, closeNoteTypeModal } =
  noteTypeSlice.actions;
export default noteTypeSlice.reducer;
