import {
  UserRefreshOnFocusDocument,
  UserRefreshTokenDocument,
} from "api/generated";
import { errorMessagesForRequests } from "api/messages";
import { STORAGE_CONSTANTS } from "config";
import {
  NotificationDuration,
  NotificationMode,
  setAuthData,
  showSnackBar,
  signOutAndClearStore,
  store,
} from "store";

import { RequestTypes } from "../../enums";
import { ITokens } from "../../types";
import { getRequestInstance, setTokensIntoStorage } from "../../utils";

export async function refreshToken(token: string): Promise<ITokens> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: UserRefreshTokenDocument,
      variables: {
        token,
      },
    });
    if (!res?.data?.userRefreshToken) {
      throw new Error("No token");
    }
    setTokensIntoStorage(res.data.userRefreshToken);
    return res.data.userRefreshToken;
  } catch (error) {
    throw error;
  }
}

export async function refreshIdToken(): Promise<Pick<ITokens, "idToken">> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: UserRefreshOnFocusDocument,
    });
    if (!res?.data?.userIntrospect) {
      localStorage.clear();
      store.dispatch(
        showSnackBar({
          mode: NotificationMode.ERROR,
          duration: NotificationDuration.ERRORS,
          message: errorMessagesForRequests.expired,
        })
      );
      setTimeout(() => {
        store.dispatch(signOutAndClearStore());
      }, 2500);
    }
    if (res?.data?.userIntrospect?.idToken) {
      localStorage.setItem(
        STORAGE_CONSTANTS.ID_TOKEN,
        JSON.stringify(res.data.userIntrospect.idToken)
      );
      store.dispatch(
        setAuthData({
          idToken: res.data.userIntrospect.idToken,
        })
      );
    } else {
      setTimeout(() => {
        store.dispatch(signOutAndClearStore());
      }, 2500);
    }
    return res?.data?.userIntrospect;
  } catch (error) {
    throw error;
  }
}
