import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import {
  ILinkAgentPayoutsAggregatedResponse,
  ILinkAgentPayoutsPaginatedResponse,
  getLinkAgentPayoutsAggregatedPaginatedData,
  getLinkAgentPayoutsPaginatedData,
} from "api";
import { Nullable } from "config";
import { RootState } from "store/store";

import { LinkAgentPayoutModalModes } from "./enums";
import { ILinkAgentPayoutModalData } from "./types";

const initialState = {
  aggregatedPayoutsData: {
    linkAgentPayoutAggregatedData: null as Nullable<
      ILinkAgentPayoutsAggregatedResponse["edges"]
    >,
    totalCount: 1,
    loading: false,
  },
  generalPayoutsData: {
    linkAgentPayoutsData: null as Nullable<
      ILinkAgentPayoutsPaginatedResponse["edges"]
    >,
    totalCount: 1,
    loading: false,
  },
  modalData: {
    open: false,
    mode: LinkAgentPayoutModalModes.VIEW,
  } as ILinkAgentPayoutModalData,
};

export const fetchLinkAgentPayoutsAggregated = createAsyncThunk(
  "link-agent-payouts/fetchLinkAgentPayoutsAggregated",
  async (_, { getState }) => {
    const filters = (getState() as RootState).filters;
    try {
      if (!Object.values(filters).length) {
        return;
      }

      const response = await getLinkAgentPayoutsAggregatedPaginatedData(
        filters
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchLinkAgentPayouts = createAsyncThunk(
  "link-agent-payouts/fetchLinkAgentPayouts",
  async (linkAgentProfileId: number, { getState }) => {
    try {
      const filters = (getState() as RootState).filters;
      if (!Object.values(filters).length) {
        return;
      }

      const response = await getLinkAgentPayoutsPaginatedData({
        ...filters,
        searchingId: linkAgentProfileId,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const linkAgentPayoutsSlice = createSlice({
  name: "link-agent-payouts",
  initialState,
  reducers: {
    setLinkAgentPayoutModalData: (
      state,
      action: PayloadAction<ILinkAgentPayoutModalData>
    ) => {
      state.modalData = action.payload;
    },
    closeLinkAgentPayoutModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchLinkAgentPayoutsAggregated.fulfilled,
      (state, action) => {
        if (!action.payload) {
          state.aggregatedPayoutsData.loading = false;
          return;
        }
        state.aggregatedPayoutsData = {
          linkAgentPayoutAggregatedData: action.payload?.edges,
          totalCount: action.payload?.totalCount,
          loading: false,
        };
      }
    );
    builder.addCase(fetchLinkAgentPayoutsAggregated.pending, (state) => {
      state.aggregatedPayoutsData.loading = true;
    });
    builder.addCase(fetchLinkAgentPayoutsAggregated.rejected, (state) => {
      state.aggregatedPayoutsData.loading = false;
    });
    builder.addCase(fetchLinkAgentPayouts.fulfilled, (state, action) => {
      if (!action.payload) {
        state.generalPayoutsData.loading = false;
        return;
      }
      state.generalPayoutsData = {
        linkAgentPayoutsData: action.payload?.edges,
        totalCount: action.payload?.totalCount,
        loading: false,
      };
    });
    builder.addCase(fetchLinkAgentPayouts.pending, (state) => {
      state.generalPayoutsData.loading = true;
    });
    builder.addCase(fetchLinkAgentPayouts.rejected, (state) => {
      state.generalPayoutsData.loading = false;
    });
  },
});

export const { setLinkAgentPayoutModalData, closeLinkAgentPayoutModal } =
  linkAgentPayoutsSlice.actions;
export default linkAgentPayoutsSlice.reducer;
