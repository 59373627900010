import {
  LeadConversionExpectationCreateDocument,
  LeadConversionExpectationDeleteDocument,
  LeadConversionExpectationGetByIdDocument,
  LeadConversionExpectationListDocument,
  LeadConversionExpectationsPaginatedDocument,
  LeadConversionExpectationUpdateDocument,
} from "api/generated";
import { Optional, PartialNullable, ResourceEnum, ScopeEnum } from "config";

import { RequestTypes } from "../../enums";
import { getRequestInstance } from "../../utils";
import {
  ICreateExpectedConversionsArgs,
  IExpectedConversionArgs,
  IExpectedConversionModel,
  IExpectedConversionReturnType,
  IUpdateExpectedConversionsArgs,
} from "./types";

export async function getExpectedConversion(
  variables: IExpectedConversionArgs
): Promise<IExpectedConversionReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: LeadConversionExpectationsPaginatedDocument,
      variables,
    });
    return res?.data?.leadConversionExpectationsPaginated;
  } catch (error) {
    throw error;
  }
}

export async function getExpectedConversionsForSelects(
  scope: ScopeEnum,
  resource: ResourceEnum
): Promise<
  Pick<IExpectedConversionModel, "leadConversionExpectationId" | "name">[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: LeadConversionExpectationListDocument,
      variables: {
        scope,
        resource,
      },
    });
    return res?.data?.leadConversionExpectationList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function createExpectedConversion(
  variables: ICreateExpectedConversionsArgs
): Promise<
  Optional<Pick<IExpectedConversionModel, "leadConversionExpectationId">>
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: LeadConversionExpectationCreateDocument,
      variables,
    });
    return res?.data?.leadConversionExpectationCreate;
  } catch (error) {
    throw error;
  }
}

export async function getExpectedConversionById(
  leadConversionExpectationId: number
): Promise<PartialNullable<IExpectedConversionModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: LeadConversionExpectationGetByIdDocument,
      variables: {
        leadConversionExpectationId,
      },
    });
    return res?.data?.leadConversionExpectationGetById;
  } catch (error) {
    throw error;
  }
}

export async function updateExpectedConversion(
  variables: IUpdateExpectedConversionsArgs
): Promise<
  Optional<Pick<IExpectedConversionModel, "leadConversionExpectationId">>
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: LeadConversionExpectationUpdateDocument,
      variables,
    });
    return res?.data?.leadConversionExpectationUpdate;
  } catch (error) {
    throw error;
  }
}

export async function deleteExpectedConversion(
  leadConversionExpectationId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: LeadConversionExpectationDeleteDocument,
      variables: {
        leadConversionExpectationId,
      },
    });
    return Boolean(res?.data?.leadConversionExpectationDelete);
  } catch (error) {
    throw error;
  }
}
