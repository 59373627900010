import {
  getFireblocksListByAsset,
  getFireblocksListByVault,
  getFireblocksListByVaultType,
  getFireblocksListByVaultTypeAndAsset,
} from "api";
import { FireblockGroupByKeys } from "./enums";

export const groupByOptionsToFunctionsMap = {
  [FireblockGroupByKeys.ASSET]: getFireblocksListByAsset,
  [FireblockGroupByKeys.TYPE]: getFireblocksListByVaultType,
  [FireblockGroupByKeys.VAULT]: getFireblocksListByVault,
  [FireblockGroupByKeys.TYPE_ASSET]: getFireblocksListByVaultTypeAndAsset,
};
