import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
  AnyAction,
  Reducer,
} from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";

import notification from "./slices/user-interaction/notifications";
import auth from "./slices/auth";
import filters from "./slices/filters";
import users from "./slices/users";
import exchange from "./slices/assets/exchange";
import fireblocks from "./slices/assets/fireblocks";
import sidebar from "./slices/sidebar";
import snapshots from "./slices/snapshots";
import {
  AdvisoryProjectReducer,
  AdvisoryBuyerReducer,
  AdvisoryPurchaseReducer,
  AdvisoryTrancheReducer,
  AdvisoryExitPlanReducer,
  AdvisoryDistributionReducer,
  LibraryDocumentListReducer,
  LibraryExpectedConversionReducer,
  LibraryNoteTypeReducer,
  LibraryProductFundReducer,
  LibrarySourceExchangeReducer,
  LibraryTagsReducer,
  LibraryVentureBlockchainReducer,
  LibraryVentureContactTypeReducer,
  LibraryVentureDocumentListReducer,
  LibraryVentureNoteTypeReducer,
  LibraryVentureProjectTypeReducer,
  LibraryVentureSectorReducer,
  LibraryVentureTagsReducer,
  LibraryWesterosDocumentListReducer,
  LibraryWesterosNoteTypeReducer,
  LibraryWesterosServiceReducer,
  LibraryWesterosTagsReducer,
  LibraryFireblocksWalletReducer,
  InvestmentCapitalMovementDividendReducer,
  InvestmentCapitalMovementReducer,
  InvestmentInvestorProfileReducer,
  InvestmentOriginationSourceReducer,
  InvestmentMethodReducer,
  InvestmentReportingReducer,
  VentureValuationControlReducer,
  WesterosClientReducer,
  VentureContactReducer,
} from "./slices";
import linkAgentProfiles from "./slices/link-agent/link-agent-profiles";
import weeklyUpdates from "./slices/user-interaction/weekly-updates";
import announcement from "./slices/announcement";
import upcomingEvents from "./slices/user-interaction/upcoming-events";
import statements from "./slices/user-interaction/statements";
import reports from "./slices/user-interaction/reports";
import statementType from "./slices/user-interaction/statement-type";
import reportType from "./slices/user-interaction/report-type";
import emailSystem from "./slices/system/email/email-system";
import linkAgentPayouts from "./slices/link-agent/link-agent-payouts";
import linkAgentReports from "./slices/link-agent/link-agent-reports";
import filtersData from "./slices/filters-data";
import resources from "./slices/user-interaction/resources";
import emailTemplates from "./slices/system/email/email-templates";
import projectProfile from "./slices/venture/project-profile";
import assetAttribution from "./slices/venture/asset-attribution";
import task from "./slices/system/task-management/task";
import taskType from "./slices/system/task-management/task-type";
import taskManagementNoteType from "./slices/system/task-management/task-note-type";
import recurringTask from "./slices/system/task-management/recurring-task";
import employeeHomepage from "./slices/employee-homepage";
import { AuthActions } from "./slices/auth/enums";

const combinedReducer = combineReducers({
  notification,
  auth,
  filters,
  users,
  exchange,
  fireblocks,
  sidebar,
  snapshots,
  linkAgentProfiles,
  weeklyUpdates,
  announcement,
  upcomingEvents,
  statements,
  reports,
  statementType,
  reportType,
  emailSystem,
  linkAgentPayouts,
  linkAgentReports,
  filtersData,
  resources,
  emailTemplates,
  projectProfile,
  assetAttribution,
  task,
  recurringTask,
  taskType,
  taskManagementNoteType,
  employeeHomepage,
  AdvisoryProjectReducer,
  AdvisoryBuyerReducer,
  AdvisoryPurchaseReducer,
  AdvisoryTrancheReducer,
  AdvisoryExitPlanReducer,
  AdvisoryDistributionReducer,
  LibraryDocumentListReducer,
  LibraryExpectedConversionReducer,
  LibraryNoteTypeReducer,
  LibraryProductFundReducer,
  LibrarySourceExchangeReducer,
  LibraryTagsReducer,
  LibraryVentureBlockchainReducer,
  LibraryVentureContactTypeReducer,
  LibraryVentureDocumentListReducer,
  LibraryVentureNoteTypeReducer,
  LibraryVentureProjectTypeReducer,
  LibraryVentureSectorReducer,
  LibraryVentureTagsReducer,
  LibraryWesterosDocumentListReducer,
  LibraryWesterosNoteTypeReducer,
  LibraryWesterosServiceReducer,
  LibraryWesterosTagsReducer,
  LibraryFireblocksWalletReducer,
  InvestmentCapitalMovementDividendReducer,
  InvestmentCapitalMovementReducer,
  InvestmentMethodReducer,
  InvestmentInvestorProfileReducer,
  InvestmentOriginationSourceReducer,
  InvestmentReportingReducer,
  VentureValuationControlReducer,
  VentureContactReducer,
  WesterosClientReducer,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === AuthActions.SIGN_OUT) {
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};

const sentryReduxEnhancer =
  process.env.REACT_APP_SENTRY_DSN && process.env.REACT_APP_PROJECT_ENV
    ? Sentry.createReduxEnhancer({
        // Optionally pass options listed below
      })
    : null;

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  enhancers: sentryReduxEnhancer ? [sentryReduxEnhancer] : undefined,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof combinedReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
