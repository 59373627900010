import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import {
  getEmailTemplatesPaginated,
  IEmailTemplatesPaginatedResponse,
} from "api";
import { Nullable } from "config";
import { RootState } from "store/store";

import { EmailTemplateModalMode } from "./enums";
import { IEmailTemplateModalData } from "./types";

const initialState = {
  emailTemplatesData: null as Nullable<
    IEmailTemplatesPaginatedResponse["edges"]
  >,
  totalCount: 1,
  loading: false,
  modalData: {
    open: false,
    mode: EmailTemplateModalMode.VIEW,
  } as IEmailTemplateModalData,
};

const emailTemplateName = "email-templates";

export const fetchEmailTemplates = createAsyncThunk(
  `${emailTemplateName}/fetchEmailTemplates`,
  async (_, { getState }) => {
    const filters = (getState() as RootState).filters;
    if (!Object.values(filters).length) {
      return;
    }
    const response = await getEmailTemplatesPaginated(filters);
    return response;
  }
);

const emailTemplateSlice = createSlice({
  name: emailTemplateName,
  initialState,
  reducers: {
    setEmailTemplateModalData: (
      state,
      action: PayloadAction<IEmailTemplateModalData>
    ) => {
      state.modalData = action.payload;
    },
    closeEmailTemplateModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEmailTemplates.fulfilled, (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          loading: false,
        };
      }
      return {
        ...state,
        emailTemplatesData: action.payload?.edges,
        totalCount: action.payload?.totalCount,
        loading: false,
      };
    });
    builder.addCase(fetchEmailTemplates.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const { setEmailTemplateModalData, closeEmailTemplateModal } =
  emailTemplateSlice.actions;
export default emailTemplateSlice.reducer;
