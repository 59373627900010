import { createSlice } from "@reduxjs/toolkit";
import { STORAGE_CONSTANTS } from "config";
import { IAuthState } from "./types";

const initialState: IAuthState = {
  userId: null,
  accessToken: JSON.parse(
    localStorage.getItem(STORAGE_CONSTANTS.ACCESS_TOKEN) === "undefined"
      ? "null"
      : localStorage.getItem(STORAGE_CONSTANTS.ACCESS_TOKEN) || "null"
  ),
  idToken: JSON.parse(
    localStorage.getItem(STORAGE_CONSTANTS.ID_TOKEN) === "undefined"
      ? "null"
      : localStorage.getItem(STORAGE_CONSTANTS.ID_TOKEN) || "null"
  ),
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthData: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearAuthData: () => {
      return initialState;
    },
    signOutAndClearStore: (state) => {
      state.accessToken = null;
    },
  },
});

export const { setAuthData, clearAuthData, signOutAndClearStore } =
  authSlice.actions;
export default authSlice.reducer;
