import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getInvestorProfileList, InvestorProfileForListModel } from "api";
import { Nullable } from "config";
import { store } from "store";

import { IInvestorModalData, ProfileModalModes } from "./types";

const initialState = {
  investorProfileData: null as Nullable<InvestorProfileForListModel["edges"]>,
  totalCount: 1,
  loading: false,
  modalData: {
    open: false,
    mode: ProfileModalModes.VIEW,
  } as IInvestorModalData,
};

export const fetchInvestorsProfile = createAsyncThunk(
  "investor-profile/fetchInvestorsProfile",
  async () => {
    const filters = store.getState().filters;
    if (!Object.values(filters).length) {
      return;
    }
    const response = await getInvestorProfileList(filters);
    return response;
  }
);

const investorProfileSlice = createSlice({
  name: "investor-profile",
  initialState,
  reducers: {
    setInvestorModalData: (
      state,
      action: PayloadAction<IInvestorModalData>
    ) => {
      state.modalData = action.payload;
    },
    setCloseInvestorModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInvestorsProfile.fulfilled, (state, action) => {
      if (!action.payload) return;
      return {
        ...state,
        investorProfileData: action.payload?.edges,
        totalCount: action.payload?.totalCount,
        loading: false,
      };
    });
    builder.addCase(fetchInvestorsProfile.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const { setInvestorModalData, setCloseInvestorModal } =
  investorProfileSlice.actions;
export default investorProfileSlice.reducer;
