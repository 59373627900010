import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getVentureTagsList, ITagModel } from "api";
import { GraphqlDataSchema, Nullable } from "config";
import { RootState } from "store";

import { VentureTagsModalModes } from "./enums";
import { IVentureTagsModalData } from "./types";

const ventureTagsName = "venture-tags";

const initialState = {
  tagsData: null as Nullable<GraphqlDataSchema<ITagModel>["edges"]>,
  totalCount: 1,
  loading: false,
  modalData: {
    mode: VentureTagsModalModes.VIEW,
    open: false,
  } as IVentureTagsModalData,
};

export const fetchVentureTags = createAsyncThunk(
  `${ventureTagsName}/fetchVentureTags`,
  async (_, { getState }) => {
    const filters = (getState() as RootState).filters;
    if (!Object.values(filters).length) {
      return;
    }
    const response = await getVentureTagsList(filters);
    return response;
  }
);

const ventureTagsSlice = createSlice({
  name: ventureTagsName,
  initialState,
  reducers: {
    setVentureTagsModalData: (
      state,
      action: PayloadAction<IVentureTagsModalData>
    ) => {
      state.modalData = action.payload;
    },
    closeVentureTagsModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVentureTags.fulfilled, (state, action) => {
      if (!action.payload) return;
      return {
        ...state,
        tagsData: action.payload
          ?.edges as GraphqlDataSchema<ITagModel>["edges"],
        totalCount: action.payload?.totalCount,
        loading: false,
      };
    });
    builder.addCase(fetchVentureTags.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const { setVentureTagsModalData, closeVentureTagsModal } =
  ventureTagsSlice.actions;
export default ventureTagsSlice.reducer;
