import { getRequestInstance } from "api/services/utils";
import { RequestTypes } from "api/services/enums";
import {
  VentureContactGetByIdDocument,
  VentureContactListDocument,
  VentureContactListPaginatedDocument,
  VentureContactCreateDocument,
  VentureContactUpdateDocument,
  VentureContactDeleteDocument,
} from "api/generated";

import { Optional, ScopeEnum, ResourceEnum } from "config";

import {
  IVentureContactModel,
  IVentureContactCreateVars,
  IVentureContactListPaginatedResponse,
  IVentureContactListPaginatedVars,
  IVentureContactUpdateVars,
  IVentureContactListModel,
} from "./types";

export async function getVentureContact(
  ventureContactId: number
): Promise<Optional<IVentureContactModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureContactGetByIdDocument,
      variables: {
        ventureContactId,
      },
    });
    return res?.data?.ventureContactGetById;
  } catch (error) {
    throw error;
  }
}

export async function getVentureContactListPaginated(
  variables: IVentureContactListPaginatedVars
): Promise<Optional<IVentureContactListPaginatedResponse>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureContactListPaginatedDocument,
      variables,
    });
    return res?.data?.ventureContactListPaginated;
  } catch (error) {
    throw error;
  }
}

export async function getVentureContactList(
  resource: ResourceEnum,
  scope: ScopeEnum
): Promise<IVentureContactListModel[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureContactListDocument,
      variables: {
        scope,
        resource,
      },
    });
    return res?.data?.ventureContactList || [];
  } catch (error) {
    throw error;
  }
}

export async function createVentureContact(
  variables: IVentureContactCreateVars
): Promise<Optional<{ ventureContactId: number }>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureContactCreateDocument,
      variables,
    });
    return res?.data?.ventureContactCreate;
  } catch (error) {
    throw error;
  }
}

export async function updateVentureContact(
  variables: IVentureContactUpdateVars
): Promise<Optional<{ ventureContactId: number }>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureContactUpdateDocument,
      variables,
    });
    return res?.data?.ventureContactUpdate;
  } catch (error) {
    throw error;
  }
}

export async function deleteVentureContact(
  ventureContactId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureContactDeleteDocument,
      variables: {
        ventureContactId,
      },
    });
    return Boolean(res?.data?.ventureContactDelete);
  } catch (error) {
    throw error;
  }
}
