import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ICapitalMovementDividendPaginatedResponse, getCapitalMovementDividendPaginated } from "api";
import { Nullable } from "config";
import { store } from "store";

import { CapitalMovementDividendModalMode } from "./enums";
import { ICapitalMovementDividendModalData } from "./types";

const initialState = {
  capitalMovementDividendData: null as Nullable<ICapitalMovementDividendPaginatedResponse["edges"]>,
  totalCount: 1,
  loading: false,
  modalData: {
    mode: CapitalMovementDividendModalMode.VIEW,
    open: false,
    loading: false,
  } as ICapitalMovementDividendModalData,
};

export const fetchCapitalMovementDividendList = createAsyncThunk("advisory/fetchCapitalMovementDividendList", async () => {
  const filters = store.getState().filters;
  if (!Object.values(filters).length) {
    return;
  }
  const response = await getCapitalMovementDividendPaginated(filters);
  return response;
});

const capitalMovementDividendSlice = createSlice({
  name: "capitalMovementDividend",
  initialState,
  reducers: {
    setCapitalMovementDividendModalData: (state, action: PayloadAction<ICapitalMovementDividendModalData>) => {
      state.modalData = action.payload;
    },
    closeCapitalMovementDividendModal: (state) => {
      state.modalData = initialState.modalData;
    },
    setCapitalMovementDividendModalLoading: (state, action: PayloadAction<boolean>) => {
      state.modalData.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCapitalMovementDividendList.fulfilled, (state, action) => {
      if (!action.payload) return;
      return {
        ...state,
        capitalMovementDividendData: action.payload
          ?.edges as ICapitalMovementDividendPaginatedResponse["edges"],
        totalCount: action.payload?.totalCount,
        loading: false,
      };
    });
    builder.addCase(fetchCapitalMovementDividendList.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const {
  setCapitalMovementDividendModalData,
  closeCapitalMovementDividendModal,
  setCapitalMovementDividendModalLoading,
} = capitalMovementDividendSlice.actions;
export default capitalMovementDividendSlice.reducer;
