import {
  VentureProjectTypeCreateDocument,
  VentureProjectTypeDeleteDocument,
  VentureProjectTypeGetByIdDocument,
  VentureProjectTypeListDocument,
  VentureProjectTypeUpdateDocument,
  VentureProjectTypesPaginatedDocument,
} from "api/generated";
import { PartialNullable } from "config";

import { RequestTypes } from "../../enums";
import { getRequestInstance } from "../../utils";
import {
  IVentureProjectTypeCreateVars,
  IVentureProjectTypeListVars,
  IVentureProjectTypeModel,
  IVentureProjectTypePaginatedResponse,
  IVentureProjectTypePaginatedVars,
  IVentureProjectTypeUpdateVars,
  VentureProjectTypeCreateUpdateResponse,
  VentureProjectTypeListResponse,
} from "./types";

export async function getVentureProjectTypesPaginated(
  variables: IVentureProjectTypePaginatedVars
): Promise<IVentureProjectTypePaginatedResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureProjectTypesPaginatedDocument,
      variables,
    });
    return res?.data?.ventureProjectTypesPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createVentureProjectType(
  variables: IVentureProjectTypeCreateVars
): Promise<VentureProjectTypeCreateUpdateResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureProjectTypeCreateDocument,
      variables,
    });
    return res?.data?.ventureProjectTypeCreate;
  } catch (error) {
    throw error;
  }
}

export async function updateVentureProjectType(
  variables: IVentureProjectTypeUpdateVars
): Promise<VentureProjectTypeCreateUpdateResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureProjectTypeUpdateDocument,
      variables,
    });
    return res?.data?.ventureProjectTypeUpdate;
  } catch (error) {
    throw error;
  }
}

export async function getVentureProjectType(
  ventureProjectTypeId: number
): Promise<PartialNullable<IVentureProjectTypeModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureProjectTypeGetByIdDocument,
      variables: {
        ventureProjectTypeId,
      },
    });
    return res?.data?.ventureProjectTypeGetById;
  } catch (error) {
    throw error;
  }
}

export async function deleteVentureProjectType(
  ventureProjectTypeId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureProjectTypeDeleteDocument,
      variables: {
        ventureProjectTypeId,
      },
    });
    return Boolean(res?.data?.ventureProjectTypeDelete);
  } catch (error) {
    throw error;
  }
}

export async function getVentureProjectTypes(
  variables: IVentureProjectTypeListVars
): Promise<VentureProjectTypeListResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureProjectTypeListDocument,
      variables,
    });
    return res?.data?.ventureProjectTypeList;
  } catch (error) {
    throw error;
  }
}
