import { Theme } from "@mui/material";
import { LinearGradients, StylingRelatedConstants } from "config";

export const MainLayoutStyles = (theme: Theme) => ({
  display: "flex",
  padding: theme.spacing(3),
  background: LinearGradients.MAIN_LAYOUT,
});

export const LayoutContentWrapperStyles = (
  theme: Theme,
  whiteColorBackground: boolean
) => ({
  display: "flex",
  width: "100%",
  marginLeft: theme.spacing(3),
  marginTop: `calc(${theme.spacing(3)} + ${
    StylingRelatedConstants.TOPBAR_HEIGHT
  })`,
  ...(whiteColorBackground && {
    backgroundColor: theme.palette.custom.white,
  }),
  borderRadius: theme.shape.borderRadius[3],
  overflow: "hidden",
  position: "relative",
});
