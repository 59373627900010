import {
  WeeklyUpdateCreateDocument,
  WeeklyUpdateDeleteDocument,
  WeeklyUpdateDocumentDownloadDocument,
  WeeklyUpdateUpdateDocument,
  WeeklyUpdateDocumentUploadDocument,
  WeeklyUpdateGetByIdDocument,
  WeeklyUpdateForHomepageByIdDocument,
  WeeklyUpdatesByUserDocument,
  WeeklyUpdatesPaginatedDocument,
} from "api/generated";
import { Optional, PartialNullable } from "config";

import { RequestTypes } from "../../enums";
import { getRequestInstance } from "../../utils";
import {
  GetWeeklyUpdatesReturnType,
  ICreateWeeklyUpdateVars,
  IUpdateWeeklyUpdateVars,
  IWeeklyUpdateDocumentModel,
  IWeeklyUpdateModel,
  IWeeklyUpdatesPaginatedResponse,
  IWeeklyUpdatesPaginatedVars,
  WeeklyUpdateCreateUpdateReturnType,
  WeeklyUpdateForHomepageReturnType,
} from "./types";
import { IFile, NumberFiltersType } from "../../types";

export async function getWeeklyUpdatesPaginated(
  variables: IWeeklyUpdatesPaginatedVars
): Promise<IWeeklyUpdatesPaginatedResponse> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: WeeklyUpdatesPaginatedDocument,
      variables,
    });
    return res?.data?.weeklyUpdatesPaginated;
  } catch (error) {
    throw error;
  }
}

export async function createWeeklyUpdate(
  variables: ICreateWeeklyUpdateVars
): Promise<Optional<WeeklyUpdateCreateUpdateReturnType>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: WeeklyUpdateCreateDocument,
      variables,
    });
    return res?.data?.weeklyUpdateCreate;
  } catch (error) {
    throw error;
  }
}

export async function updateWeeklyUpdate(
  variables: IUpdateWeeklyUpdateVars
): Promise<Optional<WeeklyUpdateCreateUpdateReturnType>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: WeeklyUpdateUpdateDocument,
      variables,
    });
    return res?.data?.weeklyUpdateUpdate;
  } catch (error) {
    throw error;
  }
}

export async function getWeeklyUpdate(
  weeklyUpdateId: number
): Promise<PartialNullable<IWeeklyUpdateModel>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: WeeklyUpdateGetByIdDocument,
      variables: { weeklyUpdateId },
    });
    return res?.data?.weeklyUpdateGetById;
  } catch (error) {
    throw error;
  }
}

export async function getWeeklyUpdates(
  productFundId?: NumberFiltersType
): Promise<GetWeeklyUpdatesReturnType[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: WeeklyUpdatesByUserDocument,
      variables: { productFundId },
    });
    return res?.data?.weeklyUpdatesByUser ?? [];
  } catch (error) {
    throw error;
  }
}

export async function deleteWeeklyUpdate(
  weeklyUpdateId: number
): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: WeeklyUpdateDeleteDocument,
      variables: { weeklyUpdateId },
    });
    return Boolean(res?.data?.weeklyUpdateDelete);
  } catch (error) {
    throw error;
  }
}

export async function uploadWeeklyUpdateDocument(
  variables: IFile
): Promise<Optional<IWeeklyUpdateDocumentModel<number>>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: WeeklyUpdateDocumentUploadDocument,
      variables,
    });
    return res?.data?.weeklyUpdateDocumentUpload;
  } catch (error) {
    throw error;
  }
}

export async function downloadWeeklyUpdateDocument(
  weeklyUpdateDocumentId: number
): Promise<Optional<string>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: WeeklyUpdateDocumentDownloadDocument,
      variables: { weeklyUpdateDocumentId },
    });
    return res?.data?.weeklyUpdateDocumentDownload;
  } catch (error) {
    throw error;
  }
}

export async function getWeeklyUpdateForHomepage(
  weeklyUpdateId: number
): Promise<WeeklyUpdateForHomepageReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: WeeklyUpdateForHomepageByIdDocument,
      variables: { weeklyUpdateId },
    });
    return res?.data?.weeklyUpdateViewForInvestor;
  } catch (error) {
    throw error;
  }
}
