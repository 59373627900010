import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { Nullable } from "config";
import { RootState } from "store";

import { ReportTypeModalModes } from "./enums";
import { IReportTypeModalData } from "./types";
import { IReportTypesPaginatedResponse, getReportTypesPaginated } from "api";

const initialState = {
  reportTypesData: null as Nullable<IReportTypesPaginatedResponse["edges"]>,
  totalCount: 1,
  loading: false,
  modalData: {
    open: false,
    mode: ReportTypeModalModes.VIEW,
  } as IReportTypeModalData,
};

export const fetchReportTypes = createAsyncThunk(
  "report-type/fetchReportTypes",
  async (_, { getState }) => {
    const filters = (getState() as RootState).filters;
    if (!Object.values(filters).length) {
      return;
    }
    const response = await getReportTypesPaginated(filters);
    return response;
  }
);

const reportTypeSlice = createSlice({
  name: "report-type",
  initialState,
  reducers: {
    setReportTypeModalData: (
      state,
      action: PayloadAction<IReportTypeModalData>
    ) => {
      state.modalData = action.payload;
    },
    closeReportTypeModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchReportTypes.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        reportTypesData: action.payload?.edges ?? null,
        totalCount: action.payload?.totalCount ?? 1,
      };
    });
    builder.addCase(fetchReportTypes.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const { setReportTypeModalData, closeReportTypeModal } =
  reportTypeSlice.actions;
export default reportTypeSlice.reducer;
