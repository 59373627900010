import { ITaskFilters } from "api";
import { TaskStatusEnum } from "config";

export function getYesterdayEndOfDayUTC(): Date {
  const today = new Date();

  today.setUTCHours(0, 0, 0, 0);
  today.setUTCSeconds(-1);

  return today;
}

export function getTodayStartOfDayUTC(): Date {
  const today = new Date();

  today.setUTCHours(0, 0, 0, 0);

  return today;
}

export function getTodayEndOfDayUTC(): Date {
  const today = new Date();

  today.setUTCHours(23, 59, 59, 0);

  return today;
}

export function getTomorrowStartOfDayUTC(): Date {
  const dayAfterTomorrow = new Date();

  dayAfterTomorrow.setUTCDate(dayAfterTomorrow.getUTCDate() + 1);
  dayAfterTomorrow.setUTCHours(0, 0, 0, 0);

  return dayAfterTomorrow;
}

export function getLastDayOfWeekEndOfDayUTC(): Date {
  const today = new Date();
  const day = today.getUTCDay();
  const lastDayOfWeek = new Date();

  lastDayOfWeek.setUTCDate(today.getUTCDate() - day + 7);
  lastDayOfWeek.setUTCHours(23, 59, 59, 0);

  return lastDayOfWeek;
}

export function getFirstDayOfNextWeekStartOfDayUTC(): Date {
  const today = new Date();
  const day = today.getUTCDay();
  const firstDayOfNextWeek = new Date();

  firstDayOfNextWeek.setUTCDate(today.getUTCDate() - day + 8);
  firstDayOfNextWeek.setUTCHours(0, 0, 0, 0);

  return firstDayOfNextWeek;
}

export function getTaskListFilters(input: {
  fromDate: Date;
  toDate: Date;
  neStatus?: TaskStatusEnum;
}): ITaskFilters[] {
  return [
    {
      endDate: {
        between: {
          from: input.fromDate,
          to: input.toDate,
        },
      },
      ...(input.neStatus ? { status: { ne: input.neStatus } } : {}),
    },
  ];
}
