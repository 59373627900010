import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { Nullable } from "config";
import { store } from "store";
import { getExchangeList, GetExchangeListReturnTypeFiltered } from "api";

import {
  ExchangeGroupByOptions,
  exchangeGroupByOptionsToFunctionsMap,
} from "./constants";

const initialState = {
  exchangeData: null as Nullable<GetExchangeListReturnTypeFiltered["edges"]>,
  totalCount: 1,
  totalValue: 0,
  loading: false,
};

export const fetchExchanges = createAsyncThunk(
  "exchange/fetchExchanges",
  async (groupByOption?: Nullable<ExchangeGroupByOptions>) => {
    const filters = store.getState().filters;
    if (!Object.values(filters).length) {
      return;
    }
    let fetchFunction = getExchangeList;
    if (
      groupByOption &&
      groupByOption !== ExchangeGroupByOptions.ACCOUNT_ASSET &&
      exchangeGroupByOptionsToFunctionsMap[groupByOption]
    ) {
      fetchFunction = exchangeGroupByOptionsToFunctionsMap[
        groupByOption
      ] as any;
    }
    const response = await fetchFunction(filters);
    return response;
  }
);

const exchangesSlice = createSlice({
  name: "exchange",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchExchanges.fulfilled, (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          loading: false,
        };
      }
      return {
        exchangeData: action.payload
          ?.edges as GetExchangeListReturnTypeFiltered["edges"],
        totalCount: action.payload?.totalCount,
        totalValue: action.payload?.totalValue,
        loading: false,
      };
    });
    builder.addCase(fetchExchanges.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export default exchangesSlice.reducer;
