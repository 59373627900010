import { ICommonModalData } from "config";

export enum SourceModalMode {
  VIEW = "view",
  EDIT = "edit",
  CREATE = "create",
}

export interface ISourceModalData extends ICommonModalData<SourceModalMode> {
  sourceId?: number;
}
