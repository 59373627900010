import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  ILinkAgentAggregatedReportResponse,
  ILinkAgentDetailedReportResponse,
  getLinkAgentReportsAggregated,
  getLinkAgentReportsDetailed,
} from "api";
import { Nullable } from "config";
import { RootState } from "store/store";

const initialState = {
  aggregatedReportsData: {
    linkAgentReportsAggregatedData: null as Nullable<
      ILinkAgentAggregatedReportResponse["edges"]
    >,
    totalCount: 1,
    loading: false,
  },
  detailedReportsData: {
    linkAgentReportsDetailedData: null as Nullable<
      ILinkAgentDetailedReportResponse["edges"]
    >,
    totalCount: 1,
    loading: false,
  },
};

export const fetchLinkAgentReportsAggregated = createAsyncThunk(
  "link-agent-reports/fetchLinkAgentReportsAggregated",
  async (_, { getState }) => {
    const filters = (getState() as RootState).filters;
    try {
      if (!Object.values(filters).length) {
        return;
      }

      const response = await getLinkAgentReportsAggregated(filters);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchLinkAgentReportsDetailed = createAsyncThunk(
  "link-agent-reports/fetchLinkAgentReportsDetailed",
  async (linkAgentProfileId: number, { getState }) => {
    const variables = (getState() as RootState).filters;
    try {
      const response = await getLinkAgentReportsDetailed({
        ...variables,
        filters: [
          ...variables?.filters,
          {
            linkAgentProfile: {
              linkAgentProfileId: {
                eq: linkAgentProfileId,
              },
            },
          },
        ],
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const linkAgentPayoutsSlice = createSlice({
  name: "link-agent-reports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchLinkAgentReportsAggregated.fulfilled,
      (state, action) => {
        if (!action.payload) {
          state.aggregatedReportsData.loading = false;
          return;
        }
        state.aggregatedReportsData = {
          linkAgentReportsAggregatedData: action.payload?.edges,
          totalCount: action.payload?.totalCount,
          loading: false,
        };
      }
    );
    builder.addCase(fetchLinkAgentReportsAggregated.pending, (state) => {
      state.aggregatedReportsData.loading = true;
    });
    builder.addCase(fetchLinkAgentReportsAggregated.rejected, (state) => {
      state.aggregatedReportsData.loading = false;
    });
    builder.addCase(
      fetchLinkAgentReportsDetailed.fulfilled,
      (state, action) => {
        if (!action.payload) {
          state.detailedReportsData.loading = false;
          return;
        }
        state.detailedReportsData = {
          linkAgentReportsDetailedData: action.payload?.edges,
          totalCount: action.payload?.totalCount,
          loading: false,
        };
      }
    );
    builder.addCase(fetchLinkAgentReportsDetailed.pending, (state) => {
      state.detailedReportsData.loading = true;
    });
    builder.addCase(fetchLinkAgentReportsDetailed.rejected, (state) => {
      state.detailedReportsData.loading = false;
    });
  },
});

export default linkAgentPayoutsSlice.reducer;
