import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getTagsList, ITagModel } from "api";
import { GraphqlDataSchema, Nullable } from "config";
import { store } from "store";

import { ITagsModalData, TagsModalModes } from "./types";

const initialState = {
  tagsData: null as Nullable<GraphqlDataSchema<ITagModel>["edges"]>,
  totalCount: 1,
  loading: false,
  modalData: {
    mode: TagsModalModes.VIEW,
    open: false,
  } as ITagsModalData,
};

export const fetchTags = createAsyncThunk("tags/fetchTags", async () => {
  const filters = store.getState().filters;
  if (!Object.values(filters).length) {
    return;
  }
  const response = await getTagsList(filters);
  return response;
});

const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    setTagsModalData: (state, action: PayloadAction<ITagsModalData>) => {
      state.modalData = action.payload;
    },
    closeTagsModal: (state) => {
      state.modalData = initialState.modalData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTags.fulfilled, (state, action) => {
      if (!action.payload) return;
      return {
        ...state,
        tagsData: action.payload
          ?.edges as GraphqlDataSchema<ITagModel>["edges"],
        totalCount: action.payload?.totalCount,
        loading: false,
      };
    });
    builder.addCase(fetchTags.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  },
});

export const { setTagsModalData, closeTagsModal } = tagsSlice.actions;
export default tagsSlice.reducer;
