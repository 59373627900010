import { ICommonModalData } from "config";

export enum ConversionModalMode {
  CREATE = "create",
  VIEW = "view",
  EDIT = "edit",
}

export interface IConversionModalData
  extends ICommonModalData<ConversionModalMode> {
  id?: number;
}
