import { ICommonModalData } from "config";

export enum UserModalModes {
  CREATE = "create",
  VIEW = "view",
  EDIT = "edit",
  EDIT_OWN_SETTINGS = "editOwnSettings",
}

export interface IUserModalData extends ICommonModalData<UserModalModes> {
  userId?: number;
}
